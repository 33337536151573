import React from 'react';
import { useSelector } from 'react-redux';
import { Transition } from '@headlessui/react';

import { editorSelector } from '../selectors/editor-selector';

import { FramePickerInner } from './frame-picker-inner';

const FramePicker = () => {
  const { framePickerVisible } = useSelector(editorSelector);

  return (
    <Transition
      show={framePickerVisible}
      enter="transition duration-200 ease-out"
      enterFrom="transform opacity-0 translate-y-4"
      enterTo="transform opacity-100 translate-y-0"
      leave="transition duration-200 ease-out"
      leaveFrom="transform opacity-100 translate-y-0"
      leaveTo="transform opacity-0 translate-y-4"
      className="editor-frame-picker origin-bottom m-2 p-2 inset-0 bg-white rounded-md shadow-md row-start-1 row-span-2 flex flex-col"
    >
      {framePickerVisible && <FramePickerInner />}
    </Transition>
  );
};

export default React.memo(FramePicker);
