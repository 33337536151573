import { AnyAction } from '@reduxjs/toolkit';
import produce from 'immer';

type ErrorState = Record<string, string[]>;

const defaultState: ErrorState = {};

export default produce((state, action: AnyAction) => {
  switch (action.type) {
    case 'ERROR/SET':
      state[action.payload.key] = action.payload.value;
      break;
    case 'ERROR/CLEAR':
      return {};
  }
  return state;
}, defaultState);
