import React from 'react';

import { User } from '../../../gql/generated/graphql';
import { UserPill } from '../../components';

export const ResultsUsers = ({ users = [] }: { users: Array<User> }) => {
  if (!users.length) {
    return (
      <div>
        <h3>Studios</h3>
        <p>No studio results.</p>
      </div>
    );
  }

  return (
    <div>
      <h3>Studios</h3>
      {users.map((user) => (
        <UserPill user={user} key={user.id} small />
      ))}
    </div>
  );
};
