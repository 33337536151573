import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useSearchParams = () => {
  const { search } = useLocation();
  const history = useHistory();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const updateUrl = ({
    searchTerm,
    animationPage,
    userPage,
  }: {
    searchTerm?: string;
    animationPage?: string;
    userPage?: string;
  }) => {
    if (searchTerm) {
      params.set('q', searchTerm);
    }
    if (animationPage) {
      params.set('ao', animationPage);
    }
    if (userPage) {
      params.set('uo', userPage);
    }

    history.replace(`/search?${params}`);
  };

  return {
    params,
    updateUrl,
    searchTerm: params.get('q'),
    animationPage: parseInt(params.get('ao') ?? '1', 10),
    userPage: parseInt(params.get('uo') ?? '1', 10),
  };
};
