import { v1 as uuidv1 } from 'uuid';

import {
  dataUrlToImg,
  getThumbnail,
} from '../../../../lib/animation/canvas-utils';

type FrameImageArgs = {
  from?: HTMLCanvasElement;
  width?: number;
  height?: number;
  id?: string | null;
};

export class FrameImage {
  id: string;
  width: number;
  height: number;
  data: string;
  thumbData: string;

  constructor({ from: sourceCanvas, width, height, id }: FrameImageArgs) {
    this.id = id || uuidv1();
    this.width = width;
    this.height = height;

    if (sourceCanvas) {
      this.data = sourceCanvas.toDataURL('image/png');
      this.thumbData = getThumbnail(sourceCanvas);
      this.width = sourceCanvas.width;
      this.height = sourceCanvas.height;
    }
  }

  getData() {
    return this.data;
  }

  getThumbData() {
    return this.thumbData;
  }

  async setFromDataUrl(dataUrl: string) {
    this.data = dataUrl;
    const img = await dataUrlToImg(dataUrl);
    this.width = img.width;
    this.height = img.height;
    this.thumbData = getThumbnail(img);
  }

  toJSON() {
    return {
      _type: 'FrameImage',
      id: this.id,
    };
  }
}
