import React, { FC } from 'react';

import PageHeading from '../../components/page-heading';
import ThemedPage from '../../components/themed-page';
import { Main, Module } from '../../layouts';

type ErrorPageProps = {
  message: string;
};

const ErrorPage: FC<ErrorPageProps> = ({ message }) => {
  return (
    <ThemedPage>
      <Main small>
        <PageHeading>Oh no! Something broke!</PageHeading>

        <Module>
          <div className="split-layout">
            <div className="split-layout--small">
              <img src="/images/villain.jpg" alt="" style={{ width: '100%' }} />
            </div>
            <div className="split-layout--large">
              <p>Whoops! Very sorry, something went horribly wrong.</p>
              <p>The error said &quot;{message}&quot;</p>
              <p>You&apos;ll need to refresh the page to get going again.</p>
            </div>
          </div>
        </Module>
      </Main>
    </ThemedPage>
  );
};

export default ErrorPage;
