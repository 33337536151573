import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { AnimationState } from '../../../lib/animation/animation-default-state';
import { FrameImage } from '../canvas/lib/frame-image';
import { editorSelector } from '../selectors/editor-selector';

import OnionSkin from './onion-skin';

type OnionSkinLayerProps = {
  sequence: AnimationState['sequence'];
  currentFrameIndex: number;
  currentLayerIndex: number;
  width: number;
  height: number;
};

export const OnionSkinLayer: FC<OnionSkinLayerProps> = ({
  sequence,
  currentFrameIndex,
  currentLayerIndex,
  width,
  height,
}) => {
  const editorMode = useSelector(editorSelector).mode;

  const getRelativeFrameImage = (desiredIndex: number): null | FrameImage => {
    let relativeIndex = desiredIndex + currentFrameIndex;

    if (relativeIndex < 0) relativeIndex = sequence.length - 1;
    if (relativeIndex >= sequence.length) relativeIndex = 0;

    if (relativeIndex === currentFrameIndex) return null;

    const { image } = sequence[relativeIndex].layers[currentLayerIndex];

    return image ?? null;
  };

  if (editorMode !== 'editing') return null;

  const images = [-2, -1, 1].map((desiredIndex) =>
    getRelativeFrameImage(desiredIndex)
  );

  return (
    <>
      {images[0] && (
        <OnionSkin
          width={width}
          height={height}
          dataUrl={images[0].getData()}
          className="editor-onion-skin--previous-previous"
        />
      )}
      {images[1] && (
        <OnionSkin
          width={width}
          height={height}
          dataUrl={images[1].getData()}
          className="editor-onion-skin--previous"
        />
      )}
      {images[2] && (
        <OnionSkin
          width={width}
          height={height}
          dataUrl={images[2].getData()}
          className="editor-onion-skin--next"
        />
      )}
    </>
  );
};
