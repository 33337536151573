import { graphql } from '../../generated';

export const MUTATE_COMIC = graphql(`
  mutation MutateComic($comicId: Int, $addAnimation: Int, $columns: Int) {
    mutateComic(
      comicId: $comicId
      addAnimation: $addAnimation
      columns: $columns
    ) {
      id
      columns
      frames {
        id
        animation {
          ...CoreAnimationFields
        }
        sequence
        rowSpan
        colSpan
      }
    }
  }
`);
