import React from 'react';
import { useMutation } from '@apollo/client';

import {
  ComicFrame as TComicFrame,
  ComicFrameAction,
} from '../../../gql/generated/graphql';
import { MUTATE_COMIC_FRAME } from '../../../gql/queries/mutations/mutate-comic-frame';
import { Button, DropdownMenu } from '../../components';

const ComicFrameControls = ({
  frame,
  numFrames,
}: {
  frame: TComicFrame;
  numFrames: number;
}) => {
  const [mutateComicFrame] = useMutation(MUTATE_COMIC_FRAME);

  const onDeletePressed = (e) => {
    e.preventDefault();
    mutateComicFrame({
      variables: { action: ComicFrameAction.Delete, frameId: frame.id },
    });
  };

  const onDecreaseSequencePressed = (e) => {
    e.preventDefault();

    mutateComicFrame({
      variables: { action: ComicFrameAction.MoveLeft, frameId: frame.id },
    });
  };

  const onIncreaseSequencePressed = (e) => {
    e.preventDefault();

    mutateComicFrame({
      variables: { action: ComicFrameAction.MoveRight, frameId: frame.id },
    });
  };

  const onSetColspanPressed = (colspan) => {
    mutateComicFrame({
      variables: {
        action: ComicFrameAction.SetColspan,
        frameId: frame.id,
        value: colspan,
      },
    });
  };

  const onSetRowspanPressed = (colspan) => {
    mutateComicFrame({
      variables: {
        action: ComicFrameAction.SetRowspan,
        frameId: frame.id,
        value: colspan,
      },
    });
  };

  return (
    <div className="absolute top-0 left-0 w-full flex justify-between p-2">
      <div>
        <Button type="negative" className="mr-4" onClick={onDeletePressed}>
          <i className="fas fa-trash-alt"></i>
        </Button>
        <span className="whitespace-nowrap">
          {frame.sequence > 1 && (
            <Button onClick={onDecreaseSequencePressed}>
              <i className="fas fa-arrow-left"></i>
            </Button>
          )}
          {frame.sequence < numFrames && (
            <Button onClick={onIncreaseSequencePressed}>
              <i className="fas fa-arrow-right"></i>
            </Button>
          )}
        </span>
      </div>
      <div>
        <DropdownMenu
          button={{ text: `Width (${frame.colSpan})` }}
          items={[
            {
              text: (
                <span>
                  1 col{' '}
                  {frame.colSpan === 1 && <i className="fas fa-check"></i>}
                </span>
              ),
              onClick: () => onSetColspanPressed(1),
            },
            {
              text: (
                <span>
                  2 cols{' '}
                  {frame.colSpan === 2 && <i className="fas fa-check"></i>}
                </span>
              ),
              onClick: () => onSetColspanPressed(2),
            },
            {
              text: (
                <span>
                  3 cols{' '}
                  {frame.colSpan === 3 && <i className="fas fa-check"></i>}
                </span>
              ),
              onClick: () => onSetColspanPressed(3),
            },
            {
              text: (
                <span>
                  4 cols{' '}
                  {frame.colSpan === 4 && <i className="fas fa-check"></i>}
                </span>
              ),
              onClick: () => onSetColspanPressed(4),
            },
          ]}
        />
        <DropdownMenu
          button={{ text: `Height (${frame.rowSpan})` }}
          items={[
            {
              text: (
                <span>
                  1 row{' '}
                  {frame.rowSpan === 1 && <i className="fas fa-check"></i>}
                </span>
              ),
              onClick: () => onSetRowspanPressed(1),
            },
            {
              text: (
                <span>
                  2 rows{' '}
                  {frame.rowSpan === 2 && <i className="fas fa-check"></i>}
                </span>
              ),
              onClick: () => onSetRowspanPressed(2),
            },
            {
              text: (
                <span>
                  3 rows{' '}
                  {frame.rowSpan === 3 && <i className="fas fa-check"></i>}
                </span>
              ),
              onClick: () => onSetRowspanPressed(3),
            },
            {
              text: (
                <span>
                  4 rows{' '}
                  {frame.rowSpan === 4 && <i className="fas fa-check"></i>}
                </span>
              ),
              onClick: () => onSetRowspanPressed(4),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default ComicFrameControls;
