import { setErrorAction } from '../../lib/error/error-actions';
import { post } from '../../lib/services/fetch';
import { userLoaded } from '../../lib/user/user-actions';

const changePassword = function (password, resetId, history) {
  return function (dispatch) {
    return post('/api/change-password', { password, resetId })
      .then(function (response) {
        dispatch(userLoaded(response.user));
        history.push('/my-studio');
      })
      .catch(function (e) {
        return dispatch(passwordChangeFailed(e));
      });
  };
};

const passwordChangeFailed = function (error) {
  return setErrorAction(
    'PASSWORD_CHANGE_FAILED',
    (error.response && error.response.payload) || ['ERR_UNKNOWN']
  );
};

export default {
  changePassword,
};
