import produce from 'immer';
import { v1 as uuidv1 } from 'uuid';

import { getBlankImage } from '../../components/editor/canvas/lib/blank-image';

import defaultState from './animation-default-state';

export default produce((state = defaultState, action) => {
  switch (action.type) {
    case 'ANIMATION/ADD_FRAME': {
      const { layers } = state;
      const { index, delay } = action.payload;
      state.sequence.splice(index, 0, {
        id: uuidv1(),
        layers: layers.map(() => ({
          image: getBlankImage({ width: state.width, height: state.height }),
          id: uuidv1(),
        })),
        delay,
      });
      state.saved = false;
      break;
    }
    case 'ANIMATION/DELETE_FRAME': {
      const { index } = action.payload;
      state.sequence.splice(index, 1);
      state.saved = false;
      break;
    }
    case 'ANIMATION/ADD_FROM_FRAME': {
      const { sourceIndex, destinationIndex, layers } = action.payload;
      const copiedFrame = state.sequence[sourceIndex];

      state.sequence.splice(destinationIndex, 0, {
        ...copiedFrame,
        id: uuidv1(),
        layers,
      });
      state.saved = false;
      break;
    }
    case 'ANIMATION/REPLACE_FRAME': {
      const { destinationIndex, layers } = action.payload;
      const destinationLayers = [...layers];

      state.sequence[destinationIndex].layers = destinationLayers;
      state.saved = false;
      break;
    }
    case 'ANIMATION/UPDATE_FRAME': {
      const sequence = [...state.sequence];
      const { index, layer, image } = action.payload;
      const sequenceItem = { ...sequence[index] };
      const layers = [...sequenceItem.layers];

      layers[layer].image = image;

      state.sequence[index].layers = layers;
      state.sequence[index].id = uuidv1();
      state.saved = false;
      break;
    }
    case 'ANIMATION/SET_FRAME_DELAY': {
      const { index, delay } = action.payload;
      const sequenceItem = state.sequence[index];

      if (action.payload.applyAll) {
        state.sequence.forEach((frame) => {
          frame.delay = delay;
        });
      } else {
        sequenceItem.delay = delay;
      }
      state.saved = false;
      break;
    }
    case 'ANIMATION/SET': {
      Object.assign(state, {
        id: action.payload.id,
        sequence: [...action.payload.sequence],
        public: action.payload.public,
        title: action.payload.title,
        url: action.payload.url,
        palette:
          action.payload.palette.length > 0
            ? action.payload.palette
            : defaultState.palette,
        blacklisted: action.payload.blacklisted,
        width: action.payload.width,
        height: action.payload.height,
        layers: action.payload.layers,
        folderId: action.payload.folderId,
        pixelMode: action.payload.pixelMode,
        type: action.payload.type,
      });
      break;
    }
    case 'ANIMATION/SET_URL': {
      state.url = action.payload.url;
      break;
    }
    case 'ANIMATION/SET_ID': {
      state.id = action.payload;
      break;
    }
    case 'ANIMATION/SET_TITLE': {
      state.title = action.payload.title;
      state.saved = false;
      break;
    }
    case 'ANIMATION/SET_SAVED': {
      state.saved = action.payload;
      break;
    }
    case 'ANIMATION/SET_SAVED_LOCALLY': {
      state.savedLocally = action.payload;
      break;
    }
    case 'ANIMATION/SET_PUBLIC': {
      if (!state.blacklisted) {
        state.public = action.payload.public;
      }
      state.saved = false;
      break;
    }
    case 'ANIMATION/NEW': {
      Object.assign(state, { ...defaultState, ...action.payload });
      break;
    }
    case 'ANIMATION/SET_COLOUR': {
      state.palette[action.payload.index] = action.payload.colour;
      state.saved = false;
      break;
    }
    case 'ANIMATION/LAYER_RENAME': {
      state.layers[action.payload.index] = action.payload.name;
      state.saved = false;
      break;
    }
    case 'ANIMATION/LAYER_ADD': {
      state.layers.push(`Layer ${state.layers.length + 1}`);
      state.sequence.forEach((frame) => {
        frame.layers.push({
          id: uuidv1(),
          image: getBlankImage({
            width: state.width,
            height: state.height,
          }),
        });
      });
      state.saved = false;
      break;
    }
    case 'ANIMATION/LAYER_DELETE': {
      state.layers.splice(action.payload.index, 1);
      state.sequence.forEach((frame) => {
        frame.layers.splice(action.payload.index, 1);
      });
      state.saved = false;
      break;
    }
    case 'ANIMATION/SET_FROM_UNDO':
      state.sequence = action.payload.sequence;
      state.layers = action.payload.layers;
      state.saved = false;
      break;
    case 'ANIMATION/SET_FOLDER':
      state.folderId = action.payload.folderId;
      state.saved = false;
      break;
    case 'ANIMATION/SET_PIXEL_MODE':
      state.pixelMode = action.payload.pixelMode;
      state.saved = false;
      break;
  }
}, defaultState);
