import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../button';

const BackButton = () => {
  const history = useHistory();

  const onClick = () => {
    if (history.length > 0) {
      history.goBack();
    } else {
      history.push('/gallery');
    }
  };

  return (
    <Button icon="fas fa-arrow-circle-left" onClick={onClick}>
      Back
    </Button>
  );
};

export default BackButton;
