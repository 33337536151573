import { v1 as uuidv1 } from 'uuid';

import { Animation } from '../../../gql/generated/graphql';
import { loadFramesService } from '../../lib/services/animation';
import { RootState } from '../../store';
import { getBlankImage } from '../editor/canvas/lib/blank-image';

import { FrameImage } from './canvas/lib/frame-image';
import { unserialiseSequence } from './editor-helpers';

type OnLoadProgressCBArgs = {
  resolved: number;
  total: number;
};

export const loadServerImages = async (
  animationData: Animation,
  onLoadProgress: ({ total, resolved }: OnLoadProgressCBArgs) => void
): Promise<Partial<RootState['animation']>> => {
  const { sequence, imagesToLoad } = unserialiseSequence({
    sequence: animationData.sequence,
  });

  let errorOccurred = false;

  const onFrameCompleted = () => {
    let resolved = 0;
    const total = imagesToLoad.length;
    return () => {
      if (errorOccurred) return;
      resolved++;
      onLoadProgress({ resolved, total });
    };
  };

  onLoadProgress({ resolved: 0, total: animationData.frames });

  const layers = animationData.layers || ['Background', 'Foreground'];

  let resolvedImages;
  try {
    const loaded = await loadFramesService({
      imagesToLoad,
      animationData,
      onFrameCompleted: onFrameCompleted(),
    });
    resolvedImages = loaded.images;
  } catch (e) {
    errorOccurred = true;
    throw e;
  }

  const resolvedFrameImages: Record<string, FrameImage> = {};
  const sequenceWithImages = sequence.map((frame) => {
    const { layers } = frame;
    return {
      ...frame,
      layers: layers.map((layerImageIndex) => {
        if (resolvedFrameImages[layerImageIndex]) {
          // Image has already been resolved
          return {
            id: uuidv1(),
            image: resolvedFrameImages[layerImageIndex],
          };
        } else {
          // Image referenced for the first time
          if (layerImageIndex !== '_blank') {
            resolvedFrameImages[layerImageIndex] =
              resolvedImages[layerImageIndex];
            return {
              id: uuidv1(),
              image: resolvedFrameImages[layerImageIndex],
            };
          } else {
            return {
              id: uuidv1(),
              image: getBlankImage({
                width: animationData.width,
                height: animationData.height,
              }),
            };
          }
        }
      }),
    };
  });

  return {
    ...animationData,
    layers,
    sequence: sequenceWithImages,
  };
};
