import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Transition } from '@headlessui/react';

import { hideModal } from '../lib/modal/modal-actions';
import { RootState, useAppDispatch } from '../store';

import AnibotModal from './components/anibot';
import AnibotFadeModal from './components/anibot/anibot-fade';
import AnibotMoveModal from './components/anibot/anibot-move';
import ChooseAnimationDimensionsModal from './components/choose-animation-dimensions';
import ChooseAvatarModal from './components/choose-avatar';
import ConfirmAnimationDeleteModal from './components/confirm-animation-delete';
import ConfirmCreateAvatarModal from './components/confirm-create-avatar';
import ConfirmOverwriteAnimationModal from './components/confirm-overwrite-animation';
import ConfirmSetAvatarModal from './components/confirm-set-avatar';
import CreateComicModal from './components/create-comic';
import CreateThreadModal from './components/create-thread';
import DailyAnimationsModal from './components/daily-animations';
import DefaultErrorModal from './components/default-error';
import EditLayersModal from './components/edit-layers';
import EditPostModal from './components/edit-post';
import LoadAnimationModal from './components/load-animation';
import LoadingModal from './components/loading';
import ManageFoldersModal from './components/manage-folders';
import ModalInner from './components/modal-inner';
import MoveAnimationsModal from './components/move-animations';
import NotSignedInModal from './components/not-signed-in';
import PaletteModal from './components/palette';
import PostBannedModal from './components/post-banned';
import PostRateLimited from './components/post-rate-limited';
import PostReportModal from './components/post-report';
import ReportModal from './components/report';
import ReportFailedModal from './components/report-failed';
import ReportPostFailedModal from './components/report-post-failed';
import ReportPostSubmittedModal from './components/report-post-submitted';
import ReportSubmittedModal from './components/report-submitted';
import SaveAnimationModal from './components/save-animation';
import SaveFailedModal from './components/save-failed';
import SavingModal from './components/saving';
import ThemeModal from './components/theme';
import UserBannedModal from './components/user-banned';
import UserDetailsModal from './components/user-details';
import UserUnverifiedModal from './components/user-unverified';
import VerificationEmailSentModal from './components/verification-email-sent';

export const components = {
  CreateThreadModal,
  EditPostModal,
  DefaultErrorModal,
  SavingModal,
  SaveFailedModal,
  LoadingModal,
  NotSignedInModal,
  PaletteModal,
  ReportModal,
  ReportSubmittedModal,
  ReportFailedModal,
  PostReportModal,
  ReportPostSubmittedModal,
  ReportPostFailedModal,
  PostRateLimited,
  DailyAnimationsModal,
  PostBannedModal,
  UserBannedModal,
  UserUnverifiedModal,
  ThemeModal,
  VerificationEmailSentModal,
  UserDetailsModal,
  ConfirmAnimationDeleteModal,
  ConfirmOverwriteAnimationModal,
  ChooseAnimationDimensionsModal,
  EditLayersModal,
  AnibotModal,
  AnibotMoveModal,
  AnibotFadeModal,
  ManageFoldersModal,
  MoveAnimationsModal,
  SaveAnimationModal,
  LoadAnimationModal,
  CreateComicModal,
  ChooseAvatarModal,
  ConfirmCreateAvatarModal,
  ConfirmSetAvatarModal,
} as const;

const clickOutsideToDismiss = {
  PaletteModal: true,
  DefaultErrorModal: true,
  NotSignedInModal: true,
  SaveFailedModal: true,
  LoadFailedModal: true,
  ReportModal: true,
  DailyAnimationsModal: true,
  PostBannedModal: true,
  UserBannedModal: true,
  UserUnverifiedModal: true,
  VerificationEmailSentModal: true,
  ConfirmAnimationDeleteModal: true,
  ChooseAnimationDimensionsModal: true,
  EditLayersModal: true,
  AnibotModal: true,
  ManageFoldersModal: true,
  MoveAnimationsModal: true,
};

export type HideModalCallback = () => void;
export type ModalComponentProps = {
  hideModal: HideModalCallback;
};
export type ModalComponent = (props: ModalComponentProps) => ReturnType<FC>;

const modalSelector = (state: RootState) => {
  return state.modal;
};

const Modal = () => {
  const dispatch = useAppDispatch();
  const hideModalCallback: HideModalCallback = () => {
    dispatch(hideModal());
  };
  const modalState = useSelector(modalSelector);

  return (
    <Transition show={modalState.visible}>
      <div className="fixed z-50 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {clickOutsideToDismiss[modalState.component] ? (
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div
                className="absolute inset-0 bg-gray-500 opacity-75"
                onClick={hideModalCallback}
              />
            </div>
          ) : (
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed inset-0 transition-opacity"
                aria-hidden="true"
              >
                <div className="absolute inset-0 bg-gray-500 opacity-75" />
              </div>
            </Transition.Child>
          )}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <ModalInner>
            {modalState.visible &&
              React.createElement(components[modalState.component], {
                ...modalState.componentProps,
                hideModal: hideModalCallback,
              })}
          </ModalInner>
        </div>
      </div>
    </Transition>
  );
};

export default Modal;
