import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import config from './lib/config';
import client from './apollo-client';

import './styles.css';
import './main.scss';

if (config.features.sentry) {
  Sentry.init({
    dsn: 'https://e1d62e176f994422acc1b769856e9918@o161201.ingest.sentry.io/6130241',
    integrations: [new BrowserTracing()],
    release: process.env.RELEASE,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    beforeBreadcrumb(breadcrumb) {
      return breadcrumb.category === 'ui.click' ? null : breadcrumb;
    },
  });
}

import { Provider } from 'react-redux';

import Root from './react/pages/root';
import { store } from './react/store';

ReactDOM.hydrate(
  <HelmetProvider>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <BrowserRouter>
          <Root />
        </BrowserRouter>
      </Provider>
    </ApolloProvider>
  </HelmetProvider>,
  document.querySelector('.react-root')
);
