import { graphql } from '../generated';

export const MUTATE_NOTIFICATION = graphql(`
  mutation MutateNotification($id: String, $viewed: Boolean) {
    notification(id: $id, viewed: $viewed) {
      id
      viewed
    }
  }
`);
