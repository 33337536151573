import { graphql } from '../generated';

export const NOTIFICATIONS = graphql(`
  query Notifications {
    notifications {
      id
      link
      viewed
      text
      createdAt
    }
  }
`);
