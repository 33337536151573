import React, { FC, Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import { User } from '../../../gql/generated/graphql';
import { UserPill } from '../../components/user-pill/user-pill';
import { Module } from '../../layouts';
import { oauthSignInAction } from '../../lib/user/user-actions';
import { useAppDispatch } from '../../store';

type SelectAccountProps = {
  linkedAccounts: User[];
};

export const SelectAccount: FC<SelectAccountProps> = ({ linkedAccounts }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const onUserSelected = async (userId: User['id']) => {
    const result = await dispatch(oauthSignInAction(userId));
    if (result === true) {
      history.replace('/my-studio');
    }
  };

  return (
    <Fragment>
      <Module>
        <p>
          We found multiple accounts linked to your email address. Please choose
          which account you would like to sign in and link to your provider.
        </p>
      </Module>
      <Module>
        <ol className="text-xl">
          {linkedAccounts.map((user, index) => {
            return (
              <li key={index}>
                <UserPill
                  user={user}
                  onClick={() => {
                    onUserSelected(user.id);
                  }}
                />
              </li>
            );
          })}
        </ol>
      </Module>
    </Fragment>
  );
};
