import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';

import { PUBLIC_STUDIO } from '../../../gql/queries/public-studio';
import { AnimationList, Button } from '../../components';
import { ButtonBar, ButtonGroup, Module } from '../../layouts';
import { hideModal } from '../../lib/modal/modal-actions';
import { ModalComponentProps } from '../modal';

type DailyAnimationsModalProps = ModalComponentProps & {
  username: string;
  date: string;
};

const DailyAnimationsModal: FC<DailyAnimationsModalProps> = (props) => {
  const dispatch = useDispatch();
  const onCloseClicked = () => {
    dispatch(hideModal());
  };

  const { data = { publicAnimations: [] } } = useQuery(PUBLIC_STUDIO, {
    variables: {
      name: props.username,
      date: props.date,
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <div>
      <ButtonBar>
        <ButtonGroup>
          <Button
            icon="fas fa-angle-right"
            to={`/studio/${props.username}`}
            type="positive"
          >
            More by {props.username}
          </Button>
        </ButtonGroup>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-times-circle"
            onClick={onCloseClicked}
            type="neutral"
          >
            Close
          </Button>
        </ButtonGroup>
      </ButtonBar>
      <Module>
        <AnimationList
          animations={data.publicAnimations}
          loading={false}
          showPublic={false}
        />
      </Module>
    </div>
  );
};

export default DailyAnimationsModal;
