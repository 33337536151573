import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';

import { AnimationType } from '../../../gql/generated/graphql';
import { MUTATE_USER } from '../../../gql/queries/mutate-user';
import { MY_STUDIO } from '../../../gql/queries/my-studio';
import { AnimationFileList } from '../../components';
import Button from '../../components/button';
import { ButtonBar, Module } from '../../layouts';
import { ButtonGroup } from '../../layouts/button-group';
import currentUserSelector from '../../lib/selectors/current-user-selector';
import { setUserAvatarAction } from '../../lib/user/user-actions';
import { useAppDispatch } from '../../store';
import { useModal } from '../use-modal';

import ModalTitle from './modal-title';

const ANIMATIONS_PER_PAGE = 6;

export default function ChooseAvatarModal() {
  const [currentPage, setCurrentPage] = useState(0);
  const { hideModal, showModal } = useModal();
  const currentUser = useSelector(currentUserSelector);
  const dispatch = useAppDispatch();

  const [mutateUser] = useMutation(MUTATE_USER, {
    onCompleted: (data) => {
      hideModal();
      dispatch(setUserAvatarAction(data.user.avatarUrl));
    },
  });

  const onAnimationSelected = (animation) => {
    mutateUser({ variables: { id: currentUser.id, avatarUrl: animation.url } });
  };

  const onPrevPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const onNextPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const onCreatePressed = () => {
    showModal('ConfirmCreateAvatarModal');
  };

  const {
    data = {
      privateAnimations: new Array(ANIMATIONS_PER_PAGE).fill({}),
      privateAnimationsCount: 0,
      animationFolders: [],
    },
  } = useQuery(MY_STUDIO, {
    variables: {
      offset: currentPage * ANIMATIONS_PER_PAGE,
      limit: ANIMATIONS_PER_PAGE,
      types: [AnimationType.Avatar],
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <div>
      <ModalTitle>Choose Profile Picture</ModalTitle>
      <ButtonBar>
        <ButtonGroup>
          <Button
            onClick={onCreatePressed}
            icon="fas fa-plus-circle"
            type="positive"
          >
            Create new profile pic
          </Button>
        </ButtonGroup>
        <ButtonGroup align="right">
          <Button
            onClick={onNextPage}
            disabled={currentPage === 0}
            icon="fas fa-arrow-circle-left"
          >
            Newer
          </Button>
          <Button
            onClick={onPrevPage}
            disabled={data.privateAnimations.length < ANIMATIONS_PER_PAGE}
            icon="fas fa-arrow-circle-right"
          >
            Older
          </Button>
        </ButtonGroup>
      </ButtonBar>
      <Module>
        {
          <AnimationFileList
            animations={data.privateAnimations}
            onAnimationSelected={onAnimationSelected}
          />
        }
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button onClick={hideModal} type="negative">
            <i className="fa fa-ban" /> Cancel
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
}
