import React from 'react';
import { useHistory } from 'react-router-dom';

import { Animation } from '../../../gql/generated/graphql';
import { useModal } from '../../modal/use-modal';
import DropdownMenu, {
  DropdownMenuEntry,
} from '../dropdown-menu/dropdown-menu';

const EditorFileMenu = () => {
  const { showModal } = useModal();
  const history = useHistory();

  const onAnimationToLoadSelected = (animation: Animation) => {
    history.push(`/editor/${animation.url}`);
  };

  const menuItems: DropdownMenuEntry[] = [
    {
      icon: 'fas fa-plus-circle',
      text: 'New animation',
      onClick: () => showModal('ChooseAnimationDimensionsModal'),
    },
    {
      icon: 'fas fa-cloud-download-alt',
      text: 'Load',
      onClick: () =>
        showModal('LoadAnimationModal', {
          onSelected: onAnimationToLoadSelected,
        }),
    },
    {
      icon: 'fas fa-cloud-upload-alt',
      text: 'Edit options & save',
      onClick: () => showModal('SaveAnimationModal'),
    },
  ];

  return (
    <DropdownMenu
      align="left"
      button={{
        text: 'File',
        type: 'transparent',
      }}
      items={menuItems}
    />
  );
};

export default EditorFileMenu;
