import React from 'react';

import PageHeading from '../components/page-heading';
import ThemedPage from '../components/themed-page';
import { Main, Module } from '../layouts';

const PrivacyPage = () => {
  return (
    <ThemedPage>
      <Main>
        <PageHeading>Privacy Policy</PageHeading>

        <Module>
          <h2>Data Protection</h2>
          <p className="my-2">
            The data for this website is hosted in Ireland in the European
            Union.
          </p>
          <p className="my-2">
            The website is built and administered in England in the UK.
          </p>
          <p className="my-2">
            No data collected by this website is sold to any third party
            companies or used for marketing purposes whatsoever.
          </p>
          <p className="my-2">
            Data is controlled by{' '}
            <a href="https://madebylantern.co.uk/">Lantern Web Limited</a> -
            registered in England and Wales with company number 07740122.
            Enquiries welcome{' '}
            <a href="mailto:help@theanimator.co.uk">by email</a>.
          </p>
        </Module>
        <Module>
          <h2>Collected Data</h2>
          <p className="my-2">
            The website only collects data necessary for the website to perform
            its functions. Collected personal data is as follows:
            <ul className="list-disc pl-5">
              <li>
                Email address - only for identification and password reset
                purposes. No marketing will ever be sent to your email address
              </li>
              <li>
                IP address - recorded only for the purpose of identifying and
                mitigating misuse of the site
              </li>
            </ul>
          </p>
        </Module>
        <Module>
          <h2>Cookies</h2>
          <p className="my-2">
            The website only uses essential cookies for managing your signed-in
            status. Third party cookies are used by the services below.
          </p>
        </Module>
        <Module>
          <h2>Third Party Analytics</h2>
          <p className="my-2">
            The website uses{' '}
            <a href="https://analytics.google.com/">Google Analytics</a> to
            monitor usage of the site over time. This data is used to improve
            your experience on the website.
          </p>
          <p className="my-2">
            The website uses <a href="https://sentry.io/">Sentry</a> to monitor
            errors you may experience on the site. Error data is used to help
            fix issues with the website programming.
          </p>
        </Module>
        <Module>
          <h2>Advertising</h2>
          <p className="my-2">
            The website uses{' '}
            <a href="https://www.google.com/intl/en_uk/adsense/start/">
              Google Adsense
            </a>{' '}
            to show advertising on certain pages. The money earned from
            advertising is used to help fund the costs of running the website.
          </p>
          <p className="my-2">
            Ads have been configured to be appropriate to the website audience.
          </p>
        </Module>
      </Main>
    </ThemedPage>
  );
};

export default PrivacyPage;
