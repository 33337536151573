import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { ButtonBar, Main, Module } from 'layouts';
import { ButtonGroup } from 'layouts/button-group';

import { showModal } from 'components/../lib/modal/modal-actions';
import Button from 'components/button';
import IfUserIs from 'components/if-user-is';
import PageHeading from 'components/page-heading';
import ThemedPage from 'components/themed-page';
import ThreadTable from 'components/thread-table';

import { COMMUNITY_PAGE } from '../../../gql/queries/community-page';

const CommunityPage = () => {
  const { data = { generalThreads: [], animationThreads: [] } } = useQuery(
    COMMUNITY_PAGE,
    {
      fetchPolicy: 'cache-and-network',
    }
  );
  const dispatch = useDispatch();

  const getButtonBar = () => {
    return (
      <ButtonBar>
        <ButtonGroup>
          <IfUserIs signedIn>
            <Button
              onClick={() => dispatch(showModal('CreateThreadModal'))}
              icon="fas fa-comment-dots"
              type="positive"
            >
              Create a new thread
            </Button>
          </IfUserIs>
        </ButtonGroup>

        <ButtonGroup align="right">
          <Button to="/community/general/1" icon="fas fa-angle-right">
            See more
          </Button>
        </ButtonGroup>
      </ButtonBar>
    );
  };

  const getAnimationButtonBar = () => {
    return (
      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            rightAlign
            to="/community/animation/1"
            icon="fas fa-angle-right"
          >
            See more
          </Button>
        </ButtonGroup>
      </ButtonBar>
    );
  };

  return (
    <ThemedPage>
      <Main>
        <Helmet>
          <title>{`The Animator - Community`}</title>
          <meta name="description" content={`The Animator Community`} />
        </Helmet>
        <PageHeading>Community</PageHeading>
        <Module>
          <h3>General Discussion</h3>
          <p>
            General chat not necessarily related to your animations. You can
            also report bugs and request new features in here.
          </p>
        </Module>
        {getButtonBar()}
        <Module>
          <ThreadTable threads={data.generalThreads} type="NORMAL" />
        </Module>
        {getButtonBar()}
        <Module>
          <h3>Animation Comments</h3>
          <p>Comments on public animations.</p>
        </Module>
        {getAnimationButtonBar()}
        <Module>
          <ThreadTable threads={data.animationThreads} type="ANIMATION" />
        </Module>
        {getAnimationButtonBar()}
      </Main>
    </ThemedPage>
  );
};

export default CommunityPage;
