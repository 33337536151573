import React, { ChangeEvent, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLazyQuery } from '@apollo/client';

import { SEARCH } from '../../../gql/queries/search';
import { TextInput } from '../../components';
import PageHeading from '../../components/page-heading';
import ThemedPage from '../../components/themed-page';
import { Main, Module } from '../../layouts';

import { ResultsAnimations } from './results-animations';
import { ResultsUsers } from './results-users';
import { useSearchParams } from './use-search-params';

const RESULTS_PER_CATEGORY = 20;
const SEARCH_DEBOUNCE_TIME_MS = 500;

const SearchPage = () => {
  const debounceTimerRef = useRef(null);

  const [doSearch, { data }] = useLazyQuery(SEARCH);
  const searchBoxRef = useRef<HTMLInputElement>();
  const { searchTerm, animationPage, userPage, updateUrl } = useSearchParams();

  useEffect(() => {
    searchBoxRef.current.focus();

    if (searchTerm) {
      searchBoxRef.current.value = searchTerm;
    }
  }, []);

  const performSearch = (searchTerm) => {
    doSearch({
      variables: {
        animationOffset: (animationPage - 1) * RESULTS_PER_CATEGORY,
        userOffset: (userPage - 1) * RESULTS_PER_CATEGORY,
        limit: RESULTS_PER_CATEGORY,
        searchTerm,
      },
    });

    updateUrl({ searchTerm });
  };

  const onSearchTermChanged = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length < 3) return;
    clearTimeout(debounceTimerRef.current);
    debounceTimerRef.current = setTimeout(() => {
      updateUrl({ animationPage: '1', userPage: '1' });
      performSearch(e.target.value);
    }, SEARCH_DEBOUNCE_TIME_MS);
  };

  useEffect(() => {
    if (searchTerm) {
      performSearch(searchTerm);
    }
  }, [searchTerm, animationPage, userPage]);

  const onAnimationPageChanged = (newPage) => {
    updateUrl({ animationPage: newPage });
  };

  return (
    <ThemedPage>
      <Main>
        <Helmet>
          <title>{`The Animator - Search`}</title>
          <meta name="description" content={`The Animator - search`} />
        </Helmet>
        <PageHeading>{`Search`}</PageHeading>
        <Module>
          <TextInput
            ref={searchBoxRef}
            className="text-lg"
            onChange={onSearchTermChanged}
            placeholder="Search for studios and animations"
          />
        </Module>

        <Module>
          <ResultsUsers users={data?.search.users} />
        </Module>
        <Module>
          <ResultsAnimations
            animations={data?.search.animations}
            total={data?.search.animationsCount}
            onPageChanged={onAnimationPageChanged}
            page={animationPage}
          />
        </Module>
      </Main>
    </ThemedPage>
  );
};

export default SearchPage;
