import React, { useState } from 'react';

import TabBar from '../../components/tab-bar/tab-bar';
import { Module } from '../../layouts';
import { ModalComponentProps } from '../modal';

import UserDetailsEmail from './user-details/email';
import UserDetailsUsername from './user-details/username';
import ModalTitle from './modal-title';

export default function UserDetailsModal(props: ModalComponentProps) {
  const [activeTab, setActiveTab] = useState('email');

  return (
    <div>
      <ModalTitle>My Account</ModalTitle>
      <Module>
        <TabBar
          selected={activeTab}
          onChange={(mode) => {
            setActiveTab(mode);
          }}
          options={[
            { id: 'email', value: 'email', label: 'Email' },
            { id: 'username', value: 'username', label: 'Username' },
          ]}
        />
      </Module>
      {activeTab === 'email' && (
        <UserDetailsEmail hideModal={props.hideModal} />
      )}
      {activeTab === 'username' && (
        <UserDetailsUsername hideModal={props.hideModal} />
      )}
    </div>
  );
}
