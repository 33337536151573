import React, { FC, Fragment, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../store';

const userSelector = (state: RootState) => {
  return {
    isAdmin: state.user.roles.includes('admin'),
    currentUser: state.user.id,
  };
};

type IfUserIsProps = {
  admin?: boolean;
  username?: string;
  children: ReactNode;
  not?: boolean;
  signedIn?: boolean;
};

const IfUserIs: FC<IfUserIsProps> = ({
  admin,
  username,
  children,
  not,
  signedIn,
}) => {
  const { isAdmin, currentUser } = useSelector(userSelector);
  let show = false;

  if (admin && isAdmin) show = true;
  if (username && username === currentUser) show = true;
  if (signedIn && currentUser) show = true;

  if (not) show = !show;

  if (show) {
    return <Fragment>{children}</Fragment>;
  }
  return null;
};

export default IfUserIs;
