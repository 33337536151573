import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Animation } from '../../../gql/generated/graphql';

type EnteredAnimationBlockProps = {
  animation: Animation;
};

const EnteredAnimationBlock: FC<EnteredAnimationBlockProps> = ({
  animation,
}) => {
  return (
    <span className="italic bg-white shadow-md inline-block py-0.5 px-2 leading-none text-center align-baseline rounded-full">
      <Link
        to={`/animation/${animation.url}`}
        className="text-black whitespace-normal"
      >
        <i className="fas fa-check" />{' '}
        {`Entered animation "${animation.title || '<untitled>'}"`}
      </Link>
    </span>
  );
};

export { EnteredAnimationBlock };
