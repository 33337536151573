import { selectToolState } from '../lib/tool-selectors';

import { GenericTool } from './generic';

export class EraserTool extends GenericTool {
  texture: HTMLCanvasElement;
  cachedSize: number;
  active = false;
  size: number;
  x: number;
  y: number;

  generateTexture(size: number) {
    const canvas = document.createElement('canvas');
    canvas.width = canvas.height = size;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = '#000000';
    ctx.arc(size / 2, size / 2, size / 2, 0, 2 * Math.PI);
    ctx.fill();

    this.texture = canvas;
    this.cachedSize = size;
  }

  getTexture(size: number) {
    if (!this.texture || size !== this.cachedSize) {
      this.generateTexture(size);
    }

    return this.texture;
  }

  static getSizeAdjustedCoords({ coords, size }) {
    let [x, y] = coords;

    x = Math.round(x - size / 2);
    y = Math.round(y - size / 2);

    return [x, y];
  }

  onStart({ coords }) {
    const { size } = selectToolState();
    const [x, y] = EraserTool.getSizeAdjustedCoords({ coords, size });
    this.x = x;
    this.y = y;
    this.active = true;
    this.generateTexture(size);
    this.size = size;

    this.reset();
    this.undoStackPush();

    this.visibleCtx.globalCompositeOperation = 'destination-out';
    this.visibleCtx.drawImage(this.getTexture(size), x, y, size, size);
    this.visibleCtx.globalCompositeOperation = 'source-over';
  }

  onMove({ coords }) {
    if (!this.active) return;

    const [x, y] = EraserTool.getSizeAdjustedCoords({
      coords,
      size: this.size,
    });

    // Line drawing algorithm
    let x0 = Math.round(this.x);
    let y0 = Math.round(this.y);
    const x1 = Math.round(x);
    const y1 = Math.round(y);

    const dx = Math.abs(x1 - x0);
    const dy = Math.abs(y1 - y0);
    const sx = x0 < x1 ? 1 : -1;
    const sy = y0 < y1 ? 1 : -1;
    let err = dx - dy;

    this.visibleCtx.globalCompositeOperation = 'destination-out';

    // eslint-disable-next-line no-constant-condition
    while (true) {
      this.visibleCtx.drawImage(
        this.getTexture(this.size),
        x0,
        y0,
        this.size,
        this.size
      );

      if (x0 == x1 && y0 == y1) break;
      const e2 = 2 * err;
      if (e2 > -dy) {
        err -= dy;
        x0 += sx;
      }
      if (e2 < dx) {
        err += dx;
        y0 += sy;
      }
    }
    this.x = x;
    this.y = y;
    this.visibleCtx.globalCompositeOperation = 'source-over';
  }

  onEnd() {
    this.active = false;
    this.x = null;
    this.y = null;
    this.commitVisible();
  }
}
