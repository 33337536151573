import { graphql } from '../generated';

export const MUTATE_ANIMATION_VIEW = graphql(`
  mutation MutateAnimationView($id: Int) {
    animationView(id: $id) {
      id
      views
    }
  }
`);
