import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import editorActions from '../editor-actions';
import { animationSelector } from '../selectors/animation-selector';
import { editorSelector } from '../selectors/editor-selector';

export default function LayerPicker() {
  const editor = useSelector(editorSelector);
  const animation = useSelector(animationSelector);
  const dispatch = useDispatch();

  return (
    <div className="text-center m-2">
      Layers to copy:{' '}
      <span>
        {animation.layers.map((layer, index) => {
          return (
            <span key={`layer-${index}`} className="mr-2">
              <input
                type="checkbox"
                id={`layer_${index}`}
                value={index}
                checked={editor.framePickerLayerMode[index]}
                onChange={() => {
                  dispatch(
                    editorActions.setFramePickerLayerVisibility({
                      layerIndex: index,
                      visible: !editor.framePickerLayerMode[index],
                    })
                  );
                }}
              />
              &nbsp;
              <label htmlFor={`layer_${index}`}>{layer}</label>
            </span>
          );
        })}
      </span>
    </div>
  );
}
