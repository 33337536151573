import React, { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';

type CompetitionCountdownProps = {
  endDate: number;
  compact?: boolean;
};

const CompetitionCountdown: FC<CompetitionCountdownProps> = ({
  endDate,
  compact = false,
}) => {
  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    if (dayjs().isAfter(dayjs(endDate))) {
      return setCountdown('Competition finished!');
    }

    let interval;

    const calculateCountdown = () => {
      if (dayjs().isAfter(dayjs(endDate))) {
        clearInterval(interval);
        return setCountdown('Competition finished!');
      }

      const endTimeHuman = dayjs
        .duration(endDate - dayjs().valueOf())
        .format(
          compact
            ? 'D[d] H[h] m[m] s[s]'
            : 'D [days] H [hours] m [mins] s [secs]'
        );
      setCountdown(`Ends in: ${endTimeHuman}`);
    };

    interval = setInterval(calculateCountdown, 1000);

    calculateCountdown();

    return function cleanup() {
      clearInterval(interval);
      interval = null;
    };
  }, [endDate]);

  return <span>{countdown}</span>;
};

export default CompetitionCountdown;
