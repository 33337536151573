import React, { Fragment, ReactNode, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Animation } from '../../../gql/generated/graphql';
import { ButtonBar, ButtonGroup, Module } from '../../layouts';
import { showModal } from '../../lib/modal/modal-actions';
import AnimationList from '../animation-list';
import Button from '../button';

const ANIMATIONS_PER_PAGE = 20;

type PaginatedGalleryProps = {
  page: number;
  totalAnimations: number;
  animations: Animation[];
  linkPrefix?: string;
  extraButtons?: ReactNode;
  showPublic?: boolean;
  loading?: boolean;
  editable?: boolean;
  onPageChanged?: CallableFunction;
};

const PaginatedGallery = (props: PaginatedGalleryProps) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [editing, setEditing] = useState(false);
  const dispatch = useDispatch();

  const onAnimationSelected = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((itemId) => itemId !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const renderPreviousLink = () => {
    const href = props.page === 2 ? props.linkPrefix : `${props.page - 1}`;

    const disabled = props.page === 1;

    if (props.onPageChanged) {
      return (
        <ButtonGroup>
          <Button
            onClick={() => props.onPageChanged(props.page - 1)}
            disabled={disabled}
            icon="fas fa-arrow-circle-left"
          >
            Newer
          </Button>
        </ButtonGroup>
      );
    }

    return (
      <ButtonGroup>
        <Button to={href} disabled={disabled} icon="fas fa-arrow-circle-left">
          Newer
        </Button>
      </ButtonGroup>
    );
  };

  const renderNextLink = () => {
    const disabled = props.page >= props.totalAnimations / ANIMATIONS_PER_PAGE;

    if (props.onPageChanged) {
      return (
        <ButtonGroup align="right">
          <Button
            onClick={() => props.onPageChanged(props.page + 1)}
            disabled={disabled}
            icon="fas fa-arrow-circle-right"
          >
            Older
          </Button>
        </ButtonGroup>
      );
    }

    return (
      <ButtonGroup align="right">
        <Button
          disabled={disabled}
          to={`${props.linkPrefix}/${props.page + 1}`}
          icon="fas fa-arrow-circle-right"
        >
          Older
        </Button>
      </ButtonGroup>
    );
  };

  const renderEditButtons = () => {
    if (!props.editable) return null;

    if (!editing) {
      return (
        <ButtonGroup align="centre">
          <Button
            onClick={() => {
              setSelectedItems([]);
              setEditing(true);
            }}
            icon="fas fa-edit"
          >
            Edit studio
          </Button>
        </ButtonGroup>
      );
    }

    return (
      <ButtonGroup align="centre" expand>
        <Button
          onClick={() => {
            setEditing(false);
            setSelectedItems([]);
          }}
          icon="fas fa-stop"
        >
          Finish editing
        </Button>
        <Button
          onClick={() => {
            dispatch(
              showModal('MoveAnimationsModal', { animations: selectedItems })
            );
            setEditing(false);
            setSelectedItems([]);
          }}
          icon="fas fa-folder"
          disabled={selectedItems.length === 0}
        >
          Move {selectedItems.length} items
        </Button>
      </ButtonGroup>
    );
  };

  const renderButtonBar = () => {
    return (
      <ButtonBar>
        {renderPreviousLink()}
        {renderEditButtons()}
        {props.extraButtons}
        {renderNextLink()}
      </ButtonBar>
    );
  };

  if (!props.animations.length) {
    return null;
  }

  return (
    <Fragment>
      {renderButtonBar()}
      <Module>
        <AnimationList
          animations={props.animations}
          showPublic={props.showPublic}
          loading={props.loading}
          selectMode={editing}
          selectedItems={selectedItems}
          onAnimationSelected={onAnimationSelected}
        />
      </Module>
      {renderButtonBar()}
    </Fragment>
  );
};

export default PaginatedGallery;
