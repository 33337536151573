const MIN_MODAL_TIME_MS = 1000;
let modalTimer;

export const showModal = function (component, componentProps) {
  clearTimeout(modalTimer);

  return {
    type: 'MODAL/SHOW',
    payload: {
      component,
      componentProps,
    },
  };
};

export const hideModal = function () {
  const now = Date.now();
  return function (dispatch, getState) {
    const timeShown = now - getState().modal.timeCreated;

    if (timeShown > MIN_MODAL_TIME_MS) {
      dispatch({
        type: 'MODAL/HIDE',
        payload: {},
      });
    } else {
      modalTimer = setTimeout(() => {
        dispatch({
          type: 'MODAL/HIDE',
          payload: {},
        });
      }, MIN_MODAL_TIME_MS - timeShown);
    }
  };
};
