import React, { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Animation } from '../../../gql/generated/graphql';
import { AnimationContent } from '../../components';
import { UserPill } from '../../components/user-pill/user-pill';
import Button from '../button';
import IfUserIs from '../if-user-is';

type ThreadAnimationEntityProps = {
  animation: Animation;
  voteButton?: boolean;
  onVotePressed?: (animationId: number) => void;
  voted?: boolean;
  votes?: number;
};

const ThreadAnimationEntity: FC<ThreadAnimationEntityProps> = ({
  animation,
  voteButton,
  onVotePressed,
  voted,
  votes,
}) => {
  const _onVotePressed = () => {
    onVotePressed(animation.id);
  };

  return (
    <span className="thread-animation-container max-w-sm flex-auto shadow-lg">
      <AnimationContent animation={animation} />
      <span className="thread-animation-info block">
        <Link to={`/animation/${animation.url}`}>{animation.title}</Link> by{' '}
        <UserPill user={animation.user} />
      </span>
      {(voteButton || votes !== undefined) && (
        <span className="block p-2">
          <span className="p-2">
            {votes} {votes === 1 ? 'vote' : 'votes'}
          </span>
          {!voted && voteButton && (
            <Fragment>
              <IfUserIs signedIn>
                <Button onClick={_onVotePressed}>Vote for this entry</Button>
              </IfUserIs>
              <IfUserIs not signedIn>
                <Button disabled>Sign-in to vote!</Button>
              </IfUserIs>
            </Fragment>
          )}
          {voted && voteButton && (
            <Fragment>
              <IfUserIs signedIn>
                <Button
                  onClick={_onVotePressed}
                  type="positive"
                  icon="fas fa-check"
                >
                  Voted!
                </Button>
              </IfUserIs>
              <IfUserIs not signedIn>
                <Button disabled>Sign-in to vote!</Button>
              </IfUserIs>
            </Fragment>
          )}
        </span>
      )}
    </span>
  );
};

export default ThreadAnimationEntity;
