import { graphql } from '../generated';

export const THREAD = graphql(/* GraphQL */ `
  query GetThread($id: Int) {
    thread(id: $id) {
      id
      userId
      title
      type
      createdAt
      updatedAt
      endDate
      locked
      pinned
      voteSummary {
        id
        userVotes
        animationVotes {
          animationId
          votes
        }
      }
      posts {
        ...PostsFields
      }
    }
  }
`);
