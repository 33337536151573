import React, { Fragment } from 'react';

import {
  AnimationType,
  Post,
  PostEntity,
  Thread,
} from '../../../gql/generated/graphql';
import config from '../../../lib/config';
import { UserPill } from '../../components/user-pill/user-pill';

import { CompetitionComicAnimationBlock } from './competition-comic-animation-block';
import { EnteredAnimationBlock } from './entered-animation-block';
import ThreadAnimationEntity from './thread-animation-entity';
import { UnavailableAnimationBlock } from './unavailable-animation-block';
import { WrongUserAnimationBlock } from './wrong-user-animation-block';

type BlockContentProps = {
  entities: PostEntity[];
  threadType: Thread['type'];
  parentPost: Post;
};

export const BlockContent = ({
  entities,
  threadType,
  parentPost,
}: BlockContentProps) => {
  return (
    <Fragment>
      {entities.map((entity, index) => {
        switch (entity.__typename) {
          case 'PostText':
            return <span key={index}>{entity.text}</span>;
          case 'PostUrl':
            return (
              <a
                key={index}
                href={entity.url}
                rel={entity.external ? 'nofollow' : ''}
              >
                {entity.title}
              </a>
            );
          case 'User':
            return <UserPill key={index} prefix="@" user={entity} />;
          case 'MissingUser':
            return <span key={index}>{`@${entity.name}`}</span>;
          case 'MissingAnimation':
            return <UnavailableAnimationBlock key={index} />;
          case 'Animation':
            if (
              threadType === 'COMPETITION' &&
              parentPost.userId !== config.botUserId
            ) {
              if (entity.user.id !== parentPost.user.id) {
                return <WrongUserAnimationBlock key={index} />;
              } else if (entity.type === AnimationType.Comic) {
                return <CompetitionComicAnimationBlock key={index} />;
              } else {
                return <EnteredAnimationBlock key={index} animation={entity} />;
              }
            } else {
              return <ThreadAnimationEntity key={index} animation={entity} />;
            }
        }
      })}
    </Fragment>
  );
};
