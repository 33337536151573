import { selectToolState } from '../lib/tool-selectors';

import { GenericTool } from './generic';

export class PaintTool extends GenericTool {
  texture: HTMLCanvasElement;
  cachedSize: number;
  cachedColour: string;
  x: number;
  y: number;
  active = false;
  colour: string;
  size: number;
  opacity: number;

  generateTexture(size: number, colour: string) {
    const canvas = document.createElement('canvas');
    canvas.width = canvas.height = size;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = colour;
    ctx.arc(size / 2, size / 2, size / 2, 0, 2 * Math.PI);
    ctx.fill();

    this.texture = canvas;
    this.cachedSize = size;
    this.cachedColour = colour;
  }

  getTexture(size: number, colour: string) {
    if (
      !this.texture ||
      size !== this.cachedSize ||
      this.cachedColour !== colour
    ) {
      this.generateTexture(size, colour);
    }

    return this.texture;
  }

  static getSizeAdjustedCoords({
    coords,
    size,
  }: {
    coords: number[];
    size: number;
  }) {
    let [x, y] = coords;

    x = Math.round(x - size / 2);
    y = Math.round(y - size / 2);

    return [x, y];
  }

  onStart({ coords }) {
    const { size, colour, opacity } = selectToolState();
    const [x, y] = PaintTool.getSizeAdjustedCoords({ coords, size });
    this.x = x;
    this.y = y;
    this.active = true;
    this.colour = colour;
    this.size = size;
    this.opacity = opacity;

    this.reset();
    this.undoStackPush();

    this.offscreenCtx.drawImage(
      this.getTexture(size, colour),
      x,
      y,
      size,
      size
    );
    this.paintToVisible();
  }

  onMove({ coords }) {
    if (!this.active) return;

    const [x, y] = PaintTool.getSizeAdjustedCoords({ coords, size: this.size });
    if (x === this.x && y === this.y) return;

    // Line drawing algorithm
    let x0 = this.x;
    let y0 = this.y;
    const x1 = x;
    const y1 = y;

    const dx = Math.abs(x1 - x0);
    const dy = Math.abs(y1 - y0);
    const sx = x0 < x1 ? 1 : -1;
    const sy = y0 < y1 ? 1 : -1;
    let err = dx - dy;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      this.offscreenCtx.drawImage(
        this.getTexture(this.size, this.colour),
        x0,
        y0,
        this.size,
        this.size
      );

      if (x0 == x1 && y0 == y1) break;
      const e2 = 2 * err;
      if (e2 > -dy) {
        err -= dy;
        x0 += sx;
      }
      if (e2 < dx) {
        err += dx;
        y0 += sy;
      }
    }

    this.x = x;
    this.y = y;
    this.paintToVisible();
  }

  onTap({ coords }) {
    this.reset();
    const { size, colour, opacity } = selectToolState();
    this.opacity = opacity;

    const [x, y] = PaintTool.getSizeAdjustedCoords({
      coords,
      size: size,
    });

    this.offscreenCtx.drawImage(
      this.getTexture(size, colour),
      x,
      y,
      size,
      size
    );

    this.paintToVisible();
    this.onEnd();
  }

  onEnd() {
    this.active = false;
    this.x = null;
    this.y = null;
    this.commitMerged();
  }
}
