import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { ButtonBar, Main, Module } from 'layouts';
import { ButtonGroup } from 'layouts/button-group';

import Button from 'components/button';
import PageHeading from 'components/page-heading';
import ThemedPage from 'components/themed-page';

import { MUTATE_CLEAR_NOTIFICATIONS } from '../../../gql/queries/mutate-clear-notifications';
import { MUTATE_MARK_NOTIFICATIONS_AS_READ } from '../../../gql/queries/mutate-mark-notifications-as-read';
import { MUTATE_NOTIFICATION } from '../../../gql/queries/mutate-notification';
import { NOTIFICATIONS } from '../../../gql/queries/notifications';

const renderNotificationItem = (notification, onNotificationClick) => {
  return (
    <li key={notification.id}>
      <Link
        data-id={notification.id}
        data-link={notification.link}
        to={notification.link}
        onClick={notification.viewed ? undefined : onNotificationClick}
      >
        {notification.text}
      </Link>{' '}
      {notification.viewed ? null : (
        <i className="fas fa-bell notification-icon" />
      )}
      <span className="notification-date">
        {dayjs(notification.createdAt).fromNow()}
      </span>
    </li>
  );
};

export default function NotificationsPage() {
  const { data = { notifications: [] } } = useQuery(NOTIFICATIONS, {
    fetchPolicy: 'cache-and-network',
  });
  const [mutateNotification] = useMutation(MUTATE_NOTIFICATION);
  const [mutateClearNotifications] = useMutation(MUTATE_CLEAR_NOTIFICATIONS, {
    update(cache) {
      cache.writeQuery({
        query: NOTIFICATIONS,
        data: { notifications: [] },
      });
    },
    refetchQueries: ['NotificationsCount'],
  });
  const [mutateMarkNotificationsAsRead] = useMutation(
    MUTATE_MARK_NOTIFICATIONS_AS_READ
  );

  const onNotificationClick = (e) => {
    mutateNotification({
      variables: { id: e.currentTarget.dataset.id, viewed: true },
    });
  };

  const onViewedAllNotificationsClick = () => {
    mutateMarkNotificationsAsRead();
  };

  const onClearAllNotificationsClick = () => {
    mutateClearNotifications();
  };

  return (
    <ThemedPage>
      <Main>
        <Helmet>
          <title>{`The Animator - My Notifications`}</title>
          <meta
            name="description"
            content={`The Animator - My Notifications`}
          />
        </Helmet>
        <PageHeading>My Notifications</PageHeading>
        <ButtonBar>
          <ButtonGroup>
            <Button
              icon="fas fa-glasses"
              onClick={onViewedAllNotificationsClick}
            >
              Mark all as read
            </Button>
            <Button
              icon="fas fa-trash-alt"
              type="negative"
              onClick={onClearAllNotificationsClick}
            >
              Clear all
            </Button>
          </ButtonGroup>
        </ButtonBar>
        <Module>
          <p>
            Notifications are automatically cleared one hour after being viewed,
            or one week after being sent.
          </p>
        </Module>
        {data.notifications.length === 0 ? (
          <Module>
            <div className="split-layout">
              <div className="split-layout--small">
                <img
                  src="/images/blue-monster.jpg"
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div className="split-layout--large">
                <p>No notifications. You&apos;re all caught up! </p>
              </div>
            </div>
          </Module>
        ) : null}
        <Module>
          <ol className="notifications-list">
            {data.notifications.map((notification) =>
              renderNotificationItem(notification, onNotificationClick)
            )}
          </ol>
        </Module>
      </Main>
    </ThemedPage>
  );
}
