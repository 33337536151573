import React from 'react';
import { Helmet } from 'react-helmet-async';

import { PageHeadingPlaceholder, ThemedPage } from '../../components';
import { Main, Module } from '../../layouts';

const ComicPageLoading = () => {
  return (
    <ThemedPage>
      <Main>
        <Helmet>
          <title>The Animator - Comic</title>
        </Helmet>
        <PageHeadingPlaceholder />

        <Module>
          <p>This will be a comic</p>
        </Module>
      </Main>
    </ThemedPage>
  );
};

export default ComicPageLoading;
