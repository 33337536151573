import React, { memo, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { NOTIFICATIONS_COUNT } from '../../../gql/queries/notifications-count';
import Button from '../button';

const POLL_TIME = 60000;

const Notifications = memo(() => {
  const {
    startPolling,
    stopPolling,
    data = { notificationsCount: 0 },
  } = useQuery(NOTIFICATIONS_COUNT, {
    pollInterval: POLL_TIME,
    errorPolicy: 'ignore',
  });

  useEffect(() => {
    startPolling(POLL_TIME);
    return () => {
      stopPolling();
    };
  });

  return (
    <Button
      type={data.notificationsCount > 0 ? 'negative' : 'inactive'}
      to="/notifications"
      icon={data.notificationsCount > 0 ? 'fas fa-bell' : 'far fa-bell'}
    >
      {data.notificationsCount}
    </Button>
  );
});

Notifications.displayName = 'Notifications';

export default Notifications;
