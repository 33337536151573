import { AnimationType } from '../../../gql/generated/graphql';
import { FrameImage } from '../../components/editor/canvas/lib/frame-image';

export type AnimationState = {
  url: string;
  id: number;
  sequence: AnimationFrame[];
  layers: string[];
  title: string;
  public: boolean;
  palette: string[];
  blacklisted: boolean;
  saved: boolean;
  width: number;
  height: number;
  savedLocally: boolean;
  folderId: string;
  pixelMode: AnimationPixelMode;
  type: AnimationType;
};

export type AnimationFrameLayer = {
  id: string;
  image: FrameImage;
};

export type AnimationFrame = {
  id: string;
  layers: AnimationFrameLayer[];
  delay: number;
};

export enum AnimationPixelMode {
  'STANDARD' = 'STANDARD',
  'PIXELATED' = 'PIXELATED',
}

const AnimationDefaultState: AnimationState = {
  url: null,
  id: null,
  sequence: [],
  layers: ['Background', 'Foreground'],
  title: '<untitled>',
  public: false,
  palette: [
    '#1abc9c',
    '#16a085',
    '#2ecc71',
    '#27ae60',
    '#3498db',
    '#2980b9',
    '#9b59b6',
    '#8e44ad',
    '#34495e',
    '#2c3e50',
    '#f1c40f',
    '#f39c12',
    '#e67e22',
    '#d35400',
    '#e74c3c',
    '#c0392b',
    '#ecf0f1',
    '#bdc3c7',
    '#95a5a6',
    '#7f8c8d',
  ],
  blacklisted: false,
  saved: false,
  savedLocally: false,
  width: 400,
  height: 300,
  folderId: null,
  pixelMode: AnimationPixelMode.STANDARD,
  type: AnimationType.Standalone,
};

export default AnimationDefaultState;
