import React, {
  FC,
  MouseEvent,
  ReactNode,
  TouchEvent,
  useRef,
  useState,
} from 'react';

const TRANSIENT_ANIMATION_TIME = 300;

type EditorButtonProps = {
  disabled?: boolean;
  transient?: boolean;
  onButtonPressed: (type: string) => void;
  active?: boolean;
  label: ReactNode;
  type?: string;
};

const EditorButton: FC<EditorButtonProps> = (props) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const [transition, setTransition] = useState<boolean>(false);

  const onButtonPressed = (event: MouseEvent | TouchEvent) => {
    event.preventDefault();
    if (props.disabled) return;
    props.onButtonPressed(props.type);
    setTransition(false);

    if (props.transient) {
      setTransition(true);
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setTransition(false);
      }, TRANSIENT_ANIMATION_TIME);
    }
  };

  const classes = `editor-button ${
    props.active ? 'editor-button--active' : ''
  } ${transition ? 'editor-button--active-transient' : ''} ${
    props.disabled ? 'editor-button--disabled' : ''
  }`;

  return (
    <button
      className={classes}
      onClick={onButtonPressed}
      onTouchEnd={onButtonPressed}
      disabled={props.disabled}
    >
      {props.label}
    </button>
  );
};

export default EditorButton;
