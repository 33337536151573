import React from 'react';
import { PropTypes } from 'prop-types';

import Button from 'components/button';

export default function TimePickerPreview(props) {
  return (
    <li className="overflow-hidden inline-block">
      <Button
        type={
          parseInt(props.currentValue) === props.delay ? 'positive' : 'neutral'
        }
        onClick={props.onClick}
        data-delay={props.delay}
      >
        <div>{props.delay} ms</div>
        <div
          className="timepicker-preview-animate"
          style={{
            animationName: 'timepicker-preview-walk',
            animationDuration: `${props.delay * 20}ms`,
            animationIterationCount: 'infinite',
          }}
        >
          <i className="fas fa-walking " />
        </div>
      </Button>
    </li>
  );
}

TimePickerPreview.propTypes = {
  delay: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  currentValue: PropTypes.number.isRequired,
};
