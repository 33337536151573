import { graphql } from '../generated';

export const GET_USER = graphql(`
  query GetUser($id: String) {
    user(id: $id) {
      id
      name
      dateJoined
      badge
      verified
      nameChangePermitted
      avatarUrl
    }
  }
`);
