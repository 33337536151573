import React, { ChangeEvent, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AnimationType } from '../../../gql/generated/graphql';
import {
  FolderSelector,
  PublicGallerySwitch,
  TextInput,
} from '../../components';
import Button from '../../components/button';
import editorActions from '../../components/editor/editor-actions';
import { animationSelector } from '../../components/editor/selectors/animation-selector';
import { ButtonBar, Module } from '../../layouts';
import { ButtonGroup } from '../../layouts/button-group';
import animationActions from '../../lib/animation/animation-actions';
import { AnimationPixelMode } from '../../lib/animation/animation-default-state';
import { useAppDispatch } from '../../store';
import { ModalComponentProps } from '../modal';

import ModalTitle from './modal-title';

type MoveAnimationsModalProps = ModalComponentProps & {
  animations: number[];
};

export default function SaveAnimationModal({
  hideModal,
}: MoveAnimationsModalProps) {
  const animation = useSelector(animationSelector);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const onPublicSwitchChange = (isPublic) => {
    dispatch(animationActions.setAnimationPublic(isPublic));
  };

  const onTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(animationActions.setAnimationTitle(event.target.value));
  };

  const onFolderChange = (folderId) => {
    dispatch(animationActions.setAnimationFolder(folderId));
  };

  const onSavePressed = () => {
    dispatch(editorActions.save({ history }));
  };

  const onPixelModeChanged = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(animationActions.setPixelMode(event.target.value));
  };

  return (
    <div>
      <ModalTitle>Save Animation</ModalTitle>
      <Module>
        <p>Changes to the options take effect immediately in the editor.</p>
        <table>
          <tr>
            <td>Title</td>
            <td>
              <TextInput
                defaultValue={animation.title}
                onChange={onTitleChange}
              />
            </td>
          </tr>
          {animation.type === AnimationType.Standalone && (
            <Fragment>
              <tr>
                <td>Folder</td>
                <td>
                  <FolderSelector
                    currentFolderId={animation.folderId}
                    onFolderSelected={onFolderChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Publish</td>
                <td>
                  <PublicGallerySwitch
                    isPublic={animation.public}
                    onAnimationPublicToggled={onPublicSwitchChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Mode</td>
                <td>
                  <input
                    type="radio"
                    name="pixelMode"
                    value={AnimationPixelMode.STANDARD}
                    id="pixelModeStandard"
                    checked={
                      animation.pixelMode === AnimationPixelMode.STANDARD
                    }
                    onChange={onPixelModeChanged}
                  />{' '}
                  <label htmlFor="pixelModeStandard">Standard video</label>{' '}
                  <input
                    type="radio"
                    name="pixelMode"
                    value={AnimationPixelMode.PIXELATED}
                    id="pixelModePixelArt"
                    checked={
                      animation.pixelMode === AnimationPixelMode.PIXELATED
                    }
                    onChange={onPixelModeChanged}
                  />{' '}
                  <label htmlFor="pixelModePixelArt">Pixel art</label>
                </td>
              </tr>
            </Fragment>
          )}
        </table>
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button onClick={hideModal} type="negative">
            <i className="fa fa-ban" /> Cancel
          </Button>
          <Button
            onClick={onSavePressed}
            type="positive"
            icon="fas fa-cloud-upload-alt"
            rightAlign
          >
            Save
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
}
