import React from 'react';
import { Link } from 'react-router-dom';

import IfUserIs from 'components/if-user-is';
import Notifications from 'components/notifications/notifications';
import UserToolbar from 'components/user-toolbar';

export const Header = () => {
  return (
    <div className="header">
      <Link to="/" className="hover:no-underline">
        <h1 className="mr-4 text-black">The Animator</h1>
      </Link>
      <nav className="main-nav">
        <Link to="/gallery">Gallery</Link>
        <Link to="/editor">Editor</Link>
        <Link to="/my-studio">My Studio</Link>
        <Link to="/community">Community</Link>
      </nav>
      <div className="flex flex-row justify-between space-x-2 items-center">
        <Link to="/search">
          <i className="fas fa-search"></i>
        </Link>
        <IfUserIs signedIn>
          <Notifications />
        </IfUserIs>
        <UserToolbar />
      </div>
    </div>
  );
};
