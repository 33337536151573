import React, { FC, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { Thread } from '../../../gql/generated/graphql';
import { MUTATE_CLOSE_COMPETITION } from '../../../gql/queries/mutate-close-competition';
import { MUTATE_DELETE_THREAD } from '../../../gql/queries/mutate-delete-thread';
import { Button } from '../../components';

type ThreadAdminControlsProps = {
  thread: Thread;
};

const ThreadAdminControls: FC<ThreadAdminControlsProps> = ({ thread }) => {
  const history = useHistory();

  const [deleteThread] = useMutation(MUTATE_DELETE_THREAD, {
    onCompleted: () => {
      history.push('/community');
    },
  });

  const [closeCompetition] = useMutation(MUTATE_CLOSE_COMPETITION);

  const onDeleteThreadPressed = () => {
    if (!window.confirm('Delete this thread?')) return;
    deleteThread({ variables: { id: thread.id } });
  };

  const onCloseCompetitionPressed = () => {
    closeCompetition({ variables: { threadId: thread.id } });
  };

  return (
    <Fragment>
      {thread.type === 'COMPETITION' && (
        <Button
          icon="fas fa-trophy"
          type="neutral"
          onClick={onCloseCompetitionPressed}
        >
          Close competition
        </Button>
      )}
      <Button
        icon="fas fa-trash-alt"
        type="negative"
        onClick={onDeleteThreadPressed}
      >
        Delete thread
      </Button>
    </Fragment>
  );
};

export default ThreadAdminControls;
