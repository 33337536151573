import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { FOLLOWING_FEED } from '../../../gql/queries/following-feed';
import PageHeading from '../../components/page-heading';
import PaginatedGallery from '../../components/paginated-gallery';
import { TabNav } from '../../components/tab-nav/tab-nav';
import ThemedPage from '../../components/themed-page';
import { Main, Module } from '../../layouts';

const ANIMATIONS_PER_PAGE = 20;

const FollowingPage = () => {
  const { currentPage: pageParam = '1' } = useParams<{ currentPage: string }>();
  const currentPage = parseInt(pageParam, 10);
  const {
    loading,
    data = {
      followingFeed: {
        animations: new Array(20).fill({}),
        count: 0,
      },
    },
  } = useQuery(FOLLOWING_FEED, {
    variables: {
      offset: (currentPage - 1) * ANIMATIONS_PER_PAGE,
      limit: ANIMATIONS_PER_PAGE,
    },
    fetchPolicy: 'cache-and-network',
  });

  const totalAnimations = data.followingFeed.count;

  return (
    <ThemedPage>
      <Main>
        <Helmet>
          <title>{`The Animator - Following page ${currentPage}`}</title>
          <meta
            name="description"
            content={`The Animator - animations from my followed animators`}
          />
        </Helmet>
        <PageHeading>{`Following gallery - page ${currentPage}`}</PageHeading>
        <TabNav>
          <Link to="/gallery">Public gallery</Link>
          <span>Animators I follow</span>
        </TabNav>

        {!data.followingFeed.animations.length && (
          <Module>
            <p>No animations yet - follow some studios!</p>
          </Module>
        )}

        <PaginatedGallery
          page={currentPage}
          totalAnimations={totalAnimations}
          animations={data.followingFeed.animations}
          linkPrefix="/gallery/following"
          loading={loading}
          showPublic={false}
        />
      </Main>
    </ThemedPage>
  );
};

export default FollowingPage;
