import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { Main, Module } from 'layouts';
import PropTypes from 'prop-types';

import Button from 'components/button';
import IfUserIs from 'components/if-user-is';
import ThemedHeading from 'components/themed-heading';
import ThemedPage from 'components/themed-page';
import { UserPill } from 'components/user-pill/user-pill';

import { ADMIN_RELATED_USERS } from '../../../gql/queries/admin-related-users';
import { GET_FOLLOWS } from '../../../gql/queries/follows';
import { MUTATE_USER } from '../../../gql/queries/mutate-user';
import { PUBLIC_STUDIO } from '../../../gql/queries/public-studio';
import { textColorForBackground } from '../../../lib/text-color-for-background';
import usernameThemeColour from '../../../lib/user-name-theme-colour';
import Avatar from '../../components/avatar/avatar';
import FollowButton from '../../components/follow-button/follow-button';
import { Follows } from '../../components/follows/follows';
import PaginatedGallery from '../../components/paginated-gallery';
import { Statistic } from '../../components/statistic/statistic';
import currentUserSelector from '../../lib/selectors/current-user-selector';

const ANIMATIONS_PER_PAGE = 20;

const generatePosessive = function (username) {
  if (!username) return '';
  return username.slice(-1) === 's' ? "'" : "'s";
};

const Studio = () => {
  const { username, currentPage: currentPageParam } = useParams();
  const currentUser = useSelector(currentUserSelector);

  const currentPage = parseInt(currentPageParam || 1, 10);
  const {
    loading,
    data = {
      publicAnimations: new Array(20).fill({}),
      user: { theme: {}, aliases: [] },
    },
  } = useQuery(PUBLIC_STUDIO, {
    variables: {
      offset: (currentPage - 1) * ANIMATIONS_PER_PAGE,
      limit: ANIMATIONS_PER_PAGE,
      name: username,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: followerData } = useQuery(GET_FOLLOWS, {
    variables: { userId: data.user.id },
  });

  const avatarColour = usernameThemeColour(data.user);

  const [getAdminData, { data: adminData = { adminRelatedUsers: [] } }] =
    useLazyQuery(ADMIN_RELATED_USERS, {
      variables: {
        name: username,
      },
      ssr: false, // https://github.com/apollographql/apollo-client/issues/9108
    });

  const [mutateUser] = useMutation(MUTATE_USER);

  useEffect(() => {
    if (currentUser.roles.includes('admin')) {
      getAdminData();
    }
  }, [currentUser]);

  const onBanUserPressed = () => {
    mutateUser({ variables: { name: username, banned: !data.user.banned } });
  };

  const onBadgeUserPressed = () => {
    if (data.user.badge !== 'DEFAULT') {
      mutateUser({
        variables: { name: username, badge: 'DEFAULT', badgeExpires: null },
      });
    } else {
      mutateUser({
        variables: {
          name: username,
          badge: 'WINNER',
          badgeExpires: dayjs().add(1, 'week').format('YYYY-MM-DD HH:mm:ss'),
        },
      });
    }
  };

  const renderNoAnimationsPlaceholder = () => {
    if (data.publicAnimations.length > 0) return null;
    return (
      <Module>
        <p>{username} hasn&apos;t created any public animations yet!</p>
        <p>Check back later to see their creations.</p>
        <p>Happy animating!</p>
      </Module>
    );
  };

  const renderRelatedUsers = (users = []) => {
    return users.map((user) => {
      return (
        <span key={user.id}>
          <a href={`/studio/${user.name}`}>{user.name}</a>{' '}
          {user.banned ? '(B)' : ''}
          {user.verified ? '(V)' : ''}{' '}
        </span>
      );
    });
  };

  const renderBanButton = (isBanned) => {
    return isBanned ? (
      <Button onClick={onBanUserPressed} type="positive">
        Unban
      </Button>
    ) : (
      <Button onClick={onBanUserPressed} type="negative">
        Ban
      </Button>
    );
  };

  const renderBadgeButton = (isBadged) => {
    return isBadged === 'WINNER' ? (
      <Button onClick={onBadgeUserPressed} type="negative">
        Remove badge
      </Button>
    ) : (
      <Button onClick={onBadgeUserPressed} type="positive">
        Reward badge
      </Button>
    );
  };

  const renderAdminSection = () => {
    return (
      <div>
        <ul>
          <li>
            IP:{' '}
            <a href={`https://tools.keycdn.com/geo?host=${data.user.ip}`}>
              {data.user.ip}
            </a>
          </li>
          <li>Verified: {`${data.user.verified ? 'Yes' : 'No'}`}</li>
          <li>
            Badged: {`${data.user.badge === 'WINNER' ? 'Yes' : 'No'}`}{' '}
            {renderBadgeButton(data.user.badge)}
          </li>
          <li>
            Banned: {`${data.user.banned ? 'Yes' : 'No'}`}{' '}
            {renderBanButton(data.user.banned)}
          </li>
          <li>
            Related users: {renderRelatedUsers(adminData.adminRelatedUsers)}
          </li>
        </ul>
      </div>
    );
  };

  return (
    <ThemedPage theme={data.user.theme}>
      <Main theme={data.user.theme}>
        <Helmet>
          <title>{`The Animator - ${username}${generatePosessive(
            username
          )} studio`}</title>
          <meta
            name="description"
            content={`${username}${generatePosessive(
              username
            )} animation studio`}
          />
        </Helmet>
        <ThemedHeading theme={data.user.theme}>
          <UserPill user={data.user} suffix={generatePosessive(username)} />{' '}
          Studio
        </ThemedHeading>
        <Module>
          <div className="flex flex-col sm:flex-row">
            <div className="text-center flex-shrink-0 mr-2 flex items-center flex-col mb-2">
              <div
                className="w-32 h-32 border-2 rounded-md text-center shadow-md"
                style={{
                  imageRendering: 'pixelated',
                  background: `${avatarColour}`,
                  color: textColorForBackground(avatarColour),
                }}
              >
                <Avatar user={data.user} />
              </div>
              <FollowButton userId={data.user.id} />
            </div>
            <div className="flex-1">
              <div className="flex mb-2 justify-center sm:justify-start">
                <Statistic title="Member for" icon="fas fa-calendar-alt">
                  {dayjs(data.user.dateJoined).fromNow(true)}
                </Statistic>
                <Statistic title="Animations" icon="fas fa-images">
                  {data.publicAnimationsCount}
                </Statistic>
              </div>

              <Follows
                title="Followed by"
                users={followerData?.follows.followers}
              />
              <Follows
                title="Following"
                users={followerData?.follows.following}
              />

              <p className="text-gray-500 text-sm">
                {data.user.aliases.length > 1
                  ? `Formerly known as: ${data.user.aliases
                      .filter((n) => n !== data.user.name)
                      .join(', ')}.`
                  : ''}
              </p>
            </div>
          </div>

          <IfUserIs admin>{renderAdminSection()}</IfUserIs>
        </Module>

        {renderNoAnimationsPlaceholder()}
        <PaginatedGallery
          page={currentPage}
          totalAnimations={data.publicAnimationsCount}
          animations={data.publicAnimations}
          linkPrefix={`/studio/${username}`}
          showPublic={false}
          loading={loading}
        />
      </Main>
    </ThemedPage>
  );
};

Studio.propTypes = {
  username: PropTypes.string,
};

export default Studio;
