import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { ButtonBar, Module } from 'layouts';
import { ButtonGroup } from 'layouts/button-group';

import Button from 'components/button';
import { Textarea, TextInput } from 'components/forms';
import UserError from 'components/user-error';

import { MUTATE_CREATE_POST } from '../../../gql/queries/mutate-create-post';
import IfUserIs from '../../components/if-user-is';
import { hideModal, showModal } from '../../lib/modal/modal-actions';

import ModalTitle from './modal-title';

const CHARACTERS_REGEX = /[a-zA-Z0-9]/;

const CreateThread = () => {
  const titleInputRef = useRef();
  const textInputRef = useRef();
  const selectTypeRef = useRef();
  const endDateRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();

  const [createPost] = useMutation(MUTATE_CREATE_POST, {
    onCompleted: (data) => {
      history.push(`/community/general/thread/${data.createPost.id}`);
    },
    onError: (e) => {
      const { code } = e.graphQLErrors[0].extensions;

      switch (code) {
        case 'POST_RATE_LIMITED':
          dispatch(showModal('PostRateLimited'));
          break;

        case 'POST_BANNED':
          dispatch(
            showModal('PostBannedModal', {
              bannedUntil: dayjs().to(
                dayjs(e.graphQLErrors[0].extensions.expiry)
              ),
            })
          );
          break;

        case 'USER_BANNED':
          dispatch(showModal('UserBannedModal'));
          break;

        case 'USER_UNVERIFIED':
          dispatch(showModal('UserUnverifiedModal'));
          break;
      }
    },
  });

  const onCancelClicked = () => {
    dispatch(hideModal());
  };

  const [error, setError] = useState();

  const onPostClicked = () => {
    if (
      !CHARACTERS_REGEX.test(titleInputRef.current.value) ||
      !textInputRef.current.value.trim()
    ) {
      setError(true);
      return;
    }

    const postType = selectTypeRef.current?.value ?? 'NORMAL';

    createPost({
      variables: {
        title: titleInputRef.current.value,
        text: textInputRef.current.value,
        type: postType,
        endDate: postType === 'COMPETITION' ? endDateRef.current?.value : null,
      },
    });

    dispatch(hideModal());
  };

  return (
    <div>
      <ModalTitle>Create a new thread</ModalTitle>
      {error && (
        <UserError errors={{ empty: 'Please enter a title and post text' }} />
      )}
      <Module>
        <TextInput
          type="text"
          name="thread-title"
          label="Thread title"
          id="thread-title-input"
          ref={titleInputRef}
        />
      </Module>
      <Module>
        <Textarea
          ref={textInputRef}
          label="Your post"
          id="thread-post-textarea"
          maxLength="2000"
        />
      </Module>
      <IfUserIs admin>
        <Module>
          Thread type:{' '}
          <select ref={selectTypeRef}>
            <option value="NORMAL">Normal</option>
            <option value="COMPETITION">Competition</option>
          </select>
        </Module>
        <Module>
          <TextInput
            type="text"
            name="thread-end-date"
            label="End date"
            id="thread-end-date-input"
            defaultValue={dayjs().add(5, 'days').format('YYYY-MM-DD HH:mm:ss')}
            ref={endDateRef}
          />
        </Module>
      </IfUserIs>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-times-circle"
            onClick={onCancelClicked}
            type="negative"
          >
            Cancel
          </Button>
          <Button
            icon="fas fa-plus-circle"
            onClick={onPostClicked}
            type="positive"
          >
            Create Thread
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
};

export default CreateThread;
