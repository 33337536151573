import { useDispatch } from 'react-redux';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import editorReducer from './components/editor/editor-reducer';
import animationReducer from './lib/animation/animation-reducer';
import errors from './lib/error/error-reducer';
import modalReducer from './lib/modal/modal-reducer';
import pageData from './lib/page-data/page-data-reducer';
import userReducer from './lib/user/user-reducer';

const rootReducer = combineReducers({
  editor: editorReducer,
  animation: animationReducer,
  user: userReducer,
  modal: modalReducer,
  errors,
  pageData,
});

export const createStore = (preloadedState = {}) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

let preloadedState = {};

if (typeof window !== 'undefined') {
  preloadedState = window?.initialStore ?? {};
}

export const store = createStore(preloadedState);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
