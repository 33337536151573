import React from 'react';

const CompetitionComicAnimationBlock = () => {
  return (
    <span className="italic bg-white shadow-md inline-block py-0.5 px-2 leading-none text-center align-baseline rounded-full">
      <i className="fas fa-times" /> Cannot enter a comic
    </span>
  );
};

export { CompetitionComicAnimationBlock };
