import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import editorActions from './editor-actions';

export const useKeyboardShortcuts = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const keyListener = (event) => {
      if (event.code === 'ArrowRight') {
        return dispatch(editorActions.nextFrame());
      }
      if (event.code === 'ArrowLeft') {
        return dispatch(editorActions.previousFrame());
      }
    };

    document.addEventListener('keydown', keyListener);

    return function cleanup() {
      document.removeEventListener('keydown', keyListener);
    };
  });
};
