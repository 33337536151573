import { v1 as uuidv1 } from 'uuid';

import { Animation } from '../../../gql/generated/graphql';

export const serialiseSequence = (sequence) => {
  const imageIds = [];
  const images = [];

  const flattenedSequence = sequence
    .map((sequenceItem) => {
      return `${sequenceItem.delay}:${sequenceItem.layers
        .map((layer) => {
          if (layer.image.id === '_blank') {
            return 'b';
          } else if (imageIds.includes(layer.image.id)) {
            return imageIds.indexOf(layer.image.id);
          } else {
            images.push(layer.image.getData());
            return imageIds.push(layer.image.id) - 1;
          }
        })
        .join(',')}`;
    })
    .join('/');

  return { flattenedSequence, images };
};

export const convertLegacySequence = (sequence: string): string => {
  try {
    const legacySequence = JSON.parse(sequence);
    return legacySequence
      .map((sequenceItem) => {
        return `${sequenceItem[1]}:b,${sequenceItem[0]}`;
      })
      .join('/');
  } catch (e) {
    return sequence;
  }
};

export const createBackgroundFrame = ({ width, height }) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = width;
  canvas.height = height;
  ctx.fillStyle = 'white';
  ctx.fillRect(0, 0, width, height);

  return canvas;
};

type UnserialiseSequenceArgs = {
  sequence: Animation['sequence'];
};

export type PlaceholderImageIds = string[];

export type SequenceFramePlaceholder = {
  id: string;
  layers: PlaceholderImageIds;
  delay: number;
};

type UnserialiseSequenceResult = {
  sequence: SequenceFramePlaceholder[];
  imagesToLoad: string[];
};

export const unserialiseSequence = ({
  sequence,
}: UnserialiseSequenceArgs): UnserialiseSequenceResult => {
  const normalisedSequence = convertLegacySequence(sequence);
  const imagesToLoad = [];

  const unserialisedSequence = normalisedSequence
    .split('/')
    .map((sequenceItem) => {
      const [delay, layerParts] = sequenceItem.split(':');
      const layerImageIndexes = layerParts.split(',');

      return {
        id: uuidv1(),
        delay: parseInt(delay, 10),
        layers: layerImageIndexes.map((layerImageIndex) => {
          if (layerImageIndex === 'b') {
            return '_blank';
          } else if (!imagesToLoad.includes(layerImageIndex)) {
            imagesToLoad.push(layerImageIndex);
          }
          return layerImageIndex;
        }),
      };
    });

  return { sequence: unserialisedSequence, imagesToLoad };
};
