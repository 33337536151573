import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';

import { MUTATE_REPORT_POST } from '../../../gql/queries/mutate-report-post';
import Button from '../../components/button';
import { TextInput } from '../../components/forms';
import { ButtonBar, Module } from '../../layouts';
import { ButtonGroup } from '../../layouts/button-group';
import { hideModal, showModal } from '../../lib/modal/modal-actions';
import { ModalComponentProps } from '../modal';

import ModalTitle from './modal-title';

type PostReportModalProps = ModalComponentProps & {
  postId: number;
};

const PostReportModal = (props: PostReportModalProps) => {
  const inputRef = useRef<HTMLInputElement>();
  const dispatch = useDispatch();
  const [reportPost] = useMutation(MUTATE_REPORT_POST, {
    onCompleted: () => {
      dispatch(showModal('ReportPostSubmittedModal'));
    },
    onError: () => {
      dispatch(showModal('ReportPostFailedModal'));
    },
  });

  const onCancelClicked = () => {
    dispatch(hideModal());
  };

  const onReportClicked = () => {
    reportPost({
      variables: {
        id: props.postId,
        reason: inputRef.current.value,
      },
    });
    dispatch(hideModal());
  };

  return (
    <div>
      <ModalTitle>Report Inappropriate Comment</ModalTitle>
      <Module>
        <p>Comments should be suitable for all ages.</p>
        <p>Some things considered inappropriate include:</p>
        <ul className="list-disc list-inside">
          <li>Swearing or offensive language</li>
          <li>Spam</li>
        </ul>
      </Module>
      <Module>
        <p>
          If you think this comment is not appropriate, you can report it here.
          You can optionally submit a reason why you think it is not
          appropriate.
        </p>
      </Module>
      <Module>
        <TextInput
          type="text"
          name="report-reason"
          label="Report reason:"
          ref={inputRef}
        />
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-times-circle"
            onClick={onCancelClicked}
            type="negative"
          >
            Cancel
          </Button>
          <Button icon="fas fa-flag" onClick={onReportClicked} type="positive">
            Submit report
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
};

export default PostReportModal;
