import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import dayjs from 'dayjs';

import { Animation } from '../../../gql/generated/graphql';
import config from '../../../lib/config';

type AnimationMetaProps = {
  animation: Animation;
};

export const AnimationMeta: FC<AnimationMetaProps> = ({ animation }) => {
  const isVideo = animation.sequence.length > 1;

  const imageThumb =
    animation.schemaVersion > 1
      ? `${config.s3Path}/${animation.url}/poster.png?v=${animation.version}`
      : `${config.s3Path}/${animation.url}/0.png?v=${animation.version}`;

  return (
    <Helmet>
      <meta
        name="description"
        content={`${animation.title} - an animation by ${
          animation.user.name
        }. Created ${dayjs(animation.dateCreated).fromNow()}.`}
      />
      {isVideo && (
        <meta
          property="og:video"
          content={`${config.s3Path}/${animation.url}/animation.mp4?v=${animation.version}`}
        />
      )}
      {isVideo && (
        <meta
          property="og:video:secure_url"
          content={`${config.s3Path}/${animation.url}/animation.mp4?v=${animation.version}`}
        />
      )}
      {isVideo && <meta property="og:video:type" content="video/mp4" />}
      <meta
        property="og:title"
        content={`${animation.title} - by ${animation.user.name}`}
      />
      <meta
        property="og:description"
        content={`An animation created on The Animator ${dayjs(
          animation.dateCreated
        ).fromNow()}.`}
      />
      {isVideo && <meta property="og:type" content="video.movie" />}
      <meta
        property="og:url"
        content={`https://theanimator.co.uk/animation/${animation.url}`}
      />
      <meta property="og:image" content={imageThumb} />
      <meta property="og:image:width" content={`${animation.width}`} />
      <meta property="og:image:height" content={`${animation.height}`} />
      {isVideo && (
        <meta property="og:video:width" content={`${animation.width}`} />
      )}
      {isVideo && (
        <meta property="og:video:height" content={`${animation.height}`} />
      )}
      <link
        rel="canonical"
        href={`https://theanimator.co.uk/animation/${animation.url}`}
      />
    </Helmet>
  );
};
