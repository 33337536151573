import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Main } from 'layouts';
import { ButtonGroup } from 'layouts/button-group';

import { PUBLIC_GALLERY } from '../../../gql/queries/public-gallery';
import { IfUserIs } from '../../components';
import Button from '../../components/button';
import JsonLd from '../../components/jsonLd';
import PageHeading from '../../components/page-heading';
import PaginatedGallery from '../../components/paginated-gallery';
import { TabNav } from '../../components/tab-nav/tab-nav';
import ThemedPage from '../../components/themed-page';

const ANIMATIONS_PER_PAGE = 20;

const GalleryPage = () => {
  const history = useHistory();
  const { currentPage: pageParam } = useParams();
  const currentPage = parseInt(pageParam || 1, 10);
  const {
    loading,
    data = {
      dailyAnimations: new Array(20).fill({}),
      aggregatedPublicAnimationsCount: 0,
    },
  } = useQuery(PUBLIC_GALLERY, {
    variables: {
      offset: (currentPage - 1) * ANIMATIONS_PER_PAGE,
      limit: ANIMATIONS_PER_PAGE,
    },
    fetchPolicy: 'cache-and-network',
  });

  const totalAnimations = data.aggregatedPublicAnimationsCount;

  const onRandomButtonClicked = () => {
    const randomPage =
      Math.floor(Math.random() * (totalAnimations / ANIMATIONS_PER_PAGE)) + 1;

    if (randomPage === 1) {
      history.push(`/gallery`);
      return;
    }

    history.push(`/gallery/${randomPage}`);
  };

  const getUsernamesForMeta = () => {
    return data.dailyAnimations
      .map((animation) => animation.username)
      .slice(0, 5)
      .filter(function (item, pos, self) {
        return self.indexOf(item) == pos;
      })
      .join(', ');
  };

  const getListItemMetadata = (animation, index) => {
    return {
      '@type': 'ListItem',
      name: animation.title,
      position: index + 1,
      url: `https://theanimator.co.uk/animation/${animation.url}`,
    };
  };

  const getJsonLd = () => {
    const jsonLdData = {
      '@context': 'https://schema.org',
      '@type': 'ItemList',
      itemListElement: data.dailyAnimations.map(getListItemMetadata),
    };
    return <JsonLd data={jsonLdData} />;
  };

  const galleryButton = (
    <ButtonGroup align="centre">
      <Button
        onClick={onRandomButtonClicked}
        disabled={totalAnimations === 0}
        icon="fas fa-random"
      >
        Random
      </Button>
    </ButtonGroup>
  );

  return (
    <ThemedPage>
      <Main>
        <Helmet>
          <title>{`The Animator - Gallery page ${currentPage}`}</title>
          <meta
            name="description"
            content={`The Animator Gallery page ${currentPage}. Animations by ${getUsernamesForMeta()} and more...`}
          />
        </Helmet>
        {getJsonLd()}
        <PageHeading>{`Gallery - page ${currentPage}`}</PageHeading>
        <TabNav>
          <span>Public gallery</span>
          <IfUserIs signedIn>
            <Link to="/gallery/following">Animators I follow</Link>
          </IfUserIs>
        </TabNav>

        <PaginatedGallery
          page={currentPage}
          totalAnimations={totalAnimations}
          animations={data.dailyAnimations}
          linkPrefix="/gallery"
          extraButtons={galleryButton}
          loading={loading}
          showPublic={false}
        />
      </Main>
    </ThemedPage>
  );
};

export default GalleryPage;
