import React, { useEffect } from 'react';

import config from '../../../lib/config';
import { usePollForProperty } from '../../hooks/use-poll-for-property';

export const AppleIdButton = () => {
  const buttonAvailable = usePollForProperty(
    typeof window !== 'undefined' && window.AppleID
  );

  useEffect(() => {
    if (!buttonAvailable) return;
    window.AppleID.auth.init({
      clientId: 'co.uk.theanimator',
      scope: 'email',
      redirectURI: config.appleAuthRedirectUrl,
      // state: '[STATE]',
      // nonce: '[NONCE]',
      usePopup: false, //or false defaults to false
    });
  }, [buttonAvailable]);

  return (
    <div
      id="appleid-signin"
      data-color="black"
      data-border="false"
      data-type="sign in"
      data-width="215"
      data-height="41"
      data-border-radius="0"
    ></div>
  );
};
