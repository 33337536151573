import React from 'react';
import PropTypes from 'prop-types';

import { AnimationListItem } from './animation-list-item';

const AnimationList = ({
  animations,
  showPublic,
  selectMode,
  onAnimationSelected,
  selectedItems,
}) => {
  const renderList = () => {
    return animations.map((animation, index) => (
      <AnimationListItem
        animation={animation}
        showPublic={showPublic}
        key={`${index}-${animation.id}`}
        selectMode={selectMode}
        onAnimationSelected={onAnimationSelected}
        selected={selectedItems.includes(animation.id)}
      />
    ));
  };

  return (
    <div className="animation-list__container">
      <ol className="animation-list">{renderList()}</ol>
    </div>
  );
};

AnimationList.propTypes = {
  animations: PropTypes.array.isRequired,
  showPublic: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  selectMode: PropTypes.bool,
  onAnimationSelected: PropTypes.func,
  selectedItems: PropTypes.array,
};

AnimationList.defaultProps = {
  animations: [],
  selectedItems: [],
};

export default AnimationList;
