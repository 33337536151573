import { graphql } from '../generated';

export const MUTATE_MARK_NOTIFICATIONS_AS_READ = graphql(`
  mutation MarkNotificationsAsRead {
    markNotificationsAsRead {
      id
      viewed
    }
  }
`);
