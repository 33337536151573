import { graphql } from '../generated';

export const MUTATE_POST = graphql(`
  mutation MutatePost($id: Int, $hidden: Boolean, $raw: String) {
    post(id: $id, hidden: $hidden, raw: $raw) {
      id
      hidden
      threadId
      raw
      blocks {
        type
        entities {
          ... on Animation {
            id
            title
            url
            schemaVersion
            version
            encoded
            user {
              id
              name
              theme {
                username
              }
            }
          }

          ... on MissingAnimation {
            url
          }

          ... on User {
            name
            theme {
              username
            }
          }

          ... on MissingUser {
            name
          }

          ... on PostText {
            text
          }

          ... on PostUrl {
            url
            title
            external
          }
        }
      }
    }
  }
`);

export const MUTATE_HIDE_POST = graphql(`
  mutation HidePost($id: Int, $hidden: Boolean) {
    post(id: $id, hidden: $hidden) {
      id
      hidden
      threadId
    }
  }
`);
