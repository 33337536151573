import React from 'react';

import Button from '../../components/button';
import { ButtonBar, Module } from '../../layouts';
import { ButtonGroup } from '../../layouts/button-group';
import { ModalComponentProps } from '../modal';

import ModalTitle from './modal-title';

type PostBannedProps = ModalComponentProps & {
  bannedUntil: string;
};

export default function PostBanned(props: PostBannedProps) {
  return (
    <div className="text-center">
      <ModalTitle>Comment failed</ModalTitle>
      <Module>
        <p>
          Sorry, you have been temporarily banned from posting on this website.
          You can post again {props.bannedUntil}.
        </p>
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-check-circle"
            onClick={props.hideModal}
            type="positive"
            rightAlign
          >
            Ok
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
}
