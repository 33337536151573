import React, { PureComponent } from 'react';
import { Main, Module } from 'layouts';

import Button from 'components/button';
import PageHeading from 'components/page-heading';
import ThemedPage from 'components/themed-page';

class VerifiedPage extends PureComponent {
  render() {
    return (
      <ThemedPage>
        <Main>
          <PageHeading>Email Verified</PageHeading>

          <Module>
            <div className="split-layout">
              <div className="split-layout--small">
                <img
                  src="/images/pirate.jpg"
                  alt=""
                  style={{ width: '100%' }}
                />
              </div>
              <div className="split-layout--large">
                <p className="text--large">
                  <strong>Email address successfully verified</strong>
                </p>
                <p>Thank you! You can now submit animations and comments.</p>
                <p>
                  Get started in <Button to="/my-studio">your studio</Button>
                </p>
              </div>
            </div>
          </Module>
        </Main>
      </ThemedPage>
    );
  }
}

export default VerifiedPage;
