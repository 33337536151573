import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '../../components/button';
import { ButtonBar, Module } from '../../layouts';
import { ButtonGroup } from '../../layouts/button-group';
import { hideModal } from '../../lib/modal/modal-actions';
import { ModalComponentProps } from '../modal';

import ModalTitle from './modal-title';

type ConfirmOverwriteAnimationModalProps = ModalComponentProps & {
  onClearAndLoadSelected: () => void;
  onCancelSelected: () => void;
};

const ConfirmOverwriteAnimationModal: FC<
  ConfirmOverwriteAnimationModalProps
> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onClearClicked = () => {
    props.onClearAndLoadSelected();
  };

  const onCancelClicked = () => {
    dispatch(hideModal());
    history.replace('/editor');
    props.onCancelSelected();
  };

  return (
    <div>
      <ModalTitle>Clear Current Animation</ModalTitle>
      <Module>
        <p>You have an unsaved animation in the editor.</p>
        <p>Do you want to clear the editor and load this animation?</p>
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-trash-alt"
            onClick={onClearClicked}
            type="negative"
          >
            Clear and load
          </Button>
          <Button
            icon="fas fa-times-circle"
            onClick={onCancelClicked}
            type="positive"
          >
            Keep current
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
};

export default ConfirmOverwriteAnimationModal;
