import React, { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { calculateThumbDimensions } from '../../../../lib/animation/canvas-utils';
import { RootState } from '../../../../store';
import { layerVisibilitySelector } from '../../selectors/layer-visibility-selector';

type TimelineFrameProps = {
  width: number;
  height: number;
  onFrameSelected: (id: string) => void;
  active: boolean;
  layers: RootState['animation']['sequence'][number]['layers'];
  id: string;
  showCounter: boolean;
  showCopyOverlay: boolean;
  index: number;
};

const TimelineFrame: FC<TimelineFrameProps> = (props) => {
  const { width, height } = calculateThumbDimensions(
    props.width,
    props.height,
    240,
    180
  );

  const layerVisibility = useSelector(layerVisibilitySelector);

  const onFrameSelected = () => {
    props.onFrameSelected(props.id);
  };

  const classes = classNames(
    'timeline__frame cursor-pointer rounded-md m-2 hover:border-indigo-500 bg-white shadow-md relative overflow-hidden flex-none',
    {
      'border-4 border-indigo-500 shadow-lg': props.active,
    },
    {
      'border-2 border-gray-400 shadow-sm': !props.active,
    }
  );

  return (
    <li
      className={classes}
      onClick={onFrameSelected}
      style={{ width: width / 2, height: height / 2 }}
    >
      <Fragment>
        {props.layers.map((layer, index) => {
          if (!layerVisibility[index]) return null;
          return (
            <img
              className="timeline__frame-canvas absolute object-cover w-full h-full"
              width={width}
              height={height}
              src={layer.image.getThumbData()}
              key={`layer-${index}`}
            />
          );
        })}
      </Fragment>
      {props.showCounter && (
        <span className="absolute bottom-0 right-0 bg-black text-white p-0.5 text-sm opacity-50">
          {props.index}
        </span>
      )}
      {props.showCopyOverlay && (
        <div className="timeline__frame--insert">
          <i className="fas fa-angle-double-down" />
        </div>
      )}
    </li>
  );
};

export default React.memo(TimelineFrame);
