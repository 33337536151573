export enum ERROR_MESSAGES {
  ERR_INVALID_CHARS = 'Username contains invalid characters',
  ERR_USERNAME_TOO_SHORT = 'Username is too short',
  ERR_USERNAME_TOO_LONG = 'Username is too long',
  ERR_INVALID_EMAIL = 'Email address is not valid',
  ERR_EMAIL_EXISTS = 'This email address is already registered',
  ERR_PASSWORD_TOO_SHORT = 'Password too short',
  ERR_USERNAME_EXISTS = 'Username is already in use',
  ERR_PASSWORDS_MATCH = 'Passwords do not match',
  ERR_REGISTRATION_FALURE = 'Sorry, something went wrong. You might need to try later',
}
