import React, { FC } from 'react';

type JsonLdProps = {
  data: Record<string, unknown>;
};

const JsonLd: FC<JsonLdProps> = (props) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(props.data).replace(/</g, '\\u003c'),
      }}
    />
  );
};

export default JsonLd;
