import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';

import { MUTATE_CREATE_ANIMATION_FOLDER } from '../../../../gql/queries/mutate-create-animation-folder';
import Button from '../../../components/button';
import { TextInput } from '../../../components/forms';
import UserError from '../../../components/user-error';
import { ButtonBar, Module } from '../../../layouts';
import { ButtonGroup } from '../../../layouts/button-group';
import { hideModal } from '../../../lib/modal/modal-actions';

const ERROR_MAP = {
  ERR_FOLDER_EXISTS: 'This folder name already exists',
  ERR_FOLDER_INVALID: 'This folder name is not valid',
  ERR_FOLDER_TOO_LONG: 'The folder name must be less than 30 characters',
};

const ManageFoldersCreate = () => {
  const inputRef = useRef<HTMLInputElement>();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState([]);
  const [createFolder] = useMutation(MUTATE_CREATE_ANIMATION_FOLDER, {
    onCompleted: () => {
      dispatch(hideModal());
    },
    onError: (e) => {
      if (Array.isArray(e?.graphQLErrors[0]?.extensions?.errorCodes)) {
        setErrors(
          e?.graphQLErrors[0]?.extensions?.errorCodes.map(
            (code) => ERROR_MAP[code]
          )
        );
      }
    },
    refetchQueries: ['GetMyStudio'],
  });

  const onCancelClicked = () => {
    dispatch(hideModal());
  };

  const onCreateClicked = () => {
    createFolder({
      variables: {
        name: inputRef.current.value,
      },
    });
  };

  return (
    <div>
      <Module>
        <p>
          Folder names are up to 30 characters long and contain only letters,
          numbers, dashes and underscores.
        </p>
      </Module>
      <Module>
        <UserError errors={errors} />
        <TextInput
          type="text"
          name="folder-name"
          label="Folder name:"
          ref={inputRef}
        />
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-times-circle"
            onClick={onCancelClicked}
            type="negative"
          >
            Cancel
          </Button>
          <Button
            icon="fas fa-folder-plus"
            onClick={onCreateClicked}
            type="positive"
          >
            Create folder
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
};

export default ManageFoldersCreate;
