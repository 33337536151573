import { graphql } from '../generated';

export const FOLLOWING_FEED = graphql(`
  query GetFollowingFeed($offset: Int, $limit: Int) {
    followingFeed(offset: $offset, limit: $limit) {
      animations {
        id
        url
        title
        version
        schemaVersion
        userId
        dailyAnimationCount
        commentCount
        dateUpdated
        encoded
        pixelMode
        type
        altThumbnail
        reactions {
          love
          laugh
          dizzy
          stars
        }
        user {
          id
          name
          theme {
            post
            username
          }
          avatarUrl
        }
      }
      count
    }
  }
`);
