import React from 'react';

import Button from '../../components/button';
import { ButtonBar, Module } from '../../layouts';
import { ButtonGroup } from '../../layouts/button-group';
import { ModalComponentProps } from '../modal';

import ModalTitle from './modal-title';

export default function SaveFailedModal(props: ModalComponentProps) {
  return (
    <div className="text-center">
      <ModalTitle>Save failed</ModalTitle>
      <Module>
        <p>
          Sorry, something went wrong and your animation was not saved. Please
          try again.
        </p>
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button onClick={props.hideModal} type="positive" rightAlign>
            <i className="fas fa-check-circle" /> Ok
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
}
