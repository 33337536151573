import React from 'react';
import { Main, Module } from 'layouts';

import PageHeading from 'components/page-heading';
import ThemedPage from 'components/themed-page';

import { OauthError } from './oauth-error';
import { SelectAccount } from './select-account';
import { UnmatchedAccount } from './unmatched-account';
import { useCheckLinkedAccounts } from './use-check-linked-accounts';

export const OauthSignInPage = () => {
  const { error, loading, linkedAccounts } = useCheckLinkedAccounts();

  return (
    <ThemedPage>
      <Main small>
        <PageHeading>Complete Sign-in</PageHeading>
        {loading && (
          <Module>
            <p>Loading...</p>
          </Module>
        )}
        {!loading && !error && linkedAccounts.length > 0 && (
          <SelectAccount linkedAccounts={linkedAccounts} />
        )}
        {!loading && !error && linkedAccounts.length === 0 && (
          <UnmatchedAccount />
        )}
        {error && <OauthError />}
      </Main>
    </ThemedPage>
  );
};
