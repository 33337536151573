import { graphql } from '../generated';

export const GET_FOLLOWS = graphql(`
  query GetFollows($userId: String) {
    follows(userId: $userId) {
      id
      isFollowing
      followers {
        ...CoreUserFields
      }
      following {
        ...CoreUserFields
      }
    }
  }
`);
