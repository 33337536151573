import React, { FC } from 'react';

import { Module } from '../../layouts';
import { RootState } from '../../store';

type UserErrorProps = {
  errors: RootState['errors'] | Record<string, string> | string[];
};

const UserError: FC<UserErrorProps> = ({ errors }) => {
  if (Object.keys(errors).length === 0) return null;
  return (
    <Module>
      <div className="error">
        {Object.keys(errors).map((errorKey) => (
          <p key={errorKey} className="error-item">
            <i className="fas fa-exclamation-triangle" /> {errors[errorKey]}
          </p>
        ))}
      </div>
    </Module>
  );
};

export default UserError;
