import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showModal } from '../../../lib/modal/modal-actions';
import editorActions from '../editor-actions';
import EditorButton from '../editor-button';
import { animationSelector } from '../selectors/animation-selector';
import { editorSelector } from '../selectors/editor-selector';

const LayerSwitcher = () => {
  const editorState = useSelector(editorSelector);
  const animationState = useSelector(animationSelector);
  const dispatch = useDispatch();

  const layerIsVisible =
    editorState.layerVisibility[editorState.currentLayerIndex];

  return (
    <div className="inline-block mx-4">
      <EditorButton
        onButtonPressed={() => dispatch(showModal('EditLayersModal'))}
        transient={true}
        label={<i className="fas fa-layer-group"></i>}
      />
      <EditorButton
        onButtonPressed={() => dispatch(editorActions.nextLayer())}
        transient={true}
        label={<i className="fas fa-caret-square-up"></i>}
        disabled={
          editorState.currentLayerIndex === animationState.layers.length - 1
        }
      />
      <EditorButton
        onButtonPressed={() => dispatch(editorActions.previousLayer())}
        transient={true}
        label={<i className="fas fa-caret-square-down"></i>}
        disabled={editorState.currentLayerIndex === 0}
      />
      <EditorButton
        onButtonPressed={() =>
          dispatch(
            editorActions.setLayerVisibility({
              layerIndex: editorState.currentLayerIndex,
              visible: !layerIsVisible,
            })
          )
        }
        transient={true}
        label={
          layerIsVisible ? (
            <i className="far fa-eye"></i>
          ) : (
            <i className="far fa-eye-slash"></i>
          )
        }
      />
      <span className="p-1 rounded-md bg-gray-100 shadow-sm">
        {animationState.layers[editorState.currentLayerIndex]}
      </span>
    </div>
  );
};

export default LayerSwitcher;
