import React, { MouseEvent, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Button from '../../components/button';
import { TextInput } from '../../components/forms';
import PageHeading from '../../components/page-heading';
import ThemedPage from '../../components/themed-page';
import UserError from '../../components/user-error';
import { ButtonBar, Main, Module } from '../../layouts';
import { ButtonGroup } from '../../layouts/button-group';
import {
  clearErrorsAction,
  setErrorAction,
} from '../../lib/error/error-actions';
import { errorSelector } from '../../lib/error/error-selector';
import { useAppDispatch } from '../../store';

import changePasswordActions from './change-password-actions';
import * as errorMessages from './constants';

const ChangePasswordForm = () => {
  const inputPasswordRef = useRef<HTMLInputElement>(null);
  const inputConfirmPasswordRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { resetId } = useParams();
  const errorState = useSelector(errorSelector);

  const onChangePasswordClicked = (e: MouseEvent) => {
    e.preventDefault();
    dispatch(clearErrorsAction());

    if (
      inputPasswordRef.current?.value !== inputConfirmPasswordRef.current?.value
    ) {
      return dispatch(
        setErrorAction('PASSWORD_CHANGE_FAILED', ['ERR_PASSWORDS_MATCH'])
      );
    }

    dispatch(
      changePasswordActions.changePassword(
        inputPasswordRef.current?.value,
        resetId,
        history
      )
    );
  };

  useEffect(() => {
    inputPasswordRef.current?.focus();

    return function cleanup() {
      dispatch(clearErrorsAction());
    };
  }, []);

  const errors =
    errorState.PASSWORD_CHANGE_FAILED?.map((e: string) => {
      return errorMessages[e];
    }) ?? [];

  return (
    <ThemedPage>
      <Main small>
        <PageHeading>Change password</PageHeading>
        <Module>
          <p>Please enter a new password.</p>
        </Module>
        <UserError errors={errors} />
        <form>
          <Module>
            <TextInput
              label="Password: (At least 4 characters)"
              id="password"
              type="password"
              ref={inputPasswordRef}
            />
          </Module>
          <Module>
            <TextInput
              label="Re-enter password:"
              id="password-confirm"
              type="password"
              ref={inputConfirmPasswordRef}
            />
          </Module>

          <ButtonBar>
            <ButtonGroup align="right">
              <Button
                icon="fas fa-key"
                className="button--positive"
                onClick={onChangePasswordClicked}
                rightAlign
                data-testid="change-password-button"
              >
                Change password
              </Button>
            </ButtonGroup>
          </ButtonBar>
        </form>
      </Main>
    </ThemedPage>
  );
};

export default ChangePasswordForm;
