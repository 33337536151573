import { graphql } from '../generated';

export const COMMUNITY_PAGE = graphql(`
  query GetCommunityPage {
    generalThreads: threads(types: ["NORMAL", "COMPETITION"], limit: 20) {
      id
      userId
      title
      type
      createdAt
      updatedAt
      locked
      pinned
      postCount
      endDate
      user {
        ...CoreUserFields
      }
    }
    animationThreads: threads(types: ["ANIMATION", "COMIC"], limit: 20) {
      id
      userId
      title
      type
      createdAt
      updatedAt
      locked
      pinned
      postCount
      animationUrl
      user {
        ...CoreUserFields
      }
    }
  }
`);
