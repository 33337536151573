import React from 'react';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';

import { GET_FOLLOWS } from '../../../gql/queries/follows';
import { MUTATE_FOLLOWING } from '../../../gql/queries/mutations/mutate-following';
import currentUserSelector from '../../lib/selectors/current-user-selector';
import Button from '../button';

const FollowButton = ({ userId }: { userId: string }) => {
  const [mutateFollow] = useMutation(MUTATE_FOLLOWING);
  const currentUser = useSelector(currentUserSelector);

  const { data } = useQuery(GET_FOLLOWS, { variables: { userId } });

  if (!currentUser.id) return null;
  if (currentUser.id === userId) return null;

  const onFollowPressed = (follow) => {
    mutateFollow({ variables: { userId, following: follow } });
  };

  if (data?.follows?.isFollowing) {
    return (
      <Button
        onClick={() => onFollowPressed(false)}
        type="highlight"
        className="mt-1"
      >
        Following <i className="fas fa-star" />
      </Button>
    );
  }

  return (
    <Button onClick={() => onFollowPressed(true)} className="mt-1">
      Not following <i className="far fa-star" />
    </Button>
  );
};

export default FollowButton;
