import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { MUTATE_DELETE_ANIMATION } from '../../../gql/queries/mutate-delete-animation';
import Button from '../../components/button';
import { ButtonBar, Module } from '../../layouts';
import { ButtonGroup } from '../../layouts/button-group';
import { hideModal } from '../../lib/modal/modal-actions';
import { ModalComponentProps } from '../modal';

import ModalTitle from './modal-title';

type PostReportModalProps = ModalComponentProps & {
  id: number;
};

const PostReportModal: FC<PostReportModalProps> = (props) => {
  const dispatch = useDispatch();
  const [isDeleting, setIsDeleting] = useState(false);
  const history = useHistory();
  const [deleteAnimation] = useMutation(MUTATE_DELETE_ANIMATION, {
    onCompleted: () => {
      dispatch(hideModal());
      history.push('/my-studio');
    },
  });

  const onDeleteClicked = () => {
    setIsDeleting(true);
    deleteAnimation({ variables: { id: props.id } });
  };

  const onCancelClicked = () => {
    dispatch(hideModal());
  };

  if (isDeleting) {
    return (
      <div className="text-center">
        <ModalTitle>Delete Animation</ModalTitle>
        <Module>
          <p>Deleting animation...</p>
        </Module>
        <div className="text-5xl text-gray-500">
          <i className="fas fa-spin fa-spinner" />
        </div>
      </div>
    );
  }

  return (
    <div>
      <ModalTitle>Delete Animation</ModalTitle>
      <Module>
        <p>Are you sure you want to delete this animation?</p>
        <p>
          This action is <strong>permanent</strong> and{' '}
          <strong>the animation cannot be retrieved</strong>.
        </p>
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-trash-alt"
            onClick={onDeleteClicked}
            type="negative"
          >
            Permanently delete
          </Button>
          <Button
            icon="fas fa-times-circle"
            onClick={onCancelClicked}
            type="positive"
          >
            Cancel and keep
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
};

export default PostReportModal;
