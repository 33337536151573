import React from 'react';
import PropTypes from 'prop-types';

import { reactionsMap } from '../reaction-selector/constants';

const Reaction = function ({ type, ...otherProps }) {
  return <i className={reactionsMap[type]} {...otherProps} />;
};

Reaction.propTypes = {
  type: PropTypes.string,
};

export default Reaction;
