import { setErrorAction } from '../../lib/error/error-actions';
import { post } from '../../lib/services/fetch';
import { userLoaded } from '../../lib/user/user-actions';

const register = function (username, password, email, history) {
  const clientId = window.localStorage.getItem('clientId');
  return function (dispatch) {
    return post('/api/register', { username, password, email, clientId })
      .then(function (response) {
        history.push('/my-studio');
        return dispatch(userLoaded(response.user));
      })
      .catch(function (e) {
        return dispatch(registrationFailed(e));
      });
  };
};

const registrationFailed = function (error) {
  return setErrorAction(
    'REGISTRATION_FAILED',
    error.response.payload || ['ERR_UNKNOWN']
  );
};

export default {
  register,
};
