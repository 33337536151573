import React from 'react';
import { useSelector } from 'react-redux';

import { savedLocallySelector } from '../selectors/saved-locally-selector';

const LocalSaveStatus = () => {
  const savedLocally = useSelector(savedLocallySelector);

  if (savedLocally === 'queued') {
    return (
      <span className="text-gray-500 mx-4">
        <i className="fas fa-stopwatch"></i> Waiting...
      </span>
    );
  } else if (savedLocally === 'pending') {
    return (
      <span className="text-gray-500 mx-4">
        <i className="fas fa-sync"></i> Saving locally
      </span>
    );
  } else if (savedLocally === 'unsupported') {
    return (
      <span className="text-red-500 mx-4">
        <i className="fas fa-ban"></i> Cannot save
      </span>
    );
  } else {
    return (
      <span className="text-gray-500 mx-4">
        <i className="fas fa-check-circle"></i> Saved locally
      </span>
    );
  }
};

export default LocalSaveStatus;
