import { AppDispatch, store } from '../../../../store';
import editorActions from '../../editor-actions';

import {
  GenericTool,
  GenericToolConstructorArgs,
  ToolEventListenerArgs,
} from './generic';

export class EyedropperTool extends GenericTool {
  dispatch: AppDispatch;
  active = false;

  constructor(args: GenericToolConstructorArgs) {
    super(args);
    this.dispatch = store.dispatch;
  }

  onStart({ coords }: ToolEventListenerArgs) {
    const [x, y] = coords;
    this.active = true;
    this.reset();

    const pixel = this.visibleCtx.getImageData(x, y, 1, 1).data;
    this.dispatch(editorActions.setColourFromEyeDropper(pixel));
  }

  onMove({ coords }: ToolEventListenerArgs) {
    if (!this.active) return;
    const [x, y] = coords;

    const pixel = this.visibleCtx.getImageData(x, y, 1, 1).data;
    this.dispatch(editorActions.setColourFromEyeDropper(pixel));
  }

  onTap({ coords }) {
    const [x, y] = coords;

    const pixel = this.visibleCtx.getImageData(x, y, 1, 1).data;
    this.dispatch(editorActions.setColourFromEyeDropper(pixel));
  }

  onEnd() {
    this.active = false;
  }
}
