import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

import Button from '../../components/button';

export const ShareButton = ({ animation }) => {
  const [lazyRender, setLazyRender] = useState(false);
  const canShare = typeof navigator !== 'undefined' && !!navigator.share;

  useEffect(() => {
    setLazyRender(true);
  }, []);

  if (!canShare || !lazyRender) return null;

  const onShareClicked = () => {
    navigator
      .share({
        title: `${animation.title} - by ${animation.userId}`,
        url: `https://theanimator.co.uk/animation/${animation.url}`,
      })
      .catch(() => {
        // ignore
      });
  };

  return (
    <Button icon="fas fa-share-alt" onClick={onShareClicked} type="positive">
      Share
    </Button>
  );
};

ShareButton.propTypes = {
  animation: PropTypes.shape({
    title: PropTypes.string,
    userId: PropTypes.string,
    url: PropTypes.string,
  }),
};
