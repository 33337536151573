import React from 'react';

import Button from '../../components/button';
import { ProgressBar } from '../../components/progress-bar/progress-bar';
import { ButtonBar, Module } from '../../layouts';
import { ButtonGroup } from '../../layouts/button-group';
import { ModalComponentProps } from '../modal';

import ModalTitle from './modal-title';

type SavingModalProps = ModalComponentProps & {
  progress: number;
  message: string;
  version: string;
  onCancel: () => void;
};

export default function SavingModal({
  progress = null,
  message,
  version = '',
  onCancel,
}: SavingModalProps) {
  return (
    <div className="text-center">
      <ModalTitle>Saving</ModalTitle>
      <Module>
        <p>{message}</p>
        <p className="text-xs">{version ? `Version: ${version}` : '-'}</p>
      </Module>
      <div className="text-5xl text-gray-500">
        <i className="fas fa-spin fa-spinner" />
      </div>
      <Module>
        {progress !== null && <ProgressBar progressPercent={progress} />}
      </Module>
      <ButtonBar>
        <ButtonGroup align="centre">
          <Button onClick={onCancel} type="negative" centre>
            <i className="fa fa-ban" /> Cancel
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
}
