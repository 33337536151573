export async function asyncRetry(fn, numRetries) {
  let error;
  for (let attempt = 0; attempt < numRetries - 1; attempt++) {
    try {
      return await fn();
    } catch (e) {
      error = e;
    }
  }
  throw error;
}
