import React, { FC, MouseEvent, useState } from 'react';

import { Animation } from '../../../gql/generated/graphql';
import config from '../../../lib/config';

type VideoProps = {
  animation: Animation;
};

export const Video: FC<VideoProps> = ({ animation }) => {
  const [errorLoading, setErrorLoading] = useState(false);

  const onAnimationLoadError = () => {
    const hasVideo =
      !!document.createElement('video').canPlayType &&
      !!document.createElement('video').canPlayType('video/mp4');

    if (!hasVideo) return;
    if (animation.encoded) return; // no need to request encode

    setErrorLoading(true);
  };

  if (!animation.url) return null;

  const posterPath =
    animation.schemaVersion > 1
      ? `${config.s3Path}/${animation.url}/poster.png?v=${animation.version}`
      : `${config.s3Path}/${animation.url}/0.png?v=${animation.version}`;

  if (errorLoading) {
    return (
      <div className="animation-page__error-container">
        <img className="animation-page__error-background" src={posterPath} />
        <p className="animation-page__error">
          This animation is in the queue to be encoded.{' '}
          <strong>Please check back in five minutes</strong>.
        </p>
      </div>
    );
  }

  if (!animation.encoded) {
    return (
      <div
        className="text-center bg-white flex flex-col justify-center"
        style={{ minHeight: 300 }}
      >
        <div>
          <i className="fas fa-cog fa-spin text-7xl text-gray-400"></i>
        </div>
        <p className="text-center m-4 ">Encoding...</p>
      </div>
    );
  }

  return (
    <video
      loop
      autoPlay
      playsInline
      muted
      key={animation.id}
      poster={posterPath}
      onError={onAnimationLoadError}
      onClick={(e: MouseEvent<HTMLVideoElement>) => {
        const el = e.currentTarget;
        if (el.paused) {
          el.play();
        } else {
          el.pause();
        }
      }}
    >
      <source
        src={`${config.s3Path}/${animation.url}/animation.mp4?v=${animation.version}`}
        type="video/mp4"
      />
    </video>
  );
};
