import React, { useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { MUTATE_POST } from '../../../gql/queries/mutate-post';
import { POST } from '../../../gql/queries/post';
import Button from '../../components/button';
import { Textarea } from '../../components/forms';
import UserError from '../../components/user-error';
import { ButtonBar, Module } from '../../layouts';
import { ButtonGroup } from '../../layouts/button-group';
import { ModalComponentProps } from '../modal';

import ModalTitle from './modal-title';

type EditPostModalProps = ModalComponentProps & {
  postId: number;
};

const EditPost = (props: EditPostModalProps) => {
  const textInputRef = useRef<HTMLTextAreaElement>();
  const [error, setError] = useState(false);

  const [mutatePost] = useMutation(MUTATE_POST);

  const { data } = useQuery(POST, { variables: { postId: props.postId } });

  const onCancelClicked = () => {
    props.hideModal();
  };

  const onPostClicked = async () => {
    if (!textInputRef.current.value.trim()) {
      setError(true);
      return;
    }

    await mutatePost({
      variables: {
        id: data.post.id,
        raw: textInputRef.current.value,
      },
    });
    props.hideModal();
  };

  return (
    <div>
      <ModalTitle>Edit Post</ModalTitle>
      {error && <UserError errors={{ empty: 'Please enter some post text' }} />}
      <Module>
        <Textarea
          ref={textInputRef}
          label="Edit your post text"
          id="thread-post-textarea"
          maxLength="2000"
          defaultValue={data?.post?.raw}
        />
      </Module>

      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-times-circle"
            onClick={onCancelClicked}
            type="negative"
          >
            Cancel
          </Button>
          <Button icon="fas fa-edit" onClick={onPostClicked} type="positive">
            Save
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
};

export default EditPost;
