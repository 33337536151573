import React, { FC, Fragment } from 'react';
import dayjs from 'dayjs';

import { User } from '../../../gql/generated/graphql';
import Avatar from '../avatar/avatar';

type PostUserProps = {
  user: User;
};

export const PostUser: FC<PostUserProps> = ({ user }) => {
  let nameSuffix;

  if (user.roles.includes('admin')) {
    nameSuffix = <i className="fas fa-star" />;
  } else if (user.roles.includes('bot')) {
    nameSuffix = <i className="fas fa-robot" />;
  } else if (user.badgeExpires && dayjs(user.badgeExpires).isAfter(dayjs())) {
    if (user.badge === 'WINNER') {
      nameSuffix = <i className="fas fa-trophy" />;
    } else if (user.badge === 'MEDAL') {
      nameSuffix = <i className="fas fa-medal" />;
    }
  }

  return (
    <Fragment>
      <Avatar user={user} className="rounded-tl-md" />{' '}
      <span className="group-hover:underline">{user.name}</span>
      {nameSuffix ? <Fragment> {nameSuffix}</Fragment> : null}
    </Fragment>
  );
};
