import { setErrorAction } from '../../lib/error/error-actions';
import { post } from '../../lib/services/fetch';
import { userLoaded } from '../../lib/user/user-actions';

export const signIn = function (username, password) {
  const clientId = window.localStorage.getItem('clientId');
  return function (dispatch) {
    return post('/api/sign-in', { username, password, clientId })
      .then(function (response) {
        dispatch(userLoaded(response.user));
      })
      .catch(function (error) {
        if (error.status === 403 && error.response.status === 'user_banned') {
          return dispatch(
            setErrorAction('SIGN_IN_FAILED', 'This account has been banned.')
          );
        }

        return dispatch(
          setErrorAction(
            'SIGN_IN_FAILED',
            'Failed to sign-in. Please check your username and password.'
          )
        );
      });
  };
};
