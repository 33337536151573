import React, { ChangeEvent, FC, MouseEvent } from 'react';

type DelayProps = {
  onChange: ({ delay, applyAll }: { delay: string; applyAll: boolean }) => void;
  value: number;
  onClick: (event: MouseEvent) => void;
};

const Delay: FC<DelayProps> = (props) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.onChange({ delay: e.currentTarget.value, applyAll: false });
  };

  return (
    <div className="inline-block">
      <i className="fas fa-stopwatch" />{' '}
      <input
        className="rounded-md w-12 shadow-inner p-1 border border-gray-400"
        value={props.value}
        onChange={onChange}
        onClick={props.onClick}
        maxLength={5}
      />{' '}
      ms
    </div>
  );
};

export default Delay;
