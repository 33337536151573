import { ActionCreator } from '@reduxjs/toolkit';

type ErrorAction = {
  type: string;
  payload: { key: string; value: string[] };
};

export const setErrorAction: ActionCreator<ErrorAction> = function (
  key: string,
  value: string[]
) {
  return {
    type: 'ERROR/SET',
    payload: {
      key,
      value,
    },
  };
};

export const clearErrorsAction = function () {
  return {
    type: 'ERROR/CLEAR',
    payload: {},
  };
};
