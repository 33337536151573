export const timelineSelector = function (state) {
  return {
    currentFrameIndex: state.editor.currentFrameIndex,
    currentLayerIndex: state.editor.currentLayerIndex,
    sequence:
      state.animation.sequence.length > 0
        ? state.animation.sequence
        : ['empty'],
    frameDimensions: {
      width: state.animation.width,
      height: state.animation.height,
    },
    mode: state.editor.mode,
    windowWidth: state.editor.windowWidth,
    windowHeight: state.editor.windowHeight,
    framePickerVisible: state.editor.framePickerVisible,
    images: state.animation.images,
    framePickerMode: state.editor.framePickerMode,
  };
};
