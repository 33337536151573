import React from 'react';

import {
  Comic,
  ComicFrame as TComicFrame,
} from '../../../gql/generated/graphql';
import { AnimationContent, IfUserIs } from '../../components';

import ComicFrameControls from './comic-frame-controls';

const colspanClassMap = {
  1: 'md:col-span-1',
  2: 'md:col-span-2',
  3: 'md:col-span-3',
  4: 'md:col-span-4',
};

const rowspanClassMap = {
  1: 'md:row-span-1',
  2: 'md:row-span-2',
  3: 'md:row-span-3',
  4: 'md:row-span-4',
};

const ComicFrame = ({
  frame,
  numFrames,
  comic,
  editMode,
}: {
  frame: TComicFrame;
  numFrames: number;
  comic: Comic;
  editMode: boolean;
}) => {
  return (
    <div
      className={`border-2 border-black shadow-md relative ${
        colspanClassMap[frame.colSpan]
      } ${rowspanClassMap[frame.rowSpan]}`}
    >
      <AnimationContent animation={frame.animation} />
      {editMode && (
        <IfUserIs username={comic.user.id}>
          <ComicFrameControls frame={frame} numFrames={numFrames} />
        </IfUserIs>
      )}
    </div>
  );
};

export default ComicFrame;
