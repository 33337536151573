export const post = function (url, data, options = {}) {
  return fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
    signal: options.abortSignal,
  }).then((response) => {
    return response.json().then(function (decoded) {
      if (!response.ok) {
        const error = new Error('POST request to ' + url + ' failed');
        error.response = decoded;
        error.status = response.status;
        throw error;
      }
      return decoded;
    });
  });
};

export const postRaw = function (url, data) {
  return fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/octet-stream' },
    body: data,
  }).then((response) => {
    return response.json().then(function (decoded) {
      if (!response.ok) {
        const error = new Error('POST request to ' + url + ' failed');
        error.response = decoded;
        error.status = response.status;
        throw error;
      }
      return decoded;
    });
  });
};

export const put = function (url, data) {
  return fetch(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json().then(function (decoded) {
      if (!response.ok) {
        const error = new Error('PUT request to ' + url + ' failed');
        error.response = decoded;
        error.status = response.status;
        throw error;
      }
      return decoded;
    });
  });
};

export const httpDelete = function (url, data) {
  return fetch(url, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }).then((response) => {
    if (!response.ok) {
      throw new Error('DELETE request to ' + url + ' failed');
    }
    return response.json();
  });
};

export const get = function (url) {
  return fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }).then((response) => {
    if (!response.ok) {
      throw new Error('GET request to ' + url + ' failed');
    }
    return response.json();
  });
};
