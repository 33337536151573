import { graphql } from '../generated';

export const MY_STUDIO = graphql(`
  query GetMyStudio(
    $offset: Int
    $limit: Int
    $folderId: String
    $types: [AnimationType]
  ) {
    privateAnimations(
      offset: $offset
      limit: $limit
      folderId: $folderId
      types: $types
    ) {
      id
      url
      title
      version
      schemaVersion
      userId
      dailyAnimationCount
      dateUpdated
      encoded
      pixelMode
      type
      altThumbnail
      reactions {
        love
        laugh
        dizzy
        stars
      }
      commentCount
      public
    }
    privateAnimationsCount(folderId: $folderId)
    animationFolders {
      id
      name
    }
  }
`);
