import React, { FC, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';

import { Animation, Thread } from '../../../gql/generated/graphql';
import { MUTATE_CREATE_POST } from '../../../gql/queries/mutate-create-post';
import { ButtonBar, Module } from '../../layouts';
import { ButtonGroup } from '../../layouts/button-group';
import { showModal } from '../../lib/modal/modal-actions';
import Button from '../button';
import { Textarea } from '../forms';
import IfUserIs from '../if-user-is';

type PostEditorProps = {
  animation: Animation;
  thread: Thread;
  type: Thread['type'];
};

const PostEditor: FC<PostEditorProps> = (props) => {
  const textareaRef = useRef<HTMLTextAreaElement>();
  const dispatch = useDispatch();
  const [mutateCreatePost] = useMutation(MUTATE_CREATE_POST, {
    onCompleted: () => {
      textareaRef.current.value = '';
    },
    onError: (e) => {
      const { code } = e.graphQLErrors[0].extensions;

      switch (code) {
        case 'POST_RATE_LIMITED':
          dispatch(showModal('PostRateLimited'));
          break;

        case 'POST_BANNED':
          dispatch(
            showModal('PostBannedModal', {
              bannedUntil: dayjs().to(
                dayjs(e.graphQLErrors[0].extensions.expiry)
              ),
            })
          );
          break;

        case 'USER_BANNED':
          dispatch(showModal('UserBannedModal'));
          break;

        case 'USER_UNVERIFIED':
          dispatch(showModal('UserUnverifiedModal'));
          break;
      }
    },
    update(cache, { data: { createPost } }) {
      if (props.animation) {
        cache.modify({
          id: cache.identify(props.animation),
          fields: {
            thread() {
              return createPost;
            },
          },
        });
      }
    },
  });

  const onPostButtonClicked = (e) => {
    e.preventDefault();
    if (textareaRef.current.value.trim().length === 0) return;

    mutateCreatePost({
      variables: {
        text: textareaRef.current.value,
        threadId: props.thread.id,
        animationId: props.animation && props.animation.id,
        type: props.type,
        lock: e.currentTarget.dataset['lock'] === 'true',
      },
    });
  };

  const getPostButton = () => {
    return (
      <ButtonGroup>
        <Button
          icon="far fa-paper-plane"
          onClick={onPostButtonClicked}
          type="positive"
        >
          Post comment
        </Button>
        <IfUserIs admin>
          <Button
            icon="fas fa-lock"
            data-lock="true"
            type="negative"
            onClick={onPostButtonClicked}
          >
            Post and lock
          </Button>
        </IfUserIs>
      </ButtonGroup>
    );
  };

  return (
    <div>
      <IfUserIs signedIn>
        <Module>
          <p className="my-4">
            Post a comment using the text box below. Be nice - no bad language,
            and don&apos;t spam the site. Do not post any personal information
            or any video chat links. Inappropriate posts will be removed and
            your account may be banned. The author of this page can hide any
            post in this thread.
          </p>
          <Textarea
            id="postEditor"
            className="max-w-sm"
            ref={textareaRef}
            placeholder="Add a comment"
            maxLength="2000"
          />
        </Module>
        <ButtonBar>{getPostButton()}</ButtonBar>
      </IfUserIs>
      <IfUserIs not signedIn>
        <Module>
          <p>Please sign in to post a comment</p>
        </Module>
      </IfUserIs>
    </div>
  );
};

export default PostEditor;
