import { graphql } from '../generated';

export const HOMEPAGE_GALLERY = graphql(`
  query GetHomepage($offset: Int, $limit: Int) {
    dailyAnimations(offset: $offset, limit: $limit) {
      id
      url
      title
      version
      schemaVersion
      userId
      dailyAnimationCount
      dateUpdated
      encoded
      pixelMode
      type
      altThumbnail
      reactions {
        love
        laugh
        dizzy
        stars
      }
      commentCount
      user {
        id
        name
        theme {
          post
        }
      }
    }
    publicAnimationsCount
  }
`);
