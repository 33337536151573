export type ToolEventListenerArgs = {
  coords: [number, number];
  delta?: [number, number];
};

export type GenericToolConstructorArgs = {
  visibleCtx: CanvasRenderingContext2D;
  onCommit: (canvas: HTMLCanvasElement) => void;
  undoStackPush: (canvas: HTMLCanvasElement) => void;
};

export abstract class GenericTool {
  visibleCtx: CanvasRenderingContext2D;
  onCommit: GenericToolConstructorArgs['onCommit'];
  _undoStackPush: GenericToolConstructorArgs['undoStackPush'];
  opacity = 1;
  originalCanvas: HTMLCanvasElement;
  originalCtx: CanvasRenderingContext2D;
  offscreenCtx: CanvasRenderingContext2D;
  isActive = false;

  constructor({
    visibleCtx,
    onCommit,
    undoStackPush,
  }: GenericToolConstructorArgs) {
    this.visibleCtx = visibleCtx;
    this.onCommit = onCommit;
    this._undoStackPush = undoStackPush;

    const canvas = document.createElement('canvas');
    canvas.width = visibleCtx.canvas.width;
    canvas.height = visibleCtx.canvas.height;

    this.originalCanvas = document.createElement('canvas');
    this.originalCtx = this.originalCanvas.getContext('2d');
    this.originalCanvas.width = visibleCtx.canvas.width;
    this.originalCanvas.height = visibleCtx.canvas.height;

    this.offscreenCtx = canvas.getContext('2d');

    this.isActive = false;
  }
  /**
   * Paint the offscreen canvas over the visible
   */
  paintToVisible() {
    this.visibleCtx.globalAlpha = 1;
    this.visibleCtx.clearRect(
      0,
      0,
      this.visibleCtx.canvas.width,
      this.visibleCtx.canvas.height
    );
    // this.visibleCtx.putImageData(this.originalImageData, 0, 0);
    this.visibleCtx.drawImage(this.originalCanvas, 0, 0);
    this.visibleCtx.globalAlpha = this.opacity;
    this.visibleCtx.drawImage(this.offscreenCtx.canvas, 0, 0);
    this.visibleCtx.globalAlpha = 1;
  }

  undoStackPush() {
    this._undoStackPush(this.visibleCtx.canvas);
  }

  /**
   * NOT USED? Revert the visible canvas back to its original state since the last reset()
   */
  // restore() {
  //   this.visibleCtx.putImageData(this.originalImageData, 0, 0);
  // }

  /**
   * Take a copy of the visible canvas and clear the offscreen canvas
   */
  reset() {
    const visibleCanvas = this.visibleCtx.canvas;
    this.originalCtx.clearRect(
      0,
      0,
      this.originalCanvas.width,
      this.originalCanvas.height
    );
    this.originalCtx.drawImage(visibleCanvas, 0, 0);
    this.offscreenCtx.clearRect(
      0,
      0,
      visibleCanvas.width,
      visibleCanvas.height
    );
  }

  /**
   * Merge the offscreen canvas with the visible canvas, then run a callback to store the result
   */
  commitMerged() {
    // this.visibleCtx.putImageData(this.originalImageData, 0, 0);
    this.visibleCtx.globalAlpha = 1;
    this.visibleCtx.clearRect(
      0,
      0,
      this.originalCanvas.width,
      this.originalCanvas.height
    );
    this.visibleCtx.drawImage(this.originalCanvas, 0, 0);
    this.visibleCtx.globalAlpha = this.opacity;
    this.visibleCtx.drawImage(this.offscreenCtx.canvas, 0, 0);
    this.visibleCtx.globalAlpha = 1;
    this.onCommit(this.visibleCtx.canvas);
  }

  /**
   * Commit the visible canvas to be stored
   */
  commitVisible() {
    this.onCommit(this.visibleCtx.canvas);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onStart(_opts?: ToolEventListenerArgs): void {
    return undefined;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onMove(_opts?: ToolEventListenerArgs): void {
    return undefined;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onTap(_opts?: ToolEventListenerArgs): void {
    return undefined;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onEnd(): void {
    return undefined;
  }
}
