import React, { PureComponent } from 'react';
import { Main, Module } from 'layouts';

import PageHeading from 'components/page-heading';
import ThemedPage from 'components/themed-page';

export default class PasswordResetSent extends PureComponent {
  render() {
    return (
      <ThemedPage>
        <Main small>
          <PageHeading>Password reset email sent</PageHeading>
          <Module>
            <p>
              If you entered a registered email address, an email has been sent
              to you.
            </p>
            <p>Use the link in the email to reset your password.</p>
          </Module>
        </Main>
      </ThemedPage>
    );
  }
}
