import React from 'react';
import { useDispatch } from 'react-redux';
import { gql, useMutation, useQuery } from '@apollo/client';

import { GET_FOLDER_QUERY } from '../../../../gql/queries/animation-folder';
import { MUTATE_DELETE_ANIMATION_FOLDER } from '../../../../gql/queries/mutate-delete-animation-folder';
import Button from '../../../components/button';
import { ButtonBar, Module } from '../../../layouts';
import { ButtonGroup } from '../../../layouts/button-group';
import { hideModal } from '../../../lib/modal/modal-actions';

const ManageFoldersDelete = () => {
  const dispatch = useDispatch();
  const { data, loading, error } = useQuery(GET_FOLDER_QUERY);

  const [deleteFolder] = useMutation(MUTATE_DELETE_ANIMATION_FOLDER, {
    onCompleted: () => {
      dispatch(hideModal());
    },
    onError: () => {
      // TODO: handle error
    },
    refetchQueries: ['GetMyStudio'],
  });

  const onCancelClicked = () => {
    dispatch(hideModal());
  };

  const renderFolders = () => {
    return data.animationFolders.map((folder) => {
      return (
        <Button
          className="m-1"
          key={folder.id}
          icon="fas fa-folder-minus"
          type="negative"
          onClick={() => {
            deleteFolder({
              variables: {
                id: folder.id,
              },
            });
          }}
        >
          {folder.name}
        </Button>
      );
    });
  };

  return (
    <div>
      <Module>
        <p>
          Choose a folder to delete. Animations will be moved out and will not
          be deleted.
        </p>
      </Module>
      <Module>{!loading && !error && renderFolders()}</Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-times-circle"
            onClick={onCancelClicked}
            type="negative"
          >
            Cancel
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
};

export default ManageFoldersDelete;
