import interact from 'interactjs';

import { dataUrlToImg } from '../../../../lib/animation/canvas-utils';
import { EditorTool } from '../../editor-reducer';
import { CircleTool } from '../tools/circle';
import { EraserTool } from '../tools/eraser';
import { EyedropperTool } from '../tools/eyedropper';
import { FillTool } from '../tools/fill';
import { LineTool } from '../tools/line';
import { MoveLayerTool } from '../tools/move-layer';
import { PaintTool } from '../tools/paint';
import { PencilTool } from '../tools/pencil';
import { RectangleTool } from '../tools/rectangle';

const PANE_PADDING_PX = 40;

const TOOL_CLASS_MAP = {
  pencil: PencilTool,
  fill: FillTool,
  erase: EraserTool,
  paint: PaintTool,
  eyedropper: EyedropperTool,
  moveLayer: MoveLayerTool,
  line: LineTool,
  circle: CircleTool,
  rectangle: RectangleTool,
} as const;

type OnCanvasChangeCallback = (canvas: HTMLCanvasElement) => void;
type OnUndoStackPushCallback = () => void;

type InteractiveCanvasPaneArgs = {
  canvasPaneElement: HTMLDivElement;
  currentTool: EditorTool;
  onUndoStackPush: OnUndoStackPushCallback;
  onCanvasChanged: OnCanvasChangeCallback;
  onInteractionStarted: () => void;
};

export class InteractiveCanvasPane {
  canvasElement: HTMLCanvasElement;
  canvasPaneElement: InteractiveCanvasPaneArgs['canvasPaneElement'] = null;
  canvasPaneScrollAreaElement: HTMLDivElement;
  canvasContainerElement: HTMLDivElement;
  canvasScale = 1;
  canvasTranslation = [0, 0];
  tool: InstanceType<typeof TOOL_CLASS_MAP[EditorTool]>;
  onUndoStackPush: OnUndoStackPushCallback;
  onCanvasChanged: OnCanvasChangeCallback;
  onInteractionStarted: () => void;

  setTool(tool: EditorTool) {
    this.tool = new TOOL_CLASS_MAP[tool]({
      visibleCtx: this.canvasElement.getContext('2d'),
      onCommit: this.onCanvasChanged,
      undoStackPush: this.onUndoStackPush,
    });
  }

  async updateImage(imageData: string) {
    const image = await dataUrlToImg(imageData);
    const ctx = this.canvasElement.getContext('2d');
    ctx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
    ctx.drawImage(image, 0, 0);
  }

  normaliseCanvasCoords(
    event: Interact.DragEvent | Interact.GestureEvent | MouseEvent
  ): [number, number] {
    const clientRect = this.canvasElement.getBoundingClientRect();
    const normalisedX = Math.floor(
      ((event.clientX + window.scrollX) / clientRect.width) *
        this.canvasElement.width
    );
    const normalisedY = Math.floor(
      ((event.clientY + window.scrollY) / clientRect.height) *
        this.canvasElement.height
    );
    return [normalisedX, normalisedY];
  }

  zoomToFit() {
    const paneBoundingRect = this.canvasPaneElement.getBoundingClientRect();
    const { width, height } = this.canvasElement;

    const xScale = (paneBoundingRect.width - PANE_PADDING_PX) / width;
    const yScale = (paneBoundingRect.height - PANE_PADDING_PX) / height;
    let scaleFactor = 0;

    if (xScale > yScale) {
      scaleFactor = yScale;
    } else {
      scaleFactor = xScale;
    }

    this.canvasScale = scaleFactor;
    this.canvasTranslation = [0, 0];
    this.canvasContainerElement.style.transform = `scale(${scaleFactor}) translate(0px, 0px)`;
    this.resizeContainer();
  }

  zoomIn() {
    const currentBoundingBox =
      this.canvasPaneScrollAreaElement.getBoundingClientRect();

    this.canvasScale *= 1.2;
    this.canvasContainerElement.style.transform = `scale(${this.canvasScale}) translate(${this.canvasTranslation[0]}px, ${this.canvasTranslation[1]}px)`;

    this.resizeContainer();

    const resizedBoundingBox =
      this.canvasPaneScrollAreaElement.getBoundingClientRect();

    this.scrollToCentre(currentBoundingBox, resizedBoundingBox);
  }

  zoomOut() {
    const currentBoundingBox =
      this.canvasPaneScrollAreaElement.getBoundingClientRect();

    this.canvasScale *= 0.8;
    if (this.canvasScale < 0.5) this.canvasScale = 0.5;
    this.canvasContainerElement.style.transform = `scale(${this.canvasScale}) translate(${this.canvasTranslation[0]}px, ${this.canvasTranslation[1]}px)`;
    this.resizeContainer();

    const resizedBoundingBox =
      this.canvasPaneScrollAreaElement.getBoundingClientRect();

    this.scrollToCentre(currentBoundingBox, resizedBoundingBox);
  }

  scrollToCentre(oldBoundingBox: DOMRect, newBoundingBox: DOMRect) {
    const viewportBoundingBox = this.canvasPaneElement.getBoundingClientRect();
    const viewportCenterXPercent =
      (this.canvasPaneElement.scrollLeft + viewportBoundingBox.width / 2) /
      oldBoundingBox.width;

    const viewportCenterYPercent =
      (this.canvasPaneElement.scrollTop + viewportBoundingBox.height / 2) /
      oldBoundingBox.height;

    this.canvasPaneElement.scrollTo(
      newBoundingBox.width * viewportCenterXPercent -
        viewportBoundingBox.width / 2,
      newBoundingBox.height * viewportCenterYPercent -
        viewportBoundingBox.height / 2
    );
  }

  resizeContainer() {
    const { width, height } =
      this.canvasContainerElement.getBoundingClientRect();

    this.canvasPaneScrollAreaElement.style.width = `${
      width + PANE_PADDING_PX
    }px`;
    this.canvasPaneScrollAreaElement.style.height = `${
      height + PANE_PADDING_PX
    }px`;
  }

  normaliseCanvasDelta(
    event: Interact.DragEvent | Interact.GestureEvent
  ): [number, number] {
    return [
      Math.round((event.clientX - event.clientX0) / this.canvasScale),
      Math.round((event.clientY - event.clientY0) / this.canvasScale),
    ];
  }

  onDragStart = (event: Interact.DragEvent) => {
    event.stopPropagation();
    event.preventDefault();
    this.onInteractionStarted();
    this.tool.onStart({
      coords: this.normaliseCanvasCoords(event),
      delta: [0, 0],
    });
  };

  onDragMove = (event: Interact.DragEvent) => {
    event.stopPropagation();
    event.preventDefault();
    this.tool.onMove({
      coords: this.normaliseCanvasCoords(event),
      delta: this.normaliseCanvasDelta(event),
    });
  };

  onTap = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    this.tool.onTap({
      coords: this.normaliseCanvasCoords(event),
    });
  };

  onDragEnd = (event) => {
    event.stopPropagation();
    event.preventDefault();
    this.tool.onEnd();
  };

  onGestureMove = (event: Interact.GestureEvent) => {
    const canvasScale = event.scale * this.canvasScale;

    this.canvasTranslation = [
      this.canvasTranslation[0] + event.dx / canvasScale,
      this.canvasTranslation[1] + event.dy / canvasScale,
    ];

    this.canvasContainerElement.style.transform = `scale(${canvasScale}) translate(${this.canvasTranslation[0]}px, ${this.canvasTranslation[1]}px)`;
  };

  onGestureEnd = (event: Interact.GestureEvent) => {
    this.canvasScale = this.canvasScale * event.scale;
    this.canvasTranslation = [
      this.canvasTranslation[0] + event.dx / this.canvasScale,
      this.canvasTranslation[1] + event.dy / this.canvasScale,
    ];

    this.canvasContainerElement.style.transform = `scale(${this.canvasScale}) translate(${this.canvasTranslation[0]}px, ${this.canvasTranslation[1]}px)`;
  };

  nullEvent = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
  };

  bindEventListeners({
    canvasPaneElement,
    currentTool = 'pencil',
    onUndoStackPush,
    onCanvasChanged,
    onInteractionStarted,
  }: InteractiveCanvasPaneArgs) {
    this.canvasPaneElement = canvasPaneElement;
    this.canvasPaneScrollAreaElement = canvasPaneElement.querySelector(
      '.editor-canvas-pane-scroll-area'
    );
    this.canvasContainerElement = canvasPaneElement.querySelector(
      '.editor-canvas-container'
    );
    this.canvasElement = canvasPaneElement.querySelector('canvas');
    this.onUndoStackPush = onUndoStackPush;
    this.onCanvasChanged = onCanvasChanged;
    this.onInteractionStarted = onInteractionStarted;

    this.setTool(currentTool);

    // Prevent Safari events
    this.canvasPaneElement.addEventListener('touchstart', this.nullEvent);
    this.canvasPaneElement.addEventListener('touchmove', this.nullEvent);
    this.canvasPaneElement.addEventListener('touchend', this.nullEvent);
    this.canvasPaneElement.addEventListener('gesturestart', this.nullEvent);
    this.canvasPaneElement.addEventListener('gesturechange', this.nullEvent);
    this.canvasPaneElement.addEventListener('gestureend', this.nullEvent);

    interact(this.canvasPaneElement)
      .gesturable({
        listeners: {
          move: this.onGestureMove,
          end: this.onGestureEnd,
        },
      })
      .draggable({
        max: 1,
        maxPerElement: 1,
        origin: this.canvasElement,
        allowFrom: this.canvasElement,
        listeners: {
          start: this.onDragStart,
          move: this.onDragMove,
          end: this.onDragEnd,
        },
      })
      .preventDefault();

    interact(this.canvasElement)
      .on('tap', this.onTap)
      .origin(this.canvasElement)
      .preventDefault();
  }

  unbindEventListeners() {
    interact(this.canvasElement).unset();
    interact(this.canvasPaneElement).unset();

    this.canvasPaneElement.removeEventListener('touchstart', this.nullEvent);
    this.canvasPaneElement.removeEventListener('touchmove', this.nullEvent);
    this.canvasPaneElement.removeEventListener('touchend', this.nullEvent);
    this.canvasPaneElement.removeEventListener('gesturestart', this.nullEvent);
    this.canvasPaneElement.removeEventListener('gesturechange', this.nullEvent);
    this.canvasPaneElement.removeEventListener('gestureend', this.nullEvent);
  }
}
