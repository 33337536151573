import { graphql } from '../generated';

export const COMMUNITY_PAGINATED = graphql(`
  query GetCommunityPaginated($types: [String], $limit: Int, $offset: Int) {
    threads(types: $types, limit: $limit, offset: $offset) {
      id
      userId
      title
      type
      createdAt
      updatedAt
      locked
      pinned
      postCount
      animationUrl
      endDate
      user {
        ...CoreUserFields
      }
    }
  }
`);
