import { graphql } from '../generated';

export const SEARCH = graphql(`
  query Search(
    $userOffset: Int
    $animationOffset: Int
    $limit: Int
    $searchTerm: String
  ) {
    search(
      userOffset: $userOffset
      animationOffset: $animationOffset
      limit: $limit
      searchTerm: $searchTerm
    ) {
      id
      animationsCount
      usersCount
      animations {
        id
        url
        title
        version
        userId
        dateCreated
        dateUpdated
        type
        encoded
        schemaVersion
        user {
          ...CoreUserFields
        }
      }
      users {
        ...CoreUserFields
      }
    }
  }
`);
