import { selectToolState } from '../lib/tool-selectors';

import { GenericTool, ToolEventListenerArgs } from './generic';

export class LineTool extends GenericTool {
  active = false;
  x: number;
  y: number;
  colour: string;
  size: number;
  opacity: number;

  onStart({ coords }: ToolEventListenerArgs) {
    const { size, colour, opacity } = selectToolState();
    const [x, y] = coords;
    this.x = x;
    this.y = y;
    this.active = true;
    this.colour = colour;
    this.size = size;
    this.opacity = opacity;

    this.reset();
    this.undoStackPush();
  }

  onMove({ coords }: ToolEventListenerArgs) {
    if (!this.active) return;

    const [x, y] = coords;
    if (x === this.x && y === this.y) return;

    this.offscreenCtx.clearRect(
      0,
      0,
      this.offscreenCtx.canvas.width,
      this.offscreenCtx.canvas.height
    );
    this.offscreenCtx.beginPath();
    this.offscreenCtx.lineWidth = this.size;
    this.offscreenCtx.strokeStyle = this.colour;
    this.offscreenCtx.moveTo(this.x, this.y);
    this.offscreenCtx.lineTo(x, y);
    this.offscreenCtx.stroke();
    this.paintToVisible();
  }

  onEnd() {
    this.active = false;
    this.x = null;
    this.y = null;
    this.commitMerged();
  }
}
