import React from 'react';

import PageHeading from '../components/page-heading';
import ThemedPage from '../components/themed-page';
import { Main, Module } from '../layouts';

const ReportAProblemPage = () => {
  return (
    <ThemedPage>
      <Main>
        <PageHeading>Report a problem</PageHeading>

        <Module>
          <h2>Problems with inappropriate content</h2>
          <p className="my-2">
            If you see something inappropriate on the website. Use the report
            button on any animation page. For thread comments, click the chevron
            icon next to a post to report it.
          </p>
        </Module>
        <Module>
          <h2>Problems with your account</h2>
          <p className="my-2">
            If you are having problems signing-in, please{' '}
            <a href="mailto:help@theanimator.co.uk">email for help</a>. It is
            helpful if you send from the email address you are trying to use to
            sign-in.
          </p>
        </Module>
        <Module>
          <h2>Problems with the website</h2>
          <p className="my-2">
            Please <a href="mailto:help@theanimator.co.uk">email for help</a>{' '}
            with as much detail as possible about the problem you are
            experiencing.
          </p>
          <p className="my-2">
            Include details about the device and operating system you are using
            and what you did to cause the problem. Screenshots are helpful if
            you are able to provide them.
          </p>
        </Module>
      </Main>
    </ThemedPage>
  );
};

export default ReportAProblemPage;
