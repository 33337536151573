import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import config from './lib/config';
import { showModal } from './react/lib/modal/modal-actions';
import { store } from './react/store';

const createApolloClient = () => {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      if (graphQLErrors.length === 1 && graphQLErrors[0].extensions.showModal) {
        store.dispatch(
          showModal('DefaultErrorModal', {
            title: graphQLErrors[0].extensions.title,
            message: graphQLErrors[0].extensions.message,
          })
        );
      } else if (graphQLErrors.length > 1) {
        store.dispatch(
          showModal('DefaultErrorModal', {
            title: 'Sorry, multiple errors occurred',
            message: graphQLErrors
              .map(({ extensions }) => extensions.message)
              .join(' '),
          })
        );
      }

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const httpLink = new HttpLink({ uri: `${config.apiUrl}/graphql` });

  return new ApolloClient({
    cache: new InMemoryCache().restore(window.PAGE_STATE),
    ssrForceFetchDelay: 1000,
    link: from([errorLink, httpLink]),
  });
};

export default typeof window !== 'undefined' ? createApolloClient() : null;
