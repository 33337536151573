import { graphql } from '../generated';

export const PUBLIC_GALLERY = graphql(`
  query GetPublicGallery($offset: Int, $limit: Int) {
    dailyAnimations(offset: $offset, limit: $limit) {
      id
      url
      title
      version
      schemaVersion
      userId
      dailyAnimationCount
      commentCount
      dateUpdated
      encoded
      pixelMode
      type
      altThumbnail
      reactions {
        love
        laugh
        dizzy
        stars
      }
      user {
        id
        name
        theme {
          post
          username
        }
        avatarUrl
      }
    }
    aggregatedPublicAnimationsCount
  }
`);
