import React from 'react';

import Button from '../../components/button';
import { ButtonBar, Module } from '../../layouts';
import { ButtonGroup } from '../../layouts/button-group';
import { ModalComponentProps } from '../modal';

import ModalTitle from './modal-title';

export default function UserBanned(props: ModalComponentProps) {
  return (
    <div className="text-center">
      <ModalTitle>Banned</ModalTitle>
      <Module>
        <p>
          This account has been banned from the website for inappropriate
          behaviour. You can no longer use this website.
        </p>
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-check-circle"
            onClick={props.hideModal}
            type="positive"
            rightAlign
          >
            Ok
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
}
