import React from 'react';

import { Module } from '../../layouts';
import { ModalComponentProps } from '../modal';

import ModalTitle from './modal-title';

type LoadingModalProps = ModalComponentProps & {
  resolved: number;
  total: number;
};

export default function LoadingModal(props: LoadingModalProps) {
  return (
    <div className="text-center">
      <ModalTitle>Loading</ModalTitle>
      <Module>
        <p>Loading animation frames</p>
      </Module>
      <div className="text-5xl text-gray-500">
        <i className="fas fa-spin fa-spinner" />
      </div>
      <Module>
        <p>
          {props.resolved} of {props.total}
        </p>
      </Module>
    </div>
  );
}
