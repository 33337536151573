import React, { FC } from 'react';
import { useQuery } from '@apollo/client';

import { GET_FOLDER_QUERY } from '../../../gql/queries/animation-folder';
import { useModal } from '../../modal/use-modal';
import DropdownMenu from '../dropdown-menu/dropdown-menu';

type FolderSelectorProps = {
  currentFolderId: string | null;
  onFolderSelected: (id: string) => void;
};

const FolderSelector: FC<FolderSelectorProps> = ({
  currentFolderId,
  onFolderSelected,
}) => {
  const { data, loading } = useQuery(GET_FOLDER_QUERY);
  const { showModal } = useModal();
  const selectedFolder = loading
    ? null
    : data.animationFolders.find((folder) => folder.id === currentFolderId);

  const folderMenuOptions = loading
    ? []
    : [
        {
          icon: 'fas fa-cog',
          onClick: () => {
            showModal('ManageFoldersModal');
          },
          text: 'Manage folders',
        },
        {
          icon: 'fas fa-folder',
          text: '(no folder)',
          onClick: () => onFolderSelected(null),
        },
        ...data.animationFolders.map((folder) => ({
          icon: 'fas fa-folder',
          text: folder.name,
          onClick: () => onFolderSelected(folder.id),
        })),
      ];

  const menuButton = loading
    ? {
        text: 'Loading...',
      }
    : { text: selectedFolder?.name ?? '(no folder)' };

  return (
    <DropdownMenu items={folderMenuOptions} align="left" button={menuButton} />
  );
};

export default FolderSelector;
