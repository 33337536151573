import { useEffect, useRef, useState } from 'react';
const POLL_INTERVAL_MS = 200;

export const usePollForProperty = (property) => {
  const pollInterval = useRef(null);
  const [propertyAvailable, setPropertyAvailable] = useState(false);

  useEffect(() => {
    pollInterval.current = setInterval(() => {
      if (property) {
        clearInterval(pollInterval.current);
        setPropertyAvailable(true);
      }
    }, POLL_INTERVAL_MS);
  }, []);

  return propertyAvailable;
};
