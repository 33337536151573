import produce from 'immer';

type ModalState = {
  visible: boolean;
  component: string;
  componentProps: Record<string, unknown>;
  timeCreated: Date;
};

const defaultState: ModalState = {
  visible: false,
  component: '',
  componentProps: {},
  timeCreated: null,
};

export default produce((state = defaultState, action) => {
  switch (action.type) {
    case 'MODAL/SHOW':
      return {
        visible: true,
        component: action.payload.component,
        componentProps: action.payload.componentProps,
        timeCreated: state.timeCreated || Date.now(),
      };
    case 'MODAL/HIDE':
      return { ...defaultState };
  }
  return state;
}, defaultState);
