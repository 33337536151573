import React, { Fragment, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../../components/button';
import { TextInput } from '../../components/forms';
import UserError from '../../components/user-error';
import { ButtonBar, Module } from '../../layouts';
import { ButtonGroup } from '../../layouts/button-group';
import {
  oauthRegisterAction,
  oauthSignInLinkAction,
} from '../../lib/user/user-actions';
import { useAppDispatch } from '../../store';
import * as errorMessages from '../registration-form/constants';

export const UnmatchedAccount = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const inputNewUsernameRef = useRef<HTMLInputElement>();
  const inputExistingUsernameRef = useRef<HTMLInputElement>();
  const inputPasswordRef = useRef<HTMLInputElement>();

  const [usernameErrors, setUsernameErrors] = useState([]);
  const [signinErrors, setSigninErrors] = useState([]);

  const onCreateSelected = async () => {
    const username = inputNewUsernameRef.current.value;
    setUsernameErrors([]);

    const result = await dispatch(oauthRegisterAction(username));
    if (result === true) {
      history.replace('/my-studio');
    } else {
      setUsernameErrors(result.map((code) => errorMessages[code]));
    }
  };

  const onSignInSelected = async () => {
    const username = inputExistingUsernameRef.current.value;
    const password = inputPasswordRef.current.value;
    setSigninErrors([]);

    const result = await dispatch(oauthSignInLinkAction(username, password));
    if (result === true) {
      history.replace('/my-studio');
    } else {
      setSigninErrors([
        'Failed to sign-in. Please check your username and password.',
      ]);
    }
  };

  return (
    <Fragment>
      <Module>
        <p>
          It looks like you&apos;re new here! We can create a new account for
          you, or link to an account you already have.
        </p>
      </Module>
      <Module>
        <p>If you are new, you can choose a new user name:</p>
        <UserError errors={usernameErrors} />
        <TextInput
          id="newUsername"
          type="text"
          maxLength="30"
          ref={inputNewUsernameRef}
          autoCapitalize="none"
          label="User name: (No spaces, alphanumeric, less than 30 chars)"
        />
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-user-circle"
            onClick={onCreateSelected}
            rightAlign
          >
            Create a new account
          </Button>
        </ButtonGroup>
      </ButtonBar>
      <Module>
        <p>
          Or, if you already have an account, you can link it by signing in
          below:
        </p>
        <UserError errors={signinErrors} />
        <TextInput
          id="username"
          className="rounded-md w-full shadow-inner p-2 border border-gray-400"
          type="text"
          maxLength="30"
          ref={inputExistingUsernameRef}
          autoCapitalize="none"
          label="User name:"
        />
        <TextInput
          label="Password:"
          id="password"
          className="rounded-md w-full shadow-inner p-2 border border-gray-400"
          type="password"
          ref={inputPasswordRef}
        />
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-user-circle"
            onClick={onSignInSelected}
            rightAlign
          >
            Sign in and link
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </Fragment>
  );
};
