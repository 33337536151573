import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../components/button';
import { animationSelector } from '../../../components/editor/selectors/animation-selector';
import { editorSelector } from '../../../components/editor/selectors/editor-selector';
import { TextInput } from '../../../components/forms';
import UserError from '../../../components/user-error';
import { ButtonBar, Module } from '../../../layouts/';
import { ButtonGroup } from '../../../layouts/button-group';
import { anibotMover } from '../../../lib/anibot/anibot-actions';
import { ModalComponentProps } from '../../modal';
import ModalTitle from '../modal-title';

export default function AnibotMoveModal(props: ModalComponentProps) {
  const dispatch = useDispatch();
  const [insertMode, setInsertMode] = useState('insert');
  const [errors, setErrors] = useState([]);
  const xAxisRef = useRef<HTMLInputElement>();
  const yAxisRef = useRef<HTMLInputElement>();
  const numFramesRef = useRef<HTMLInputElement>();
  const wrapEdgesRef = useRef<HTMLInputElement>();
  const animation = useSelector(animationSelector);
  const editor = useSelector(editorSelector);

  const onInsertModeChanged = (e) => {
    setInsertMode(e.currentTarget.value);
  };

  const onSubmit = () => {
    const x = parseInt(xAxisRef.current.value, 10);
    const y = parseInt(yAxisRef.current.value, 10);
    const frameCount = parseInt(numFramesRef.current.value, 10);
    const errorsToShow = [];

    // Validation
    if (
      insertMode === 'replace' &&
      frameCount + editor.currentFrameIndex > animation.sequence.length
    ) {
      errorsToShow.push(
        `Animation is not long enough to move ${frameCount} frames.`
      );
    }

    if (isNaN(x) || isNaN(y) || isNaN(frameCount)) {
      errorsToShow.push(`Enter valid numbers.`);
    }

    if (errorsToShow.length) {
      return setErrors(errorsToShow);
    } else {
      setErrors([]);
    }

    dispatch(
      anibotMover({
        x: parseInt(xAxisRef.current.value, 10),
        y: parseInt(yAxisRef.current.value, 10),
        frameCount: parseInt(numFramesRef.current.value, 10),
        insertMode,
        wrap: wrapEdgesRef.current.checked,
      })
    );
    props.hideModal();
  };

  return (
    <div className="text-center">
      <ModalTitle>
        <i className="fas fa-robot" /> Move Layer
      </ModalTitle>
      <Module>
        <p>
          Move current layer automatically. Use negative numbers to move
          left/up.
        </p>
      </Module>
      <Module>
        <input
          type="radio"
          name="insertMode"
          value="insert"
          id="move-insert"
          checked={insertMode === 'insert'}
          onChange={onInsertModeChanged}
        />{' '}
        <label htmlFor="move-insert">Insert new frames</label>{' '}
        <input
          type="radio"
          name="insertMode"
          value="replace"
          id="move-replace"
          checked={insertMode === 'replace'}
          onChange={onInsertModeChanged}
        />{' '}
        <label htmlFor="move-replace">Use existing frames</label>
      </Module>
      <Module>
        <input
          type="checkbox"
          id="wrap"
          value="wrap"
          defaultChecked
          ref={wrapEdgesRef}
        />{' '}
        <label htmlFor="wrap">Wrap at edges</label>
      </Module>
      <Module>
        <TextInput
          id="x_axis"
          size="5"
          inline
          label="left/right pixels"
          defaultValue="0"
          type="number"
          className="w-20"
          ref={xAxisRef}
        />
        <TextInput
          id="y_axis"
          size="5"
          inline
          label="up/down pixels"
          defaultValue="0"
          type="number"
          className="w-20"
          ref={yAxisRef}
        />
        <TextInput
          id="frameCount"
          size="5"
          inline
          label="num frames"
          defaultValue="2"
          className="w-20"
          type="number"
          max="100"
          min="2"
          ref={numFramesRef}
        />
        <UserError errors={errors} />
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-times-circle"
            onClick={props.hideModal}
            type="negative"
            rightAlign
          >
            Cancel
          </Button>
          <Button
            icon="fas fa-robot"
            onClick={onSubmit}
            type="positive"
            rightAlign
          >
            Run Tweenbot
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
}
