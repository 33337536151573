import { graphql } from '../generated';

export const ANIMATION = graphql(`
  query GetAnimationData($slug: String) {
    animation(slug: $slug) {
      id
      url
      title
      version
      userId
      dateCreated
      dateUpdated
      sequence
      views
      blacklisted
      public
      palette
      frames
      width
      height
      schemaVersion
      encoded
      layers
      folderId
      pixelMode
      type
    }
  }
`);
