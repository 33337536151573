import React, { FC } from 'react';

type OnionSkinProps = {
  className: string;
  width: number;
  height: number;
  dataUrl: string;
};

const OnionSkin: FC<OnionSkinProps> = ({
  className,
  width,
  height,
  dataUrl,
}) => {
  return (
    <div className="editor-onion-skin pointer-events-none">
      <img
        className={`${className} pointer-events-none`}
        width={width}
        height={height}
        src={dataUrl}
      />
    </div>
  );
};

export default OnionSkin;
