import React from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { GET_FOLDER_QUERY } from '../../../gql/queries/animation-folder';
import { MUTATE_MOVE_ANIMATIONS } from '../../../gql/queries/mutations/move-animations';
import Button from '../../components/button';
import { ButtonBar, Module } from '../../layouts';
import { ButtonGroup } from '../../layouts/button-group';
import { ModalComponentProps } from '../modal';

import ModalTitle from './modal-title';

type MoveAnimationsModalProps = ModalComponentProps & {
  animations: number[];
};

export default function MoveAnimationsModal({
  animations,
  hideModal,
}: MoveAnimationsModalProps) {
  const { data, loading, error } = useQuery(GET_FOLDER_QUERY);
  const [mutateMoveAnimations] = useMutation(MUTATE_MOVE_ANIMATIONS, {
    onCompleted: hideModal,
    refetchQueries: ['GetMyStudio'],
  });

  const renderFolders = () => {
    return data.animationFolders.map((folder) => {
      return (
        <Button
          className="m-1"
          key={folder.id}
          icon="fas fa-folder"
          onClick={() => {
            mutateMoveAnimations({
              variables: {
                animations,
                folderId: folder.id,
              },
            });
          }}
        >
          {folder.name}
        </Button>
      );
    });
  };

  return (
    <div className="text-center">
      <ModalTitle>Move Animations</ModalTitle>
      <Module>
        <p>Choose a folder to move {animations.length} animation(s) to:</p>
      </Module>
      <Module>
        {' '}
        <Button
          className="m-1"
          icon="fas fa-folder"
          onClick={() => {
            mutateMoveAnimations({
              variables: {
                animations,
                folderId: null,
              },
            });
          }}
        >
          (no folder)
        </Button>
        {!loading && !error && renderFolders()}
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button onClick={hideModal} type="negative" centre>
            <i className="fa fa-ban" /> Cancel
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
}
