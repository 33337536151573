import React, { FC, useEffect, useState } from 'react';

import { Animation } from '../../../gql/generated/graphql';
import Reaction from '../reaction';

type ReactionsShowerProps = {
  reactions: Animation['reactionsBreakdown'];
};

const ReactionsShower: FC<ReactionsShowerProps> = ({ reactions }) => {
  const [positions, setPositions] = useState([]);

  useEffect(() => {
    setPositions(
      reactions.map((reaction, index) => {
        return {
          type: <Reaction key={index} type={reaction.type} />,
          left: Math.round(Math.random() * 100),
          delay: (Math.random() * 1).toFixed(2),
          time: (Math.random() * 2.5 + 1).toFixed(2),
          color: `hsla(${Math.round(Math.random() * 360)},70%,80%,1)`,
        };
      })
    );
  }, [reactions]);

  return (
    <div className="reactions-shower">
      {positions.map((position, index) => {
        return (
          <span
            key={index}
            className="reactions-shower__item"
            style={{
              left: `${position.left}%`,
              animationDelay: `${position.delay}s`,
              animationDuration: `${position.time}s`,
              color: position.color,
            }}
          >
            {position.type}
          </span>
        );
      })}
    </div>
  );
};

export default ReactionsShower;
