import { graphql } from '../generated';

export const ADMIN_RELATED_USERS = graphql(`
  query GetAdminRelatedUsers($name: String) {
    adminRelatedUsers(name: $name) {
      id
      name
      banned
      verified
      dateJoined
    }
  }
`);
