import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { ButtonBar, Main, Module } from 'layouts';
import { ButtonGroup } from 'layouts/button-group';

import DropdownMenu from 'components/dropdown-menu';
import PageHeading from 'components/page-heading';
import PaginatedGallery from 'components/paginated-gallery';
import ThemedHeading from 'components/themed-heading';
import ThemedPage from 'components/themed-page';
import { UserPill } from 'components/user-pill/user-pill';

import { GET_FOLLOWS } from '../../../gql/queries/follows';
import { MY_STUDIO } from '../../../gql/queries/my-studio';
import { textColorForBackground } from '../../../lib/text-color-for-background';
import usernameThemeColour from '../../../lib/user-name-theme-colour';
import Avatar from '../../components/avatar/avatar';
import Button from '../../components/button';
import { Follows } from '../../components/follows/follows';
import { Statistic } from '../../components/statistic/statistic';
import { showModal } from '../../lib/modal/modal-actions';
import currentUserSelector from '../../lib/selectors/current-user-selector';

const ANIMATIONS_PER_PAGE = 20;

const generatePosessive = function (username) {
  if (!username) return '';
  return username.slice(-1) === 's' ? "'" : "'s";
};

const MyStudio = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentPage: currentPageString } = useParams();
  const currentUser = useSelector(currentUserSelector);
  const [currentFolder, setCurrentFolder] = useState(null);

  const currentPage = parseInt(currentPageString || 1, 10);
  const {
    loading,
    data = {
      privateAnimations: new Array(20).fill({}),
      privateAnimationsCount: 0,
      animationFolders: [],
    },
  } = useQuery(MY_STUDIO, {
    variables: {
      offset: (currentPage - 1) * ANIMATIONS_PER_PAGE,
      limit: ANIMATIONS_PER_PAGE,
      folderId: currentFolder ? currentFolder.id : null,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: followerData } = useQuery(GET_FOLLOWS, {
    variables: { userId: currentUser.id },
  });

  const avatarColour = usernameThemeColour(currentUser);

  const folderMenuOptions = [
    {
      icon: 'fas fa-cog',
      onClick: () => {
        dispatch(showModal('ManageFoldersModal'));
      },
      text: 'Manage folders',
    },
    {
      icon: 'fas fa-folder',
      text: '(no folder)',
      onClick: () => {
        setCurrentFolder(null);
        history.replace('/my-studio');
      },
    },
    ...data.animationFolders.map((folder) => ({
      icon: 'fas fa-folder',
      text: folder.name,
      onClick: () => {
        setCurrentFolder(folder);
        history.replace('/my-studio');
      },
    })),
  ];

  const createNewAnimation = () => {
    dispatch(showModal('ChooseAnimationDimensionsModal'));
  };

  const createComic = () => {
    dispatch(showModal('CreateComicModal'));
  };

  const customisePalette = () => {
    dispatch(showModal('ThemeModal'));
  };

  const renderNoAnimationsPlaceholder = () => {
    if (data.privateAnimations.length > 0) return null;

    return (
      <Module>
        <p>No animations here yet!</p>
        <p>
          When you create animations they will appear here for you to view and
          edit.
        </p>
        <p>Happy animating!</p>
      </Module>
    );
  };

  const renderButtonBar = () => {
    if (!currentUser.id) return null;

    return (
      <ButtonBar>
        <ButtonGroup>
          <Button
            type="positive"
            onClick={createNewAnimation}
            icon="fas fa-plus-circle"
          >
            Create animation
          </Button>
          <Button type="positive" onClick={createComic} icon="fas fa-book-open">
            Create comic (beta)
          </Button>
        </ButtonGroup>
        <ButtonGroup align="centre" expand>
          <DropdownMenu
            align="left"
            button={{
              icon: 'fas fa-folder-open',
              text: currentFolder ? currentFolder.name : '(no folder)',
            }}
            items={folderMenuOptions}
          />
        </ButtonGroup>
        <ButtonGroup align="right">
          <Button onClick={customisePalette} icon="fas fa-palette">
            Customise theme
          </Button>
        </ButtonGroup>
      </ButtonBar>
    );
  };

  if (currentUser.id) {
    return (
      <ThemedPage theme={currentUser.theme}>
        <Main theme={currentUser.theme}>
          <Helmet>
            <title>The Animator - My Studio</title>
            <meta name="description" content="My animation studio" />
          </Helmet>
          <ThemedHeading theme={currentUser.theme}>
            <UserPill
              user={currentUser}
              suffix={generatePosessive(currentUser.id)}
            />{' '}
            Studio
          </ThemedHeading>

          {renderButtonBar()}

          <Module>
            <div className="flex flex-col sm:flex-row">
              <div className="text-center flex-shrink-0 mr-2 flex items-center flex-col mb-2">
                <div
                  className="w-32 h-32 border-2 rounded-md text-center shadow-md"
                  style={{
                    imageRendering: 'pixelated',
                    background: `${avatarColour}`,
                    color: textColorForBackground(avatarColour),
                  }}
                >
                  <Avatar user={currentUser} editable />
                </div>
              </div>
              <div className="flex-1">
                <div className="flex mb-2 justify-center sm:justify-start">
                  <Statistic title="Member for" icon="fas fa-calendar-alt">
                    {dayjs(currentUser.dateJoined).fromNow(true)}
                  </Statistic>
                  <Statistic title="Animations" icon="fas fa-images">
                    {data.privateAnimationsCount}
                  </Statistic>
                </div>

                <Follows
                  title="Followed by"
                  users={followerData?.follows.followers}
                />
                <Follows
                  title="Following"
                  users={followerData?.follows.following}
                />
              </div>
            </div>
          </Module>

          {renderNoAnimationsPlaceholder()}
          <PaginatedGallery
            page={currentPage}
            totalAnimations={data.privateAnimationsCount}
            animations={data.privateAnimations}
            linkPrefix="/my-studio"
            showPublic={true}
            loading={loading}
            editable
          />
        </Main>
      </ThemedPage>
    );
  }

  return (
    <ThemedPage>
      <Main>
        <Helmet>
          <title>The Animator - My Studio</title>
          <meta name="description" content="My animation studio" />
        </Helmet>
        <PageHeading>Your Own Animation Studio Here</PageHeading>
        <Module>
          <div className="split-layout">
            <div className="split-layout--large">
              <p>
                <Link to="/sign-in">Sign in</Link> to see your own animations.
              </p>
              <p>
                If you don&apos;t yet have an account, you can{' '}
                <Link to="/register">create a new account</Link>.
              </p>
            </div>
            <div className="split-layout--small">
              <img
                src="/images/blue-monster.jpg"
                alt=""
                style={{ width: '100%' }}
              />
            </div>
          </div>
        </Module>
      </Main>
    </ThemedPage>
  );
};

export default MyStudio;
