import React, { MouseEvent, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { Link, Redirect, useLocation } from 'react-router-dom';

import config from '../../../lib/config';
import Button from '../../components/button';
import { TextInput } from '../../components/forms';
import PageHeading from '../../components/page-heading';
import ThemedPage from '../../components/themed-page';
import UserError from '../../components/user-error';
import { ButtonBar, Main, Module } from '../../layouts';
import { ButtonGroup } from '../../layouts/button-group';
import { clearErrorsAction } from '../../lib/error/error-actions';
import { errorSelector } from '../../lib/error/error-selector';
import currentUserSelector from '../../lib/selectors/current-user-selector';
import { useAppDispatch } from '../../store';

import { AppleIdButton } from './appleid-button';
import { GoogleButton } from './google-button';
import * as signInActions from './sign-in-actions';

const SignInForm = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const user = useSelector(currentUserSelector);
  const errorState = useSelector(errorSelector);
  const inputUsernameRef = useRef<HTMLInputElement>();
  const inputPasswordRef = useRef<HTMLInputElement>();

  const onSignInClicked = (e: MouseEvent<HTMLButtonElement>) => {
    dispatch(clearErrorsAction());
    dispatch(
      signInActions.signIn(
        inputUsernameRef.current.value,
        inputPasswordRef.current.value
      )
    );
    e.preventDefault();
  };

  const { search } = location;
  const searchParams = new URLSearchParams(search);

  if (user.id) {
    return <Redirect to="/my-studio" />;
  }

  const errors = errorState;

  return (
    <ThemedPage>
      <Main small>
        <Helmet>
          <title>The Animator - Sign-in</title>
          <meta name="description" content="Sign in to your Animator account" />
        </Helmet>
        <PageHeading>Sign-in to your account</PageHeading>
        <Module>
          <p>
            You can create a new account or sign into an existing account using
            your school, company or personal account with these providers:
          </p>
          {searchParams.get('ssoError') ? (
            <UserError
              errors={{
                ssoFailed: 'Sign in could not be completed. Please try again',
              }}
            />
          ) : null}
        </Module>
        <Module className="flex justify-center flex-wrap">
          {config.features.azureSSO && (
            <span className="m-1">
              <a href="/initiate-azure-sign-in">
                <img
                  src="/images/ms-symbollockup_signin_light.svg"
                  style={{ display: 'inline', maxWidth: 'auto' }}
                />
              </a>
            </span>
          )}
          {config.features.appleSSO && (
            <span className="m-1">
              <AppleIdButton />
            </span>
          )}
          {config.features.googleSSO && (
            <span className="m-1">
              <GoogleButton />
            </span>
          )}
        </Module>
        <form>
          <Module>
            <p>
              If you don&apos;t have an account, you can{' '}
              <Link to="/register" data-testid="register-link">
                create a new account
              </Link>
              . If you have forgotten your password, you can{' '}
              <Link to="/password-reset">request a password reset</Link>.
            </p>
            {errors ? <UserError errors={errors} /> : null}
            <TextInput
              id="username"
              className="rounded-md w-full shadow-inner p-2 border border-gray-400"
              type="text"
              maxLength="30"
              ref={inputUsernameRef}
              autoCapitalize="none"
              label="User name:"
            />
            <TextInput
              label="Password:"
              id="password"
              className="rounded-md w-full shadow-inner p-2 border border-gray-400"
              type="password"
              ref={inputPasswordRef}
            />
          </Module>

          <ButtonBar>
            <ButtonGroup align="right">
              <Button
                icon="fas fa-user-circle"
                onClick={onSignInClicked}
                rightAlign
                data-testid="signin-button"
              >
                Sign in
              </Button>
            </ButtonGroup>
          </ButtonBar>
        </form>
      </Main>
    </ThemedPage>
  );
};

export default SignInForm;
