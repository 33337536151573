import React from 'react';
import { PropTypes } from 'prop-types';

const ButtonBar = ({ children }) => {
  return <div className="my-4 flex justify-between">{children}</div>;
};

ButtonBar.propTypes = {
  children: PropTypes.node,
};

export { ButtonBar };
