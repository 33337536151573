import config from '../../../lib/config';
import { FrameImage } from '../../components/editor/canvas/lib/frame-image';

import { post } from './fetch';
const UPLOAD_BATCH_SIZE = 5;

const loadFrame = function (
  animationUrl,
  frameId,
  version,
  width,
  height,
  schemaVersion
): Promise<HTMLCanvasElement> {
  return new Promise(function (resolve, reject) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    const img = document.createElement('img');
    img.crossOrigin = 'Anonymous';
    img.addEventListener('load', function () {
      ctx.drawImage(img, 0, 0);
      resolve(canvas);
    });
    img.addEventListener('error', function () {
      return reject(new Error('Image failed to load'));
    });
    img.src = `${config.s3Path}/${animationUrl}/${
      schemaVersion > 2 ? `v${version}/` : ''
    }${frameId}.png`;
  });
};

export const save = function (
  url,
  images,
  version,
  onFrameCompleted,
  abortSignal
) {
  return uploadAllFrames(url, images, version, onFrameCompleted, abortSignal);
};

export const loadFramesService = async function ({
  imagesToLoad,
  onFrameCompleted,
  animationData,
}) {
  const images = await Promise.all<FrameImage>(
    imagesToLoad.map(async (_, index) => {
      const canvas = await loadFrame(
        animationData.url,
        index,
        animationData.version,
        animationData.width,
        animationData.height,
        animationData.schemaVersion
      );
      onFrameCompleted();
      return new FrameImage({ from: canvas });
    })
  );

  return { images };
};

export const uploadAllFrames = async function (
  animationUrl,
  images,
  version,
  onFrameCompleted,
  abortSignal
) {
  for (
    let imageIndex = 0;
    imageIndex < images.length;
    imageIndex += UPLOAD_BATCH_SIZE
  ) {
    await uploadBatch({
      url: animationUrl,
      version,
      abortSignal,
      imageBatch: images
        .slice(imageIndex, imageIndex + UPLOAD_BATCH_SIZE)
        .map((imageData, offset) => {
          return {
            id: offset + imageIndex,
            imageData,
          };
        }),
    });
    onFrameCompleted(Math.ceil((imageIndex / images.length) * 100));
  }
};

export const cleanupService = async ({ slug, currentVersion, abortSignal }) => {
  return await post(
    '/api/animation/cleanup',
    {
      slug,
      currentVersion,
    },
    { abortSignal }
  );
};

const uploadBatch = ({ url, imageBatch, version, abortSignal }) => {
  return post(
    '/api/animation/frame',
    {
      animationUrl: url,
      version,
      images: imageBatch,
    },
    { abortSignal }
  );
};
