import {
  hideModal as hideModalAction,
  showModal as showModalAction,
} from '../lib/modal/modal-actions';
import { useAppDispatch } from '../store';

import { components } from './modal';

export const useModal = () => {
  const dispatch = useAppDispatch();

  const invokeShowModal = (
    modalType: keyof typeof components,
    props?: Record<string, unknown>
  ) => {
    dispatch(showModalAction(modalType, props));
  };

  const invokeHideModal = () => {
    dispatch(hideModalAction());
  };

  return {
    showModal: invokeShowModal,
    hideModal: invokeHideModal,
  };
};
