import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../../components/button';
import { ButtonBar, Module } from '../../layouts/';
import { ButtonGroup } from '../../layouts/button-group';

import ModalTitle from './modal-title';

type ModalProps = {
  hideModal: () => void;
};

const NotSignedInModal = (props: ModalProps) => {
  const history = useHistory();

  const onCancelClicked = () => {
    props.hideModal();
  };

  const onSignInClicked = () => {
    props.hideModal();
    history.push('/sign-in');
  };

  return (
    <div className="text-center">
      <ModalTitle>Not signed in</ModalTitle>
      <Module>
        <p>Sorry, you need to sign in to do that.</p>
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-times-circle"
            onClick={onCancelClicked}
            type="negative"
          >
            Cancel
          </Button>
          <Button
            icon="fas fa-sign-in-alt"
            onClick={onSignInClicked}
            type="positive"
          >
            Sign In
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
};

export default NotSignedInModal;
