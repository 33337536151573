import { AnyAction, ThunkAction } from '@reduxjs/toolkit';

import apolloClient from '../../../apollo-client';
import { AnimationType } from '../../../gql/generated/graphql';
import { ANIMATION } from '../../../gql/queries/animation';
import { MUTATE_ANIMATION } from '../../../gql/queries/mutate-animation';
import animationActions from '../../lib/animation/animation-actions';
import { AnimationPixelMode } from '../../lib/animation/animation-default-state';
import { rgbToHex } from '../../lib/animation/canvas-utils';
import { hideModal, showModal } from '../../lib/modal/modal-actions';
import {
  cleanupService,
  save as saveService,
} from '../../lib/services/animation';
import { AppDispatch, RootState } from '../../store';

import { serialiseSequence } from './editor-helpers';

const setLayerVisibility = function ({ layerIndex, visible }) {
  return {
    type: 'EDITOR/SET_LAYER_VISIBILITY',
    payload: {
      layerIndex,
      visible,
    },
  };
};

const setFramePickerLayerVisibility = function ({ layerIndex, visible }) {
  return {
    type: 'EDITOR/SET_FRAME_PICKER_LAYER_VISIBILITY',
    payload: {
      layerIndex,
      visible,
    },
  };
};

const onToolChanged = function (type) {
  return {
    type: 'EDITOR/TOOL_CHANGED',
    payload: {
      type,
    },
  };
};

const goToFrame = function (index) {
  return {
    type: 'EDITOR/GO_TO_FRAME',
    payload: { index },
  };
};

const goToFrameId = function (id) {
  return (dispatch, getState) => {
    const index = getState().animation.sequence.findIndex(
      (sequenceItem) => sequenceItem.id === id
    );
    return dispatch({
      type: 'EDITOR/GO_TO_FRAME',
      payload: { index },
    });
  };
};

const setColourFromEyeDropper = (pixelData) => {
  return (dispatch, getState) => {
    const hex = rgbToHex(pixelData[0], pixelData[1], pixelData[2]);
    const opacity = pixelData[3] / 255;
    const { currentColourIndex } = getState().editor;
    dispatch(animationActions.setColourAction(currentColourIndex, hex));
    dispatch(onOpacityChanged(opacity));
  };
};

const nextFrame = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { currentFrameIndex } = state.editor;
    const { sequence } = state.animation;
    const newIndex =
      currentFrameIndex < sequence.length - 1 ? currentFrameIndex + 1 : 0;
    dispatch(goToFrame(newIndex));
  };
};

const previousFrame = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { currentFrameIndex } = state.editor;
    const { sequence } = state.animation;
    const newIndex =
      currentFrameIndex > 0 ? currentFrameIndex - 1 : sequence.length - 1;
    dispatch(goToFrame(newIndex));
  };
};

const toggleFramePicker = function () {
  return {
    type: 'EDITOR/TOGGLE_FRAME_PICKER',
    payload: {},
  };
};

const onBrushSizeChanged = function (size) {
  return {
    type: 'EDITOR/BRUSH_SIZE_CHANGED',
    payload: {
      size,
    },
  };
};

const onOpacityChanged = function (opacity) {
  return {
    type: 'EDITOR/OPACITY_CHANGED',
    payload: {
      opacity,
    },
  };
};

const onColourChanged = function (index) {
  return {
    type: 'EDITOR/COLOUR_CHANGED',
    payload: {
      index,
    },
  };
};

const resetEditor = function () {
  return {
    type: 'EDITOR/RESET',
  };
};

const startPlayback = function () {
  return {
    type: 'EDITOR/PLAYBACK_START',
    payload: {},
  };
};

const stopPlayback = function () {
  return {
    type: 'EDITOR/PLAYBACK_STOP',
    payload: {},
  };
};

const undoStackPush = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    const { editor, animation } = getState();

    dispatch({
      type: 'EDITOR/REDO_STACK_RESET',
    });

    dispatch({
      type: 'EDITOR/UNDO_STACK_PUSH',
      payload: {
        frameIndex: editor.currentFrameIndex,
        layerIndex: editor.currentLayerIndex,
        sequence: animation.sequence,
        layers: animation.layers,
      },
    });
  };
};

const createNew = ({
  width,
  height,
  pixelMode,
  type,
}: {
  width: number;
  height: number;
  pixelMode: AnimationPixelMode;
  type: AnimationType;
}) => {
  return (dispatch: AppDispatch) => {
    dispatch(resetEditor());

    return dispatch(
      animationActions.createNew({
        width,
        height,
        pixelMode,
        type,
      })
    ).then(() => {
      dispatch(resetEditorLayers());
      dispatch(goToFrame(0));
    });
  };
};

const undo = () => {
  return (dispatch, getState) => {
    const { editor, animation } = getState();

    // Current state -> redo stack before undo
    dispatch({
      type: 'EDITOR/REDO_STACK_PUSH',
      payload: {
        frameIndex: editor.currentFrameIndex,
        layerIndex: editor.currentLayerIndex,
        sequence: [...animation.sequence],
        layers: [...animation.layers],
      },
    });

    const undoItem = editor.undoStack[editor.undoStack.length - 1];
    dispatch({
      type: 'EDITOR/GO_TO_LAYER',
      payload: undoItem.layerIndex,
    });
    dispatch(goToFrame(undoItem.frameIndex));
    dispatch({
      type: 'ANIMATION/SET_FROM_UNDO',
      payload: {
        layers: undoItem.layers,
        sequence: undoItem.sequence,
      },
    });
    dispatch(undoStackPop());
  };
};

const redo = () => {
  return (dispatch, getState) => {
    const { editor, animation } = getState();

    // Current state -> undo stack before redo
    dispatch({
      type: 'EDITOR/UNDO_STACK_PUSH',
      payload: {
        frameIndex: editor.currentFrameIndex,
        layerIndex: editor.currentLayerIndex,
        sequence: [...animation.sequence],
        layers: [...animation.layers],
      },
    });

    const redoItem = editor.redoStack[editor.redoStack.length - 1];
    dispatch({
      type: 'EDITOR/GO_TO_LAYER',
      payload: redoItem.layerIndex,
    });
    dispatch(goToFrame(redoItem.frameIndex));
    dispatch({
      type: 'ANIMATION/SET_FROM_UNDO',
      payload: {
        layers: redoItem.layers,
        sequence: redoItem.sequence,
      },
    });
    dispatch(redoStackPop());
  };
};

const undoStackPop = function () {
  return {
    type: 'EDITOR/UNDO_STACK_POP',
    payload: {},
  };
};

const redoStackPop = function () {
  return {
    type: 'EDITOR/REDO_STACK_POP',
    payload: {},
  };
};

const nextLayer = () => {
  return {
    type: 'EDITOR/NEXT_LAYER',
  };
};

const previousLayer = () => {
  return {
    type: 'EDITOR/PREVIOUS_LAYER',
  };
};

const openPaletteEditorAction = function () {
  return showModal('PaletteModal');
};

const setFramePickerMode = function (mode) {
  return {
    type: 'EDITOR/SET_FRAME_PICKER_MODE',
    payload: mode,
  };
};

const save = function ({ history }) {
  return async function (dispatch, getState) {
    const { user, animation } = getState();
    let { id, url } = animation;
    let version = 0;

    if (!user.id) {
      return dispatch(showModal('NotSignedInModal'));
    }

    const abortController = new AbortController();
    const { signal } = abortController;

    const onCancel = () => {
      abortController.abort();
    };

    dispatch(
      showModal('SavingModal', {
        progress: 0,
        message: 'Saving frames...',
        onCancel,
      })
    );

    try {
      const { flattenedSequence, images } = serialiseSequence(
        animation.sequence
      );

      if (!animation.id) {
        // New animation - create it first
        const createdAnimation = await apolloClient.mutate({
          mutation: MUTATE_ANIMATION,
          variables: {
            title: animation.title,
            sequence: flattenedSequence,
            public: animation.public,
            palette: animation.palette,
            width: animation.width,
            height: animation.height,
            layers: animation.layers,
            schemaVersion: 3,
            folderId: animation.folderId,
            pixelMode: animation.pixelMode,
            type: animation.type,
          },
        });

        ({ id, url } = createdAnimation.data.animation);
        dispatch(animationActions.setUrl(url));
        dispatch(animationActions.setId(id));
        history.replace(`/editor/${url}`);
      } else {
        const animationData = await apolloClient.query({
          query: ANIMATION,
          variables: {
            slug: url,
          },
        });
        version = animationData.data.animation.version + 1;
      }

      const onFrameCompleted = () => {
        return (progress) => {
          dispatch(
            showModal('SavingModal', {
              progress,
              message: 'Saving frames...',
              version,
              onCancel,
            })
          );
        };
      };

      await saveService(url, images, version, onFrameCompleted(), signal);

      dispatch(
        showModal('SavingModal', {
          progress: 100,
          message: 'Finalising...',
          version,
          onCancel,
        })
      );

      // Commit the new version
      await apolloClient.mutate({
        mutation: MUTATE_ANIMATION,
        variables: {
          id,
          title: animation.title,
          sequence: flattenedSequence,
          public: animation.public,
          palette: animation.palette,
          width: animation.width,
          height: animation.height,
          layers: animation.layers,
          version,
          schemaVersion: 3,
          requestEncode: true,
          folderId: animation.folderId,
          pixelMode: animation.pixelMode,
          type: animation.type,
        },
      });

      await cleanupService({
        slug: url,
        currentVersion: version,
        abortSignal: signal,
      });

      dispatch(hideModal());
      dispatch(animationActions.setSaved(true));

      if (
        animation.type === AnimationType.Avatar &&
        user.avatarUrl !== animation.url
      ) {
        dispatch(
          showModal('ConfirmSetAvatarModal', { animationUrl: animation.url })
        );
      }
    } catch (e) {
      if (e.status === 403 && e.response.status === 'user_banned') {
        return dispatch(showModal('UserBannedModal'));
      }
      if (
        e.status === 400 &&
        e.response.message === 'Animation does not exist'
      ) {
        // Animation was deleted, reset and try again
        dispatch(animationActions.setUrl(null));
        dispatch(animationActions.setId(null));
        return dispatch(save({ history }));
      }
      console.error(e);
      return dispatch(showModal('SaveFailedModal'));
    }
  };
};

const resetEditorLayers = () => {
  return (dispatch, getState) => {
    const { animation } = getState();
    dispatch({
      type: 'EDITOR/GO_TO_LAYER',
      payload: animation.layers.length - 1,
    });
    dispatch({
      type: 'EDITOR/SET_LAYERS',
      payload: animation.layers.map(() => true),
    });
    dispatch({
      type: 'EDITOR/SET_FRAME_PICKER_LAYERS',
      payload: animation.layers.map(() => true),
    });
  };
};

const setDbState = (dbState) => {
  return {
    type: 'EDITOR/SET_DB_STATE',
    payload: dbState,
  };
};

const setDbSaveState = (saveState) => {
  return {
    type: 'EDITOR/SET_DB_SAVE_STATE',
    payload: saveState,
  };
};

const toggleOnionSkins = () => {
  return {
    type: 'EDITOR/TOGGLE_ONION_SKINS',
  };
};

const toggleGrid = () => {
  return {
    type: 'EDITOR/TOGGLE_GRID',
  };
};

export default {
  onToolChanged,
  goToFrame,
  goToFrameId,
  createNew,
  toggleFramePicker,
  openPaletteEditorAction,
  onBrushSizeChanged,
  onOpacityChanged,
  onColourChanged,
  setColourFromEyeDropper,
  startPlayback,
  stopPlayback,
  undoStackPush,
  undoStackPop,
  undo,
  redo,
  redoStackPop,
  save,
  resetEditor,
  nextLayer,
  previousLayer,
  resetEditorLayers,
  setFramePickerMode,
  setLayerVisibility,
  setFramePickerLayerVisibility,
  nextFrame,
  previousFrame,
  setDbState,
  setDbSaveState,
  toggleOnionSkins,
  toggleGrid,
};
