import React, { Fragment, PureComponent } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';

import { UserPill } from 'components/user-pill/user-pill';

import CompetitionCountdown from '../../pages/thread-page/competition-countdown';
class ThreadTable extends PureComponent {
  getThreads() {
    return this.props.threads.map((thread) => {
      let link;
      if (thread.type === 'ANIMATION') {
        link = `/animation/${thread.animationUrl}`;
      } else if (thread.type === 'COMIC') {
        link = `/comic/${thread.animationUrl}`;
      } else {
        link = `/community/general/thread/${thread.id}`;
      }
      return (
        <tr key={thread.id}>
          <td>
            <span className="mr-1">
              <UserPill user={thread.user} small />
            </span>
            <Link to={link}>{thread.title}</Link>
            {thread.locked ? <i className="fas fa-lock lock-icon" /> : ''}
            {thread.pinned ? (
              <i className="fas fa-thumbtack pinned-icon" />
            ) : null}
            {thread.type === 'COMPETITION' && (
              <Fragment>
                <i className="fas fa-trophy competition-icon mr-2" />
                <span className="text-xs whitespace-nowrap">
                  <CompetitionCountdown endDate={thread.endDate} compact />
                </span>
              </Fragment>
            )}
          </td>
          <td className="text-center">{thread.postCount}</td>
          <td className="text-xs text-right">
            {dayjs(thread.updatedAt).fromNow()}
          </td>
        </tr>
      );
    });
  }

  render() {
    return (
      <table className="thread-table">
        <thead>
          <tr>
            <td>Title</td>
            <td className="text-center">Posts</td>
            <td className="text-right">Last post</td>
          </tr>
        </thead>
        <tbody>{this.getThreads()}</tbody>
      </table>
    );
  }
}

ThreadTable.propTypes = {
  threads: PropTypes.array,
};

export default ThreadTable;
