import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';

import {
  Animation,
  ReactionAction,
  ReactionType,
} from '../../../gql/generated/graphql';
import { MUTATE_REACTION } from '../../../gql/queries/mutate-reaction';
import { showModal } from '../../lib/modal/modal-actions';
import currentUserSelector from '../../lib/selectors/current-user-selector';

type ReactionSelectorProps = {
  animationId: Animation['id'];
  reactionsBreakdown: Animation['reactionsBreakdown'];
};

type UserReactions = {
  [ReactionType.Dizzy]?: boolean;
  [ReactionType.Laugh]?: boolean;
  [ReactionType.Love]?: boolean;
  [ReactionType.Stars]?: boolean;
};

const ReactionSelector: FC<ReactionSelectorProps> = ({
  animationId,
  reactionsBreakdown,
}) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector);
  const [mutateReaction] = useMutation(MUTATE_REACTION);
  const userReactions = reactionsBreakdown
    .filter((reaction) => reaction.user.id === currentUser.id)
    .reduce<UserReactions>((reactions, reaction) => {
      reactions[reaction.type] = true;
      return reactions;
    }, {});

  const onReactionSelected = (e) => {
    if (!currentUser.id) {
      dispatch(showModal('NotSignedInModal'));
    }

    const { type } = e.currentTarget.dataset;
    mutateReaction({
      variables: {
        animationId,
        type,
        action: userReactions[type]
          ? ReactionAction.Remove
          : ReactionAction.Add,
      },
    });
  };

  return (
    <div className="reaction-selector">
      <ul className="reaction-selector__list">
        <li>
          <button
            className={`reaction-selector__button ${
              userReactions.love ? 'reaction-selector__button--active' : ''
            }`}
            data-type="love"
            onClick={onReactionSelected}
          >
            <i className="fas fa-heart" />
          </button>
        </li>
        <li>
          <button
            className={`reaction-selector__button ${
              userReactions.laugh ? 'reaction-selector__button--active' : ''
            }`}
            data-type="laugh"
            onClick={onReactionSelected}
          >
            <i className="fas fa-grin-squint-tears" />
          </button>
        </li>
        <li>
          <button
            className={`reaction-selector__button ${
              userReactions.stars ? 'reaction-selector__button--active' : ''
            }`}
            data-type="stars"
            onClick={onReactionSelected}
          >
            <i className="fas fa-grin-stars" />
          </button>
        </li>
        <li>
          <button
            className={`reaction-selector__button ${
              userReactions.dizzy ? 'reaction-selector__button--active' : ''
            }`}
            data-type="dizzy"
            onClick={onReactionSelected}
          >
            <i className="fas fa-dizzy" />
          </button>
        </li>
      </ul>
    </div>
  );
};

export default ReactionSelector;
