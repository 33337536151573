import { graphql } from '../../generated';

export const MUTATE_FOLLOWING = graphql(`
  mutation MutateFollowing($userId: String, $following: Boolean) {
    mutateFollowing(userId: $userId, following: $following) {
      id
      isFollowing
      followers {
        ...CoreUserFields
      }
    }
  }
`);
