import { graphql } from '../generated';

export const GET_COMIC = graphql(`
  query GetComic($slug: String) {
    comic(slug: $slug) {
      id
      columns

      parentAnimation {
        id
        title
        public
        reactions {
          id
          love
          laugh
          dizzy
          stars
        }
        reactionsBreakdown {
          id
          type
          user {
            id
            name
          }
        }

        thread {
          id
          userId
          title
          locked
          pinned
          type
          posts {
            ...PostsFields
          }
        }
      }

      user {
        ...CoreUserFields
      }

      frames {
        id
        animation {
          ...CoreAnimationFields
        }
        sequence
        rowSpan
        colSpan
      }
    }
  }
`);
