import React, { FC } from 'react';
import dayjs from 'dayjs';

import { Animation } from '../../../gql/generated/graphql';
import config from '../../../lib/config';
import JsonLd from '../../components/jsonLd';

type AnimationJsonLdProps = {
  animation: Animation;
};

export const AnimationJsonLd: FC<AnimationJsonLdProps> = ({ animation }) => {
  const isVideo = animation.sequence.length > 1;

  const jsonData = {
    '@context': 'https://schema.org',
    '@type': isVideo ? 'VideoObject' : 'ImageObject',
    name: animation.title,
    description: `${animation.title} - an animation by ${
      animation.user.id
    }. Created ${dayjs(animation.dateCreated).fromNow()}.`,
    thumbnailUrl: [
      `${config.s3Path}/${animation.url}/0.png?v=${animation.version}`,
    ],
    uploadDate: dayjs(animation.dateCreated).toISOString(),
    // duration: `PT${duration / 1000}S`, <-- Google doesn't like this
    contentUrl: `${config.s3Path}/${animation.url}/animation.mp4?v=${animation.version}`,
    interactionCount: animation.views,
  };
  return <JsonLd data={jsonData} />;
};
