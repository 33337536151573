import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ButtonBar, Main } from 'layouts';
import { ButtonGroup } from 'layouts/button-group';

import BackButton from 'components/back-button';
import IfUserIs from 'components/if-user-is';
import ThemedHeading from 'components/themed-heading';
import ThemedPage from 'components/themed-page';
import Thread from 'components/thread';

import { THREAD } from '../../../gql/queries/thread';

import CompetitionCountdown from './competition-countdown';
import ThreadAdminControls from './thread-admin-controls';

dayjs.extend(relativeTime);

const ThreadPage = () => {
  const { threadId } = useParams();

  const { loading, data = { thread: { posts: [] } } } = useQuery(THREAD, {
    variables: { id: parseInt(threadId, 10) },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <ThemedPage theme={!loading && data.thread.posts[0].user.theme}>
      <Main theme={!loading && data.thread.posts[0].user.theme}>
        <Helmet>
          <title>{`The Animator - ${data.thread.title}`}</title>
          <meta
            name="description"
            content={`Community thread - ${data.thread.posts.length} posts`}
          />
        </Helmet>
        <ThemedHeading theme={!loading && data.thread.posts[0].user.theme}>
          {data.thread.type === 'COMPETITION'
            ? 'Competition'
            : 'Community Thread'}
        </ThemedHeading>

        <ButtonBar>
          <ButtonGroup>
            <BackButton />
          </ButtonGroup>
          <ButtonGroup align="right">
            <IfUserIs admin>
              <ThreadAdminControls thread={data.thread} />
            </IfUserIs>
          </ButtonGroup>
        </ButtonBar>
        {data.thread.type === 'COMPETITION' && (
          <div className="text-center text-2xl font-bold">
            <CompetitionCountdown endDate={data.thread.endDate} />
          </div>
        )}
        <Thread thread={data.thread} type="NORMAL" />
      </Main>
    </ThemedPage>
  );
};

export default ThreadPage;
