import React from 'react';

import { User } from '../../../gql/generated/graphql';
import { UserPill } from '../user-pill/user-pill';

export const Follows = ({
  users,
  title,
}: {
  title: string;
  users: Array<User>;
}) => {
  if (!users?.length) return null;

  return (
    <div className="text-sm bg-white bg-opacity-50 rounded-md mb-2 shadow-md">
      <div className="bg-white rounded-t-md px-2 font-bold">{title}</div>
      <div className="p-2">
        {users.map((user) => (
          <UserPill user={user} key={user.id} small />
        ))}
      </div>
    </div>
  );
};
