import { Dispatch } from 'react';
import { AnyAction } from 'redux';

import { moveWrapped } from '../../../../lib/animation/canvas-utils';
import { store } from '../../../../store';

import {
  GenericTool,
  GenericToolConstructorArgs,
  ToolEventListenerArgs,
} from './generic';

export class MoveLayerTool extends GenericTool {
  dispatch: Dispatch<AnyAction>;
  active = false;

  constructor(args: GenericToolConstructorArgs) {
    super(args);
    this.dispatch = store.dispatch;
  }

  onStart() {
    this.active = true;
    this.reset();
    this.undoStackPush();
  }

  onMove({ delta }: ToolEventListenerArgs) {
    if (!this.active) return;
    const [dx, dy] = delta;
    const { visibleCtx, originalCtx } = this;
    moveWrapped({
      sourceCtx: originalCtx,
      destinationCtx: visibleCtx,
      moveX: dx,
      moveY: dy,
    });
  }

  onEnd() {
    this.active = false;
    this.commitVisible();
  }
}
