import React from 'react';

import PageHeading from '../components/page-heading';
import ThemedPage from '../components/themed-page';
import { Main, Module } from '../layouts';

const TermsPage = () => {
  return (
    <ThemedPage>
      <Main>
        <PageHeading>Terms &amp; conditions</PageHeading>

        <Module>
          <h2>General Terms</h2>
          <p className="my-2">
            This website is made available to you free of charge. There is no
            warranty or guarantee of availability. From time-to-time, it may be
            necessary to take the website down for essential maintenance.
          </p>
          <p className="my-2">
            The website is made by and copyright{' '}
            <a href="https://madebylantern.co.uk/">Lantern Web Limited</a>.
          </p>
          <p className="my-2">
            When you contribute an animation or image, you retain the copyright.
            You grant this website licence to store and distribute those images
            and animations. This is essential for the website to function.
          </p>
          <p className="my-2">
            The website is hosted in Ireland and managed from the UK and is
            governed by the applicable laws in those territories.
          </p>
        </Module>
        <Module>
          <h2>Acceptable use</h2>
          <p className="my-2">
            All contributions to this website must be suitable for all ages.
            Unsuitable contributions will be hidden or deleted. Deliberately
            contributing unsuitable content may result in a temporary or
            permanent ban from the website.
          </p>
          <p className="my-2">
            Unsuitable contributions include (but are not limited to):
            <ul className="list-disc pl-5">
              <li>Excessive violence, blood and gore</li>
              <li>Sexual or implied sexual content</li>
              <li>Nudity</li>
              <li>
                Swearing, including masked (using e.g. asterisks) and misspelt
                swearing
              </li>
              <li>
                Spam - excessive contribution of little value, or for marketing
                purposes
              </li>
            </ul>
          </p>
        </Module>
        <Module>
          <h2>Age suitability and moderation</h2>
          <p className="my-2">
            In line with the other terms shown on this page, this website should
            be suitable for all ages.
          </p>
          <p className="my-2">
            The website is reactively moderated and relies on its users to
            report unsuitable contributions. If you see anything unsuitable,
            please report it and it will be taken down as soon as possible.
          </p>
        </Module>
        <Module>
          <h2>Bans</h2>
          <p className="my-2">
            If you breach these terms, your account may be temporarily or
            permanently banned. Normally you will be warned first, unless it is
            clear that you are only contributing in order to cause trouble or
            spam the site.
          </p>
          <h3>Temporary bans</h3>
          <p className="my-2">
            If you are temporarily banned, your ability to post in comment
            threads and publish public animations will be revoked. Normally this
            will be a period of one or two days. Longer bans may be used
            depending on the nature of the offences.
          </p>
          <h3>Permanent bans</h3>
          <p className="my-2">
            If you are permanently banned, your account will be frozen and you
            will unable to sign-or contribute.
          </p>
          <p className="my-2">
            If you have multiple associated accounts, those may also be banned.
          </p>
          <p className="my-2">
            Permanent bans are non-negotiable and will not be reversed.
          </p>
        </Module>
      </Main>
    </ThemedPage>
  );
};

export default TermsPage;
