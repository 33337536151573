import { post } from './fetch';

export const getLocalUserData = function () {
  const localUser = window.localStorage.getItem('user');
  return JSON.parse(localUser);
};

export const getUser = function () {
  const localUser = getLocalUserData();
  if (localUser) {
    return Promise.resolve(localUser);
  }

  return Promise.resolve({});
};

export const setUser = function (user) {
  window.localStorage.setItem('user', JSON.stringify(user));
};

export const oauthSignIn = async (userId) => {
  const result = await post('/api/user/oauth-sign-in', { id: userId });
  if (result.status === 'signed-in') {
    return result.user;
  }
  return false;
};

export const oauthSignInLink = async (userId, password) => {
  try {
    const result = await post('/api/user/oauth-sign-in-link', {
      id: userId,
      password,
    });
    if (result.status === 'signed-in') {
      return result;
    }
  } catch (e) {
    return e.response;
  }

  return false;
};

export const oauthRegister = async (userId) => {
  try {
    const result = await post('/api/user/oauth-register', { username: userId });
    if (result.status === 'signed-in') {
      return result;
    }
  } catch (e) {
    return e.response;
  }
};

export const changeUsername = async (username) => {
  try {
    const result = await post('/api/user/change-name', { username });
    if (result.status === 'signed-in') {
      return result;
    }
  } catch (e) {
    return e.response;
  }
};
