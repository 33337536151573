import React, { FC, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { v1 as uuidv1 } from 'uuid';

import animationActions from '../../../lib/animation/animation-actions';
import {
  AnimationPixelMode,
  AnimationState,
} from '../../../lib/animation/animation-default-state';
import { useAppDispatch } from '../../../store';
import { getCanvasPane } from '../canvas/lib/canvas-bridge';
import editorActions from '../editor-actions';
import { EditorState, EditorTool } from '../editor-reducer';
import { editorSelector } from '../selectors/editor-selector';

import { FrameLayers } from './frame-layers';
import { OnionSkinLayer } from './onion-skin-layer';

type CanvasPaneProps = {
  layerVisibility: EditorState['layerVisibility'];
  currentLayerIndex: number;
  sequence: AnimationState['sequence'];
  currentFrameIndex: number;
  width: number;
  height: number;
  currentTool: EditorTool;
  pixelMode: AnimationPixelMode;
  cancelPendingAutosave: () => void;
};

const CanvasPane: FC<CanvasPaneProps> = ({
  layerVisibility,
  currentLayerIndex,
  sequence,
  currentFrameIndex,
  width,
  height,
  currentTool,
  pixelMode,
  cancelPendingAutosave,
}) => {
  const dispatch = useAppDispatch();
  const { showGrid, showOnionSkins } = useSelector(editorSelector);
  const canvasPaneRef = useRef<HTMLDivElement>(document.createElement('div'));
  const layerIndexes = Array.from(layerVisibility.keys());
  const currentFrameLayer =
    sequence[currentFrameIndex].layers[currentLayerIndex];

  const lowerLayerIndexes = layerIndexes
    .slice(0, currentLayerIndex)
    .filter((layerIndex) => layerVisibility[layerIndex]);

  const upperLayerIndexes = layerIndexes
    .slice(currentLayerIndex + 1)
    .filter((layerIndex) => layerVisibility[layerIndex]);

  const onUndoStackPushCallback = () => {
    dispatch(editorActions.undoStackPush());
  };

  const onCanvasChangedCallback = (canvas: HTMLCanvasElement) => {
    dispatch(animationActions.updateCurrentFrame(canvas, uuidv1()));
  };

  const onInteractionStartedCallback = () => {
    cancelPendingAutosave();
  };

  useEffect(() => {
    getCanvasPane().bindEventListeners({
      canvasPaneElement: canvasPaneRef.current,
      currentTool: currentTool,
      onUndoStackPush: onUndoStackPushCallback,
      onCanvasChanged: onCanvasChangedCallback,
      onInteractionStarted: onInteractionStartedCallback,
    });
    getCanvasPane().zoomToFit();
    return function cleanup() {
      getCanvasPane().unbindEventListeners();
    };
  }, []);

  useEffect(() => {
    getCanvasPane().setTool(currentTool);
  }, [currentTool]);

  useEffect(() => {
    getCanvasPane().updateImage(currentFrameLayer.image.getData());
  }, [currentFrameIndex, currentFrameLayer.image.id]);

  return (
    <div
      className="editor-canvas-pane"
      style={{
        touchAction: 'none',
        userSelect: 'none',
        WebkitUserSelect: 'none',
      }}
    >
      <div
        className="overflow-auto w-full h-full relative select-none"
        ref={canvasPaneRef}
      >
        <div className="editor-canvas-pane-scroll-area absolute top-0 left-0 flex items-center justify-center min-w-full min-h-full">
          <div
            className="editor-canvas-container absolute"
            style={{
              width: `${width}px`,
              height: `${height}px`,
              touchAction: 'none',
              background: showGrid
                ? 'repeating-conic-gradient(#eee 0% 25%, white 0% 50%) 50% / 2px 2px'
                : 'white',
              imageRendering:
                pixelMode === AnimationPixelMode.PIXELATED
                  ? 'pixelated'
                  : 'auto',
            }}
          >
            <div className="onion-skin-container">
              <FrameLayers
                frame={sequence[currentFrameIndex]}
                width={width}
                height={height}
                layerIndexes={lowerLayerIndexes}
              />
              {showOnionSkins && (
                <OnionSkinLayer
                  sequence={sequence}
                  currentFrameIndex={currentFrameIndex}
                  currentLayerIndex={currentLayerIndex}
                  width={width}
                  height={height}
                />
              )}
            </div>
            <canvas
              className={`canvas-${currentTool} select-none`}
              onContextMenu={(e) => e.preventDefault()}
              width={width}
              height={height}
              style={{
                transform: 'scale(0.2)',
                width: width * 5,
                height: height * 5,
                transformOrigin: 'top left',
                WebkitUserSelect: 'none',
              }}
            />
            {showOnionSkins && (
              <div className="onion-skin-container pointer-events-none">
                <FrameLayers
                  frame={sequence[currentFrameIndex]}
                  width={width}
                  height={height}
                  layerIndexes={upperLayerIndexes}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CanvasPane;
