import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useModal } from '../../../modal/use-modal';
import { getCanvasPane } from '../canvas/lib/canvas-bridge';
import editorActions from '../editor-actions';
import EditorButton from '../editor-button';
import { animationSelector } from '../selectors/animation-selector';
import { editorSelector } from '../selectors/editor-selector';
import SliderControl from '../slider-control';

const Toolbar = () => {
  const dispatch = useDispatch();
  const animation = useSelector(animationSelector);
  const editor = useSelector(editorSelector);
  const history = useHistory();
  const { showModal } = useModal();

  const { currentTool, undoStack, currentBrushSize, opacity, mode, redoStack } =
    editor;

  const onButtonPressed = (type) => {
    dispatch(editorActions.onToolChanged(type));
  };

  const onZoomOutPressed = () => {
    getCanvasPane().zoomOut();
  };
  const onZoomInPressed = () => {
    getCanvasPane().zoomIn();
  };
  const onResetZoomPressed = () => {
    getCanvasPane().zoomToFit();
  };

  const onSavePressed = () => {
    if (!animation.url) {
      return showModal('SaveAnimationModal');
    }
    dispatch(editorActions.save({ history }));
  };

  const className = `editor-toolbar ${
    mode !== 'playback' ? '' : 'editor-toolbar--hidden'
  }`;

  const currentColour = animation.palette[editor.currentColourIndex];

  return (
    <ol className={className}>
      <li>
        <ol className="editor-toolbar__group">
          <li className="editor-toolbar__item">
            <EditorButton
              label={<i className="fas fa-search-minus" />}
              onButtonPressed={onZoomOutPressed}
            />
          </li>
          <li className="editor-toolbar__item">
            <EditorButton
              label={<i className="fas fa-expand" />}
              onButtonPressed={onResetZoomPressed}
            />
          </li>
          <li className="editor-toolbar__item">
            <EditorButton
              label={<i className="fas fa-search-plus" />}
              onButtonPressed={onZoomInPressed}
            />
          </li>
        </ol>
      </li>
      <li>
        <ol className="editor-toolbar__group">
          <li className="editor-toolbar__item">
            <EditorButton
              type="pencil"
              onButtonPressed={onButtonPressed}
              label={<i className="fas fa-pencil-alt fa-fw" />}
              active={currentTool === 'pencil'}
            />
          </li>
          <li className="editor-toolbar__item">
            <EditorButton
              type="paint"
              onButtonPressed={onButtonPressed}
              label={<i className="fas fa-paint-brush fa-fw" />}
              active={currentTool === 'paint'}
            />
          </li>
          <li className="editor-toolbar__item">
            <EditorButton
              type="fill"
              onButtonPressed={onButtonPressed}
              label={<i className="fas fa-fill-drip fa-fw" />}
              active={currentTool === 'fill'}
            />
          </li>
          <li className="editor-toolbar__item">
            <EditorButton
              type="line"
              onButtonPressed={onButtonPressed}
              label={<strong>|</strong>}
              active={currentTool === 'line'}
            />
          </li>
          <li className="editor-toolbar__item">
            <EditorButton
              type="circle"
              onButtonPressed={onButtonPressed}
              label={<i className="fa fa-circle fa-fw" />}
              active={currentTool === 'circle'}
            />
          </li>
          <li className="editor-toolbar__item">
            <EditorButton
              type="rectangle"
              onButtonPressed={onButtonPressed}
              label={<i className="fas fa-square-full fa-fw" />}
              active={currentTool === 'rectangle'}
            />
          </li>
          <li className="editor-toolbar__item">
            <EditorButton
              type="erase"
              onButtonPressed={onButtonPressed}
              label={<i className="fas fa-eraser fa-fw" />}
              active={currentTool === 'erase'}
            />
          </li>
          <li className="editor-toolbar__item">
            <EditorButton
              type="moveLayer"
              onButtonPressed={onButtonPressed}
              label={<i className="fas fa-arrows-alt" />}
              active={currentTool === 'moveLayer'}
            />
          </li>
          <li className="editor-toolbar__item">
            <EditorButton
              onButtonPressed={() => showModal('AnibotModal')}
              label={<i className="fas fa-robot" />}
            />
          </li>
        </ol>
      </li>
      <li className="w-full">
        <ol className="editor-toolbar__group">
          <li className="flex flex-row items-center">
            <span className="icon icon--brush-small m-2">
              <i className="fas fa-circle fa-xs" />
            </span>
            <SliderControl
              min={1}
              max={30}
              decimals={0}
              onChange={(size) =>
                dispatch(editorActions.onBrushSizeChanged(size))
              }
              defaultValue={currentBrushSize}
            />
            <span className="icon icon--brush-large m-2">
              <i className="fas fa-circle fa-lg" />
            </span>
          </li>
        </ol>
        <ol className="editor-toolbar__group">
          <li className="flex flex-row items-center">
            <span className="icon editor-toolbar-icon__transparent m-2"></span>
            <SliderControl
              min={0}
              max={1}
              decimals={2}
              onChange={(opacity) =>
                dispatch(editorActions.onOpacityChanged(opacity))
              }
              defaultValue={opacity}
            />
            <span
              className="icon editor-toolbar-icon__solid m-2"
              style={{ background: currentColour }}
            ></span>
          </li>
        </ol>
      </li>
      <li>
        <ol className="editor-toolbar__group">
          <li className="editor-toolbar__item">
            <EditorButton
              type="undo"
              onButtonPressed={() => dispatch(editorActions.undo())}
              label={<i className="fas fa-undo fa-fw" />}
              transient={true}
              disabled={undoStack.length === 0}
            />
          </li>
          <li className="editor-toolbar__item">
            <EditorButton
              type="redo"
              onButtonPressed={() => dispatch(editorActions.redo())}
              label={<i className="fas fa-redo fa-fw" />}
              transient={true}
              disabled={redoStack.length === 0}
            />
          </li>
          <li className="editor-toolbar__item">
            <EditorButton
              type="save"
              onButtonPressed={onSavePressed}
              label={<i className="fas fa-cloud-upload-alt fa-fw" />}
              transient={true}
            />
          </li>
        </ol>
      </li>
    </ol>
  );
};

export default Toolbar;
