import { selectToolState } from '../lib/tool-selectors';

import { GenericTool, ToolEventListenerArgs } from './generic';

type RGBA = {
  r: number;
  g: number;
  b: number;
  a: number;
};

function matchStartColor(
  colorLayer: ImageData,
  pixelPos: number,
  originalColour: RGBA
) {
  const r = colorLayer.data[pixelPos];
  const g = colorLayer.data[pixelPos + 1];
  const b = colorLayer.data[pixelPos + 2];
  const a = colorLayer.data[pixelPos + 3];

  return (
    r === originalColour.r &&
    g === originalColour.g &&
    b === originalColour.b &&
    a === originalColour.a
  );
}

export class FillTool extends GenericTool {
  colorLayer: ImageData;

  colorPixel(
    pixelPos: number,
    fillColorR: number,
    fillColorG: number,
    fillColorB: number,
    opacity: number
  ) {
    this.colorLayer.data[pixelPos] = fillColorR;
    this.colorLayer.data[pixelPos + 1] = fillColorG;
    this.colorLayer.data[pixelPos + 2] = fillColorB;
    this.colorLayer.data[pixelPos + 3] = opacity;
  }

  onTap({ coords }: ToolEventListenerArgs) {
    const { opacity: fractionalOpacity, colour } = selectToolState();
    const opacity = fractionalOpacity * 255;
    const [x, y] = coords;

    this.reset();
    this.undoStackPush();

    const canvasWidth = this.visibleCtx.canvas.width;
    const canvasHeight = this.visibleCtx.canvas.height;
    this.colorLayer = this.visibleCtx.getImageData(
      0,
      0,
      canvasWidth,
      canvasHeight
    );

    const pixelStack = [[x, y]];

    const linearCoords = (y * canvasWidth + x) * 4;

    const originalColour = {
      r: this.colorLayer.data[linearCoords],
      g: this.colorLayer.data[linearCoords + 1],
      b: this.colorLayer.data[linearCoords + 2],
      a: this.colorLayer.data[linearCoords + 3],
    };

    const fillColorR = parseInt(colour.slice(1, 3), 16);
    const fillColorG = parseInt(colour.slice(3, 5), 16);
    const fillColorB = parseInt(colour.slice(5), 16);

    if (
      originalColour.r === fillColorR &&
      originalColour.g === fillColorG &&
      originalColour.b === fillColorB &&
      originalColour.a === opacity
    ) {
      return;
    }

    while (pixelStack.length) {
      let pixelPos, reachLeft, reachRight;
      const newPos = pixelStack.pop();
      // eslint-disable-next-line prefer-const
      let [x, y] = newPos;

      pixelPos = (y * canvasWidth + x) * 4;
      while (
        y-- >= 0 &&
        matchStartColor(this.colorLayer, pixelPos, originalColour)
      ) {
        pixelPos -= canvasWidth * 4;
      }
      pixelPos += canvasWidth * 4;
      ++y;
      reachLeft = false;
      reachRight = false;
      while (
        y++ < canvasHeight - 1 &&
        matchStartColor(this.colorLayer, pixelPos, originalColour)
      ) {
        this.colorPixel(pixelPos, fillColorR, fillColorG, fillColorB, opacity);

        if (x > 0) {
          if (matchStartColor(this.colorLayer, pixelPos - 4, originalColour)) {
            if (!reachLeft) {
              pixelStack.push([x - 1, y]);
              reachLeft = true;
            }
          } else if (reachLeft) {
            reachLeft = false;
          }
        }

        if (x < canvasWidth - 1) {
          if (matchStartColor(this.colorLayer, pixelPos + 4, originalColour)) {
            if (!reachRight) {
              pixelStack.push([x + 1, y]);
              reachRight = true;
            }
          } else if (reachRight) {
            reachRight = false;
          }
        }

        pixelPos += canvasWidth * 4;
      }
    }
    this.visibleCtx.putImageData(this.colorLayer, 0, 0);
    this.commitVisible();
  }
}
