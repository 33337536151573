import React, { MouseEvent, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import Button from '../../components/button';
import { TextInput } from '../../components/forms';
import PageHeading from '../../components/page-heading';
import ThemedPage from '../../components/themed-page';
import UserError from '../../components/user-error';
import { ButtonBar, Main, Module } from '../../layouts';
import { ButtonGroup } from '../../layouts/button-group';
import { clearErrorsAction } from '../../lib/error/error-actions';
import { errorSelector } from '../../lib/error/error-selector';
import { useAppDispatch } from '../../store';

import * as passwordResetActions from './password-reset-actions';

const RequestPasswordResetEmailForm = () => {
  const inputEmailRef = useRef<HTMLInputElement>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const errorState = useSelector(errorSelector);

  const onSendResetClicked = (e: MouseEvent<HTMLButtonElement>) => {
    dispatch(
      passwordResetActions.sendPasswordReset(
        inputEmailRef.current.value,
        history
      )
    );
    e.preventDefault();
  };

  useEffect(() => {
    inputEmailRef.current.focus();
    return function cleanup() {
      dispatch(clearErrorsAction());
    };
  });

  const errors = errorState.PASSWORD_RESET_FAILED ?? [];

  return (
    <ThemedPage>
      <Main small>
        <PageHeading>Reset your password</PageHeading>
        <Module>
          <p>
            If you have forgotten your password, we can send you an email to set
            a new password. Enter your email address below.{' '}
            <Link to="/sign-in">Return to sign-in</Link>.
          </p>
        </Module>
        {errors ? <UserError errors={errors} /> : null}
        <form>
          <Module>
            <TextInput
              id="email"
              type="email"
              ref={inputEmailRef}
              autoCapitalize="none"
              label="Email address:"
            />
          </Module>
          <ButtonBar>
            <ButtonGroup align="right">
              <Button
                icon="fas fa-envelope"
                onClick={onSendResetClicked}
                rightAlign
                data-testid="request-reset-button"
              >
                Send password reset email
              </Button>
            </ButtonGroup>
          </ButtonBar>
        </form>
      </Main>
    </ThemedPage>
  );
};

export default RequestPasswordResetEmailForm;
