import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { AnimationType } from '../../../gql/generated/graphql';
import { MUTATE_ANIMATION } from '../../../gql/queries/mutate-animation';
import { TextInput, UserError } from '../../components';
import Button from '../../components/button';
import { ButtonBar, Module } from '../../layouts';
import { ButtonGroup } from '../../layouts/button-group';
import { hideModal } from '../../lib/modal/modal-actions';
import { useAppDispatch } from '../../store';

import ModalTitle from './modal-title';

const CreateComicModal = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [mutateCreateAnimation] = useMutation(MUTATE_ANIMATION, {
    onCompleted: (data) => {
      if (data.animation.url) {
        history.push(`/comic/${data.animation.url}`);
      }
    },
  });

  const [error, setError] = useState(null);
  const titleRef = useRef<HTMLInputElement>();

  const onCancelClicked = () => {
    dispatch(hideModal());
  };

  const onCreateClicked = () => {
    const title = titleRef.current.value;

    mutateCreateAnimation({
      variables: {
        title,
        type: AnimationType.Comic,
        public: false,
      },
    });

    if (!title.trim()) {
      return setError(['Please enter a comic title']);
    }
  };

  return (
    <div>
      <ModalTitle>Create Comic</ModalTitle>
      <Module className="text-center">
        <p>
          A comic groups your animations together on one page to tell a story.
        </p>
        {error && <UserError errors={error} />}
      </Module>
      <Module className="">
        <div>
          <TextInput label="Comic title" ref={titleRef} />
        </div>
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-times-circle"
            onClick={onCancelClicked}
            type="negative"
          >
            Cancel
          </Button>
          <Button
            icon="fas fa-plus-circle"
            onClick={onCreateClicked}
            type="positive"
          >
            Create Comic
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
};

export default CreateComicModal;
