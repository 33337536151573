import { graphql } from '../../generated';

export const MUTATE_COMIC_FRAME = graphql(`
  mutation MutateComicFrame(
    $action: ComicFrameAction
    $frameId: Int
    $value: Int
  ) {
    mutateComicFrame(action: $action, frameId: $frameId, value: $value) {
      id
      columns
      frames {
        id
        animation {
          ...CoreAnimationFields
        }
        sequence
        rowSpan
        colSpan
      }
    }
  }
`);
