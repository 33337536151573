import React, { ChangeEvent, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

import { AnimationType } from '../../../gql/generated/graphql';
import animationActions from '../../lib/animation/animation-actions';
import NotFound from '../../pages/not-found';
import { useAppDispatch } from '../../store';
import PublicGallerySwitch from '../public-gallery-switch';

import FramePicker from './frame-picker/frame-picker';
import LocalSaveStatus from './local-save-status/local-save-status';
import { animationSelector } from './selectors/animation-selector';
import { editorSelector } from './selectors/editor-selector';
import CanvasPane from './canvas-pane';
import editorActions from './editor-actions';
import EditorFileMenu from './editor-file-menu';
import { useKeyboardShortcuts } from './editor-keyboard-hooks';
import EditorLoading from './editor-loading';
import EditorViewMenu from './editor-view-menu';
import Palette from './palette';
import Timeline from './timeline';
import Toolbar from './toolbar';
import { useAutosave } from './use-autosave';
import { BOOTSTRAP_ERRORS, useEditorBootstrap } from './use-editor-bootstrap';

import { backgroundGradient } from '../../lib/css';

const Editor = () => {
  const dispatch = useAppDispatch();

  const playbackTimer = useRef<ReturnType<typeof setTimeout>>();

  const editor = useSelector(editorSelector);
  const animation = useSelector(animationSelector);

  useKeyboardShortcuts();

  const { loading, error } = useEditorBootstrap();

  const cancelPendingAutosave = useAutosave({
    enabled: !loading,
  });

  useEffect(() => {
    if (editor.mode !== 'playback') {
      clearTimeout(playbackTimer.current);
      return;
    }

    let nextSequenceIndex = editor.currentFrameIndex + 1;
    if (nextSequenceIndex === animation.sequence.length) {
      nextSequenceIndex = 0;
    }

    playbackTimer.current = setTimeout(() => {
      dispatch(editorActions.goToFrame(nextSequenceIndex));
    }, animation.sequence[editor.currentFrameIndex].delay);

    return function cleanup() {
      clearTimeout(playbackTimer.current);
    };
  }, [editor.currentFrameIndex, editor.mode]);

  const onAnimationTitleChanged = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(animationActions.setAnimationTitle(event.target.value));
  };

  if (loading) return <EditorLoading />;
  if (error === BOOTSTRAP_ERRORS.NOT_FOUND) {
    return <NotFound />;
  } else if (error) {
    return <p>Error</p>;
  }

  return (
    <div
      className="editor"
      style={backgroundGradient(
        animation.palette[editor.currentColourIndex],
        animation.palette[editor.currentColourIndex]
      )}
    >
      <Helmet>
        <title>The Animator - Editor</title>
        <meta
          name="description"
          content="Create and share your own hand-drawn animations with this easy, fun and free to use animation editor."
        />
      </Helmet>
      <div className="editor-options-bar flex items-center justify-between px-2">
        <EditorFileMenu />
        <EditorViewMenu />

        <div className="flex items-center justify-center flex-auto">
          <input
            className="text-sm rounded-md w-40 shadow-inner p-1 border border-gray-400"
            type="text"
            value={animation.title}
            onChange={onAnimationTitleChanged}
            maxLength={50}
          />
          {animation.type === AnimationType.Standalone ? (
            <PublicGallerySwitch
              isPublic={animation.public}
              onAnimationPublicToggled={() => {
                dispatch(
                  animationActions.setAnimationPublic(!animation.public)
                );
              }}
            />
          ) : (
            <span className="ml-1">Profile picture</span>
          )}
          <LocalSaveStatus />
        </div>
      </div>

      <div className="editor-toolbar-container select-none">
        <Toolbar key="toolbar" />,
        <Palette
          key="palette"
          visible={editor.mode !== 'playback' && editor.currentTool !== 'erase'}
        />
      </div>

      {animation.sequence[editor.currentFrameIndex] && (
        <CanvasPane
          layerVisibility={editor.layerVisibility}
          currentLayerIndex={editor.currentLayerIndex}
          sequence={animation.sequence}
          currentFrameIndex={editor.currentFrameIndex}
          width={animation.width}
          height={animation.height}
          currentTool={editor.currentTool}
          cancelPendingAutosave={cancelPendingAutosave}
          pixelMode={animation.pixelMode}
        />
      )}

      <FramePicker />

      <div className="editor-bottom select-none">
        <Timeline />
      </div>
    </div>
  );
};

export default Editor;
