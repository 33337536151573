import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  Animation,
  AnimationType,
  PixelMode,
  User,
} from '../../../gql/generated/graphql';
import config from '../../../lib/config';
import { UserPill } from '../../components/user-pill/user-pill';
import { useDetectSsr } from '../../hooks/use-detect-ssr';
import { showModal } from '../../lib/modal/modal-actions';
import Reactions from '../reactions';

const MAX_STACK_SIZE = 2;

type AnimationListItemProps = {
  animation: Animation;
  showPublic: boolean;
  user?: User;
  selectMode: boolean;
  selected: boolean;
  onAnimationSelected: (id: Animation['id']) => void;
};

export const AnimationListItem: FC<AnimationListItemProps> = ({
  animation,
  showPublic,
  user,
  selectMode,
  selected,
  onAnimationSelected,
}) => {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const ssrMode = useDetectSsr();
  const imgRef = useRef(null);
  const userData = user ?? animation.user;

  const { dailyAnimationCount } = animation;

  const getThumbnailPath = () => {
    if (!animation.url) return null;

    if (animation.altThumbnail) {
      return `${config.s3Path}${animation.altThumbnail}`;
    }

    return animation.schemaVersion > 1
      ? `${config.s3Path}/${animation.url}/thumb.png?v=${animation.version}`
      : `${config.s3Path}/${animation.url}/0.png?v=${animation.version}`;
  };

  const thumbPath = getThumbnailPath();

  useEffect(() => {
    if (imgRef.current && imgRef.current.naturalWidth > 0) {
      setLoaded(true);
    }
  });

  const showEncoding = animation.url && !animation.encoded;
  const showSpinner = !ssrMode && !loaded && !showEncoding;

  const renderStack = (stackSize) => {
    return [
      ...Array(stackSize < MAX_STACK_SIZE ? stackSize : MAX_STACK_SIZE).keys(),
    ].map((i) => <div className="animation-item__stack" key={i} />);
  };

  const onOpenDailyAnimations = (e, animation) => {
    e.preventDefault();
    dispatch(
      showModal('DailyAnimationsModal', {
        username: animation.user.name,
        date: animation.dateUpdated,
      })
    );
  };

  const clickHandler = (e) => {
    if (dailyAnimationCount > 1) {
      onOpenDailyAnimations(e, animation);
    } else if (selectMode) {
      e.preventDefault();
      onAnimationSelected(animation.id);
    }
  };

  const pagePart =
    animation.type === AnimationType.Standalone ? 'animation' : 'comic';

  return (
    <li
      className={`animation-list__item ${
        selected ? 'animation-list__item--selected' : ''
      }`}
    >
      {dailyAnimationCount > 1 ? renderStack(dailyAnimationCount - 1) : null}
      <div className="animation-list__item-inner">
        <Link
          to={animation.url ? `/${pagePart}/${animation.url}` : '#'}
          className="animation-list__item-link hover:no-underline"
          onClick={clickHandler}
        >
          {thumbPath && animation.encoded && (
            <img
              className={`animation-item__image ${showSpinner ? 'hidden' : ''}`}
              alt={animation.title}
              src={thumbPath}
              onLoad={() => setLoaded(true)}
              ref={imgRef}
              style={{
                imageRendering:
                  animation.pixelMode === PixelMode.Pixelated
                    ? 'pixelated'
                    : 'auto',
              }}
            />
          )}

          {showSpinner && (
            <div className="animation-list__placeholder">
              <i className="fas fa-spin fa-spinner" />
            </div>
          )}

          {showEncoding && (
            <div className="animation-item__encoding text-center text-lg absolute inset-x-0 bottom-0 mb-8 text-gray-400">
              <i className="fas fa-cog fa-spin"></i>
              <div className="animation-item__encoding-text">Encoding...</div>
            </div>
          )}
        </Link>
        <div className="animation-item__title-bar text-sm p-1">
          <div>
            <div>
              <Link
                to={`/${pagePart}/${animation.url}`}
                className="animation-list__item-link font-bold"
                onClick={clickHandler}
              >
                {selected && (
                  <i className="fas fa-check-circle animation-list__item-selected-icon text-blue-500 mr-1" />
                )}
                {animation.title}{' '}
                {dailyAnimationCount > 1 ? (
                  <span className="text-sm shadow-md inline-block py-0.5 px-2 leading-none text-center whitespace-nowrap align-baseline rounded-full bg-gray-400 text-white">
                    x{`${dailyAnimationCount}`}
                  </span>
                ) : null}
              </Link>
            </div>
            <div className="text-xs mt-0.5 mb-0.5">
              <Reactions
                reactions={animation.reactions}
                comments={animation.commentCount}
              />
            </div>
            {showPublic && animation.public ? (
              <span className="text-xs shadow-md inline-block py-0.5 px-2 leading-none text-center whitespace-nowrap align-baseline rounded-full bg-orange-400 text-black mr-1">
                <i className="fas fa-globe-europe" /> In Gallery
              </span>
            ) : null}
            {animation.pixelMode === PixelMode.Pixelated ? (
              <span className="text-xs shadow-md inline-block py-0.5 px-2 leading-none text-center whitespace-nowrap align-baseline rounded-full bg-blue-200 text-black mr-1">
                <i className="fas fa-th-large"></i> Pixel Art
              </span>
            ) : null}
            {animation.type === AnimationType.Comic ? (
              <span className="text-xs shadow-md inline-block py-0.5 px-2 leading-none text-center whitespace-nowrap align-baseline rounded-full bg-green-200 text-black mr-1">
                <i className="fas fa-book-open"></i> Comic
              </span>
            ) : null}
          </div>
        </div>
        <div className="text-sm absolute bottom-0 left-0 m-1">
          <UserPill user={userData} />
        </div>
      </div>
    </li>
  );
};
