import React, { FC, useState } from 'react';

import type {
  Animation,
  Thread as ThreadType,
} from '../../../gql/generated/graphql';
import { Module } from '../../layouts';
import Button from '../button';
import IfUserIs from '../if-user-is';
import PostEditor from '../post-editor';

import ThreadCompetitionEntries from './thread-competition-entries';
import { ThreadPosts } from './thread-posts';

const MAX_POSTS_VISIBLE = 20;

type ThreadProps = {
  thread: ThreadType;
  type: string;
  animation: Animation;
};

const Thread: FC<ThreadProps> = (props) => {
  const [revealedPosts, setRevealedPosts] = useState(MAX_POSTS_VISIBLE);

  const onShowMoreClicked = () => {
    setRevealedPosts(revealedPosts + MAX_POSTS_VISIBLE);
  };

  const onShowAllClicked = () => {
    setRevealedPosts(props.thread.posts.length);
  };

  const posts = [...props.thread.posts] || [];
  const firstPost = posts.shift();
  const visiblePosts = posts.slice(revealedPosts * -1);
  const hasUnrevealedPosts = visiblePosts.length < posts.length;
  const unrevealedPostsCount = posts.length - visiblePosts.length;

  return (
    <div>
      <Module>
        {props.thread.title && (
          <h3 className="thread-title shadow-md">
            {props.thread.title}
            {props.thread.locked ? <i className="fas fa-lock lock-icon" /> : ''}
            {props.thread.pinned ? (
              <i className="fas fa-thumbtack pinned-icon" />
            ) : null}
          </h3>
        )}
      </Module>
      <Module>
        {firstPost && (
          <ThreadPosts posts={[firstPost]} threadUserId={props.thread.userId} />
        )}
        {props.thread.type === 'COMPETITION' && (
          <ThreadCompetitionEntries thread={props.thread} />
        )}
        {hasUnrevealedPosts ? (
          <p className="my-4">
            {unrevealedPostsCount} older comment
            {unrevealedPostsCount > 1 ? 's' : ''}{' '}
            <Button icon="fas fa-angle-up" onClick={onShowMoreClicked}>
              show more
            </Button>
            &nbsp;
            <Button icon="fas fa-angle-double-up" onClick={onShowAllClicked}>
              show all
            </Button>
          </p>
        ) : null}
        <ThreadPosts
          posts={visiblePosts}
          threadUserId={props.thread.userId}
          threadType={props.thread.type}
        />
        <IfUserIs username={props.thread.userId}>
          <p>
            This thread belongs to you. You can hide any posts you don&apos;t
            like. If you hide a post, it will be hidden from everyone.
          </p>
        </IfUserIs>
      </Module>
      {props.thread.locked ? (
        <Module>This thread has been locked. No replies can be made.</Module>
      ) : (
        <PostEditor
          thread={props.thread}
          animation={props.animation}
          type={props.type}
        />
      )}
    </div>
  );
};

export default Thread;
