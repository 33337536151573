import { graphql } from '../generated';

export const MUTATE_USER = graphql(`
  mutation MutateUser(
    $id: String
    $name: String
    $banned: Boolean
    $badge: String
    $badgeExpires: Date
    $theme: UserThemeInput
    $email: String
    $avatarUrl: String
  ) {
    user(
      id: $id
      name: $name
      banned: $banned
      badge: $badge
      badgeExpires: $badgeExpires
      theme: $theme
      email: $email
      avatarUrl: $avatarUrl
    ) {
      id
      banned
      badge
      badgeExpires
      email
      avatarUrl
      theme {
        bg1
        bg2
        post
        username
      }
    }
  }
`);
