import { graphql } from '../generated';

export const MUTATE_REACTION = graphql(`
  mutation MutateReaction(
    $animationId: Int
    $action: ReactionAction
    $type: String
  ) {
    reaction(animationId: $animationId, action: $action, type: $type) {
      id
      reactions {
        id
        laugh
        dizzy
        stars
        love
      }
      reactionsBreakdown {
        id
        type
        userId
      }
    }
  }
`);
