import React, { useEffect, useRef } from 'react';

import config from '../../../lib/config';
import { usePollForProperty } from '../../hooks/use-poll-for-property';

export const GoogleButton = () => {
  const googleButtonRef = useRef();
  const buttonAvailable = usePollForProperty(
    typeof window !== 'undefined' && window.google
  );

  useEffect(() => {
    if (!buttonAvailable) return;
    window.google.accounts.id.initialize({
      client_id:
        '1043317875937-h6uqq8qoe7j2mv60usreh0ft1v9a34ic.apps.googleusercontent.com',
      login_uri: config.googleAuthRedirectUrl,
      ux_mode: 'redirect',
    });
    window.google.accounts.id.renderButton(
      googleButtonRef.current,
      { theme: 'outline', size: 'large' } // customization attributes
    );
  }, [buttonAvailable]);

  return <div ref={googleButtonRef}></div>;
};
