import { store } from '../../../../store';

export const selectToolState = () => {
  const state = store.getState();
  return {
    size: Math.round(state.editor.currentBrushSize),
    colour: state.animation.palette[state.editor.currentColourIndex],
    opacity: state.editor.opacity,
  };
};
