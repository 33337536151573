import { showModal } from '../modal/modal-actions';
import { post, put } from '../services/fetch';
import {
  changeUsername,
  oauthRegister,
  oauthSignIn,
  oauthSignInLink,
} from '../services/user';

export const userLoaded = function (user) {
  if (typeof window !== 'undefined')
    window.localStorage.setItem('clientId', user.clientId);

  return {
    type: 'USER/SET',
    payload: user,
  };
};

export const setUserThemeAction = function (theme) {
  return function (dispatch) {
    return dispatch({
      type: 'USER/SET_THEME',
      payload: theme,
    });
  };
};

export const setUserAvatarAction = function (avatarUrl) {
  return {
    type: 'USER/SET',
    payload: { avatarUrl },
  };
};

export const sendVerificationEmailAction = function () {
  return function (dispatch) {
    post('/api/user/send-verification').then(function () {
      return dispatch(showModal('VerificationEmailSentModal'));
    });
  };
};

export const updateUserAction = function ({ email }) {
  return function (dispatch) {
    return put('/api/user', { email }).then(function () {
      dispatch({ type: 'USER/SET', payload: { email } });
      return dispatch(showModal('VerificationEmailSentModal'));
    });
  };
};

export const banUserAction = function ({ username, banned }) {
  return function (dispatch, getState) {
    const state = getState();
    return put(`/api/user/${username}/ban`, { banned }).then(function () {
      dispatch({
        type: 'STUDIO/SET',
        payload: {
          ...state.studio,
          adminAttributes: {
            ...state.studio.adminAttributes,
            banned,
          },
        },
      });
    });
  };
};

export const adminModifyUserAction = function ({ username, attributes }) {
  return function (dispatch) {
    return put(`/api/admin/user/${username}`, attributes).then(function () {
      dispatch({
        type: 'STUDIO/ADMIN_MODIFY_USER_SUCCESS',
        payload: attributes,
      });
    });
  };
};

export const signOutUser = function () {
  return function (dispatch) {
    post('/api/sign-out').then(function () {
      return dispatch({
        type: 'USER/SIGN_OUT',
        payload: {},
      });
    });
  };
};

export const oauthSignInAction = (userId) => {
  return async (dispatch) => {
    const signedInUser = await oauthSignIn(userId);
    if (signedInUser) {
      dispatch(userLoaded(signedInUser));
      return true;
    }
    return false;
  };
};
export const oauthRegisterAction = (userId) => {
  return async (dispatch) => {
    const signedInUser = await oauthRegister(userId);
    if (signedInUser.status === 'signed-in') {
      dispatch(userLoaded(signedInUser.user));
      return true;
    }
    return signedInUser.payload;
  };
};

export const oauthSignInLinkAction = (userId, password) => {
  return async (dispatch) => {
    const signedInUser = await oauthSignInLink(userId, password);
    if (signedInUser.status === 'signed-in') {
      dispatch(userLoaded(signedInUser.user));
      return true;
    }
    return signedInUser.payload;
  };
};

export const changeUsernameAction = (username) => {
  return async (dispatch) => {
    const changedUser = await changeUsername(username);
    if (changedUser.status === 'signed-in') {
      dispatch(userLoaded(changedUser.user));
      return true;
    }
    return changedUser.payload;
  };
};
