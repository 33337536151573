import { setErrorAction } from '../../lib/error/error-actions';
import { post } from '../../lib/services/fetch';

export const sendPasswordReset = function (email, history) {
  return function (dispatch) {
    return post('/api/request-password-reset', { email })
      .then(function () {
        history.push('/password-reset-sent');
      })
      .catch(function () {
        return dispatch(
          setErrorAction(
            'PASSWORD_RESET_FAILED',
            'Failed to send password reset email. Please try again later.'
          )
        );
      });
  };
};
