import { graphql } from '../generated';

export const MUTATE_CREATE_POST = graphql(`
  mutation MutateCreatePost(
    $threadId: Int
    $text: String
    $title: String
    $animationId: Int
    $lock: Boolean
    $type: String
    $endDate: Date
  ) {
    createPost(
      threadId: $threadId
      text: $text
      title: $title
      animationId: $animationId
      lock: $lock
      type: $type
      endDate: $endDate
    ) {
      id
      userId
      title
      type
      createdAt
      updatedAt
      locked
      pinned
      animationId
      posts {
        id
        threadId
        createdAt
        user {
          id
          theme {
            post
          }
        }
      }
    }
  }
`);
