import React from 'react';
import { useHistory } from 'react-router-dom';

import { AnimationType } from '../../../gql/generated/graphql';
import Button from '../../components/button';
import editorActions from '../../components/editor/editor-actions';
import { ButtonBar, Module } from '../../layouts';
import { ButtonGroup } from '../../layouts/button-group';
import { AnimationPixelMode } from '../../lib/animation/animation-default-state';
import { useAppDispatch } from '../../store';
import { useModal } from '../use-modal';

import ModalTitle from './modal-title';

export default function ConfirmCreateAvatarModal() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { hideModal } = useModal();

  const onCreateClicked = async () => {
    await dispatch(
      editorActions.createNew({
        width: 128,
        height: 128,
        pixelMode: AnimationPixelMode.PIXELATED,
        type: AnimationType.Avatar,
      })
    );
    history.push('/editor');
    hideModal();
  };

  const onCancelClicked = () => {
    hideModal();
  };

  return (
    <div>
      <ModalTitle>Create Profile Picture</ModalTitle>
      <Module>
        <p>This will create a new animation in the editor.</p>
        <p>Any unsaved work will be lost. Do you want to continue?</p>
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-plus-circle"
            onClick={onCreateClicked}
            type="positive"
          >
            Create Profile Picture
          </Button>
          <Button
            icon="fas fa-times-circle"
            onClick={onCancelClicked}
            type="negative"
          >
            Cancel
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
}
