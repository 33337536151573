import produce from 'immer';

export const DEFAULT_THEME = {
  bg1: 'rgb(10, 147, 39)',
  bg2: 'rgb(235, 252, 123)',
};

export type UserState = {
  id: string;
  name: string;
  email: string;
  dateJoined: string;
  theme: Record<string, string>;
  roles: string[];
  clientId: string;
  verified: boolean;
  avatarUrl: string;
};

const defaultState: UserState = {
  id: '',
  name: '',
  email: null,
  dateJoined: null,
  theme: DEFAULT_THEME,
  roles: [],
  clientId: '',
  verified: false,
  avatarUrl: null,
};

export default produce((state, action) => {
  switch (action.type) {
    case 'USER/SET':
      return {
        ...state,
        ...action.payload, // FIXME: sets type to "any"
      };
    case 'USER/SET_THEME': {
      return {
        ...state,
        theme: action.payload,
      };
    }
    case 'USER/SIGN_OUT':
      return {
        id: '',
        email: '',
        dateJoined: '',
        roles: [],
      };
  }
  return state;
}, defaultState);
