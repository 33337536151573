import React from 'react';
import { Transition } from '@headlessui/react';
import PropTypes from 'prop-types';

const ModalInner = (props) => {
  return (
    <Transition.Child
      enter="ease-out duration-300"
      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enterTo="opacity-100 translate-y-0 sm:scale-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      className="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full"
    >
      {props.children}
    </Transition.Child>
  );
};

ModalInner.propTypes = {
  children: PropTypes.node,
};

export default ModalInner;
