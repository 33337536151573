import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import animationActions from '../../lib/animation/animation-actions';
import editorActions from '../editor/editor-actions';

import { animationSelector } from './selectors/animation-selector';
import { EDITOR_DB_SAVE_STATE } from './editor-reducer';

const LOCAL_SAVE_DELAY = 5000;

export const useAutosave = ({ enabled }) => {
  const animation = useSelector(animationSelector);
  const saveTimer = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(editorActions.setDbSaveState(EDITOR_DB_SAVE_STATE.QUEUED));

    if (enabled) {
      saveTimer.current = setTimeout(() => {
        dispatch(animationActions.saveLocally());
      }, LOCAL_SAVE_DELAY);
    }

    return function cleanup() {
      clearTimeout(saveTimer.current);
    };
  }, [animation, enabled]);

  return useCallback(
    function cancelPendingAutosave() {
      dispatch(editorActions.setDbSaveState(EDITOR_DB_SAVE_STATE.QUEUED));
      clearTimeout(saveTimer.current);
    },
    [saveTimer.current]
  );
};
