import React from 'react';

import PageHeading from '../components/page-heading';
import ThemedPage from '../components/themed-page';
import { Main, Module } from '../layouts';

const PrivacyPage = () => {
  return (
    <ThemedPage>
      <Main>
        <PageHeading>Animation For Schools</PageHeading>

        <Module>
          <h2>Using The Animator with your pupils/students</h2>
          <p className="my-2">
            Many schools use The Animator with their classes. You are also
            welcome to - free of charge. As outlined in the{' '}
            <a href="/terms">general terms</a>, the website is provided without
            warranty or guaranteed uptime.
          </p>
          <p className="my-2">
            The website was deliberately designed to be easy to use for
            children, with a limited selection of tools and clear iconography.
            It is intended to allow easy creation of simple flip-book style
            animations. You can find some very impressive creations in the{' '}
            <a href="/gallery">public gallery</a>.
          </p>
          <p className="my-2">
            If you use the website with your classes, please ensure that your IT
            department allows incoming emails from the
            &quot;theanimator.co.uk&quot; domain. When registering, your
            pupils/students will be required to click the verification link
            emailed to them before they are able to contribute animations. If
            emails from the domain are not allowed, the email will bounce and
            your pupil/student will not be able to verify their address and
            contribute.
          </p>
        </Module>
        <Module>
          <h2>Private hosting for your school</h2>
          <p className="my-2">
            For a fee, The Animator can be privately hosted for your school with
            its own database and storage. Privately hosted versions can be
            secured to only allow access from your school. Additionally, a
            privately hosted version can be located in a geographic location of
            your choice to be compliant with local laws.
          </p>
          <p className="my-2">
            If you&apos;d like to discuss private hosting, please{' '}
            <a href="mailto:help@theanimator.co.uk">email us</a> with details of
            your requirements and expected numbers of students for a quote.
          </p>
          <p className="my-2">Quoted costs will be on annual terms in GBP.</p>
        </Module>
      </Main>
    </ThemedPage>
  );
};

export default PrivacyPage;
