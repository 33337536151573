import React from 'react';

import Button from '../../components/button';
import { ButtonBar, Module } from '../../layouts';
import { ButtonGroup } from '../../layouts/button-group';
import { ModalComponentProps } from '../modal';

import ModalTitle from './modal-title';

export default function ReportSubmittedModal(props: ModalComponentProps) {
  return (
    <div className="text-center">
      <ModalTitle>Report Submitted</ModalTitle>
      <Module>
        <p>Thank you for your report.</p>
        <p>
          If the animation is found to be inappropriate, it will be removed from
          the public gallery as soon as possible.
        </p>
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-check-circle"
            onClick={props.hideModal}
            type="positive"
            rightAlign
          >
            Ok
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
}
