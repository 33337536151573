import React from 'react';

import { backgroundGradient } from '../../lib/css';

const DEFAULT_BG1 = 'rgb(10, 147, 39)';
const DEFAULT_BG2 = 'rgb(235, 252, 123)';

const EditorLoading = () => {
  return (
    <div
      className="w-full h-full flex justify-center items-start"
      style={backgroundGradient(DEFAULT_BG1, DEFAULT_BG2)}
    >
      <div className="bg-white text-6xl rounded-lg font-bold my-5 p-5 opacity-40 text-center">
        <div className="text-lg text-center mb-2">Starting editor</div>
        <i className="fas fa-spin fa-spinner" />
      </div>
    </div>
  );
};

export default EditorLoading;
