import React, { Fragment } from 'react';

import { captureError } from '../../../lib/error-logger';
import { Header } from '../../components/header/header';
import ErrorPage from '../error/error';

type RootErrorBoundaryProps = {
  children?: React.ReactNode;
};

type RootErrorBoundaryState = {
  caughtError: null | Error;
};

export class RootErrorBoundary extends React.Component<
  RootErrorBoundaryProps,
  RootErrorBoundaryState
> {
  state: {
    caughtError: null | Error;
  };

  constructor(props: RootErrorBoundaryProps) {
    super(props);
    this.state = { caughtError: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { caughtError: error };
  }

  componentDidCatch(error: Error) {
    captureError(error);
  }

  render() {
    if (this.state.caughtError) {
      return (
        <Fragment>
          <Header />
          <ErrorPage message={this.state.caughtError.message} />
        </Fragment>
      );
    }
    return this.props.children;
  }
}
