import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AnimationType } from '../../../gql/generated/graphql';
import { TextInput, UserError } from '../../components';
import Button from '../../components/button';
import editorActions from '../../components/editor/editor-actions';
import { ButtonBar, Module } from '../../layouts';
import { ButtonGroup } from '../../layouts/button-group';
import { AnimationPixelMode } from '../../lib/animation/animation-default-state';
import { hideModal } from '../../lib/modal/modal-actions';
import { useAppDispatch } from '../../store';

import ModalTitle from './modal-title';

const ChooseAnimationDimensionsModal = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [error, setError] = useState(null);
  const [pixelMode, setPixelMode] = useState(AnimationPixelMode.STANDARD);
  const widthRef = useRef<HTMLInputElement>();
  const heightRef = useRef<HTMLInputElement>();

  const onCancelClicked = () => {
    dispatch(hideModal());
  };

  const onPixelModeChanged = (e) => {
    setPixelMode(e.target.value);
  };

  const onCreateClicked = () => {
    const width = parseInt(widthRef.current.value);
    const height = parseInt(heightRef.current.value);

    if (
      !width ||
      !height ||
      width < 16 ||
      width > 1000 ||
      height < 16 ||
      height > 1000
    ) {
      return setError(['Width and height must be between 16 and 1000']);
    }

    if (width % 2 || height % 2) {
      return setError(['Width and height must be even numbers']);
    }

    dispatch(
      editorActions.createNew({
        width,
        height,
        pixelMode,
        type: AnimationType.Standalone,
      })
    );
    history.push('/editor');
    dispatch(hideModal());
  };

  const onItemSelected = (width, height, isPixelArt = false) => {
    widthRef.current.value = width;
    heightRef.current.value = height;
    setPixelMode(
      isPixelArt ? AnimationPixelMode.PIXELATED : AnimationPixelMode.STANDARD
    );
  };

  return (
    <div>
      <ModalTitle>Create New Animation</ModalTitle>
      <Module className="text-center">
        <p>
          Please choose the size for your new animation:
          <br />
          (max 1000, min 16)
        </p>
        {error && <UserError errors={error} />}
      </Module>
      <Module className="text-center">
        <Button onClick={() => onItemSelected('400', '300')}>400x300</Button>{' '}
        <Button onClick={() => onItemSelected('800', '600')}>800x600</Button>{' '}
        <Button onClick={() => onItemSelected('768', '480')}>768x480</Button>{' '}
      </Module>

      <Module className="text-center">
        <p>Widths and heights must be even numbers</p>
        <div>
          <TextInput
            label="width"
            ref={widthRef}
            inline
            className="w-20"
            maxlength="4"
            defaultValue="400"
            type="number"
            max="1000"
            min="16"
            step="2"
          />
          <TextInput
            label="height"
            ref={heightRef}
            className="w-20"
            inline
            maxlength="4"
            defaultValue="300"
            type="number"
            max="1000"
            min="16"
            step="2"
          />
        </div>
      </Module>
      <Module className="text-center">
        <input
          type="radio"
          name="pixelMode"
          value={AnimationPixelMode.STANDARD}
          id="pixelModeStandard"
          checked={pixelMode === AnimationPixelMode.STANDARD}
          onChange={onPixelModeChanged}
        />{' '}
        <label htmlFor="pixelModeStandard">Standard (MP4) video</label>{' '}
        <input
          type="radio"
          name="pixelMode"
          value={AnimationPixelMode.PIXELATED}
          id="pixelModePixelArt"
          checked={pixelMode === AnimationPixelMode.PIXELATED}
          onChange={onPixelModeChanged}
        />{' '}
        <label htmlFor="pixelModePixelArt">Pixel art</label>
        <p>
          Pixel art works best at small sizes, less than 100px. Pixel art is
          encoded as a .gif.
        </p>
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-times-circle"
            onClick={onCancelClicked}
            type="negative"
          >
            Cancel
          </Button>
          <Button
            icon="fas fa-plus-circle"
            onClick={onCreateClicked}
            type="positive"
          >
            Create New Animation
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
};

export default ChooseAnimationDimensionsModal;
