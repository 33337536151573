import React from 'react';
import { useParams } from 'react-router-dom';

import { Button } from '../components';
import PageHeading from '../components/page-heading';
import ThemedPage from '../components/themed-page';
import { ButtonBar, ButtonGroup, Main, Module } from '../layouts';

const EditorLoadFailurePage = () => {
  const { animationUrl } = useParams();

  return (
    <ThemedPage>
      <Main small>
        <PageHeading>Animation failed to load</PageHeading>

        <Module>
          <h2>Sorry, your animation failed to load</h2>
          <p className="my-2">
            It might be a temporary network problem. It may succeed if you try
            again.
          </p>
        </Module>
        <ButtonBar>
          <ButtonGroup align="right">
            <Button to="/editor" type="negative" rightAlign>
              <i className="fas fa-times-circle"></i> Cancel
            </Button>
            <Button to={`/editor/${animationUrl}`} type="positive" rightAlign>
              <i className="fas fa-redo"></i> Try again
            </Button>
          </ButtonGroup>
        </ButtonBar>
      </Main>
    </ThemedPage>
  );
};

export default EditorLoadFailurePage;
