import React, { Fragment, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLazyQuery, useQuery } from '@apollo/client';

import { GET_USER } from '../../../../gql/queries/user';
import Button from '../../../components/button';
import { TextInput } from '../../../components/forms';
import UserError from '../../../components/user-error';
import { ButtonBar, Module } from '../../../layouts';
import { ButtonGroup } from '../../../layouts/button-group';
import currentUserSelector from '../../../lib/selectors/current-user-selector';
import { changeUsernameAction } from '../../../lib/user/user-actions';
import { useAppDispatch } from '../../../store';
import { ModalComponentProps } from '../../modal';

const ERROR_MAP = {
  ERR_NAME_UNCHANGED: 'The username is the same as the current name',
  ERR_NAME_UNAVAILABLE: 'The username is not available',
  ERR_NAME_TOO_SHORT: 'The username needs to be longer than 3 characters',
  ERR_NAME_TOO_LONG: 'The username needs to be shorter than 30 characters',
  ERR_NAME_FREQUENCY:
    'Username cannot be changed within 30 days of last change',
  ERR_NAME_INVALID:
    'The username can only contain alphanumeric characters, dashes and underscored',
};

export default function UserDetailsUsername(props: ModalComponentProps) {
  const dispatch = useAppDispatch();
  const inputUsernameRef = useRef(null);
  const currentUser = useSelector(currentUserSelector);

  const [updateUserQuery, { loading: loadingUserUpdate }] = useLazyQuery(
    GET_USER,
    {
      variables: { id: currentUser.id },
      fetchPolicy: 'network-only',
    }
  );

  const [errors, setErrors] = useState([]);

  const { loading, data } = useQuery(GET_USER, {
    variables: { id: currentUser.id },
  });

  const onUpdateClicked = async () => {
    const result = await dispatch(
      changeUsernameAction(inputUsernameRef.current.value)
    );
    if (result === true) {
      await updateUserQuery();
      props.hideModal();
    } else {
      setErrors(result.map((code) => ERROR_MAP[code]));
    }
  };

  return (
    <div>
      {!loading && data.user.nameChangePermitted && (
        <Fragment>
          <Module>
            <p>
              You can change your user name once within thirty days. The change
              is immediate. You will need to use the new username to sign in.
            </p>
            <p>
              Your name will update in this browser tab. If you have other
              browser tabs open on this site, you will need to sign out and sign
              in again to see the change.
            </p>
            <UserError errors={errors} />

            <TextInput
              label="User name: (No spaces, alphanumeric, less than 30 chars)"
              id="username"
              type="text"
              ref={inputUsernameRef}
              autoCapitalize="none"
              defaultValue={currentUser.name}
            />
          </Module>
          <ButtonBar>
            <ButtonGroup align="right">
              <Button
                icon="fas fa-times"
                onClick={props.hideModal}
                type="negative"
              >
                Cancel
              </Button>
              {!loadingUserUpdate && (
                <Button
                  icon="fas fa-user"
                  onClick={onUpdateClicked}
                  type="positive"
                >
                  Change username
                </Button>
              )}
            </ButtonGroup>
          </ButtonBar>
        </Fragment>
      )}
      {!loading && !data.user.nameChangePermitted && (
        <Fragment>
          <Module>
            <p>
              You have changed or created your name within the last thirty days.
              You cannot change your name until thirty days have passed.
            </p>
          </Module>
          <ButtonBar>
            <ButtonGroup align="right">
              <Button
                icon="fas fa-times"
                onClick={props.hideModal}
                type="negative"
              >
                Cancel
              </Button>
            </ButtonGroup>
          </ButtonBar>
        </Fragment>
      )}
    </div>
  );
}
