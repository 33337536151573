import React from 'react';
import Module from 'layouts/module';
import { PropTypes } from 'prop-types';

export const PublicStatus = ({ isPublic }) => {
  if (isPublic) {
    return (
      <Module>
        <p className="text-center">
          This animation is{' '}
          <span className="animation-page__public">
            <i className="fas fa-globe-europe" /> In Gallery
          </span>{' '}
          anyone can see it publicly.
        </p>
      </Module>
    );
  }

  return (
    <Module>
      <p className="text-center">
        This animation is{' '}
        <span className="animation-page__private">
          <i className="fas fa-eye-slash" /> Private
        </span>{' '}
        only people with this link can see it.
      </p>
    </Module>
  );
};

PublicStatus.propTypes = {
  isPublic: PropTypes.bool.isRequired,
};
