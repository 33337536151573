import React from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import Reaction from 'components/reaction';

export const ReactionsBreakdown = ({ reactions }) => {
  if (reactions.length === 0) return null;

  const flattenedReactions = reactions.reduce((flattened, { type, user }) => {
    if (!flattened[user.name]) {
      flattened[user.name] = { types: [] };
    }

    flattened[user.name].types.push(type);
    return flattened;
  }, {});

  return (
    <ol className="flex -mx-1 flex-wrap">
      {Object.keys(flattenedReactions).map((username) => {
        const reaction = flattenedReactions[username];
        return (
          <li
            className="text-sm bg-indigo-300 m-0.5 p-1 text-center rounded-md shadow-md inline-block"
            key={username}
          >
            <Link className="text-white" to={`/studio/${username}`}>
              {reaction.types.map((type) => (
                <Reaction key={type} type={type} />
              ))}
              <br />
              {username}
            </Link>
          </li>
        );
      })}
    </ol>
  );
};

ReactionsBreakdown.propTypes = {
  reactions: PropTypes.array.isRequired,
};
