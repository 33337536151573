export const framePickerSelector = (state) => {
  const { animation, editor } = state;
  const { framePickerLayerMode: visibleLayers } = editor;
  const { sequence } = animation;

  const imagesUsed = new WeakMap();
  const uniqueSequenceItems = [];

  sequence.forEach((sequenceItem, index) => {
    const filteredLayers = sequenceItem.layers.filter(
      (layer, index) => visibleLayers[index]
    );

    if (filteredLayers.some((layer) => !imagesUsed.has(layer.image))) {
      filteredLayers.forEach((layer) => imagesUsed.set(layer.image, true));
      uniqueSequenceItems.push({
        ...sequenceItem,
        layers: filteredLayers,
        index,
      });
    }
  });

  return uniqueSequenceItems;
};
