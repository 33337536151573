import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';

import { MUTATE_SEND_VERIFICATION_EMAIL } from '../../../../gql/queries/mutate-send-verification-email';
import { MUTATE_USER } from '../../../../gql/queries/mutate-user';
import { GET_USER } from '../../../../gql/queries/user';
import Button from '../../../components/button';
import { TextInput } from '../../../components/forms';
import UserError from '../../../components/user-error';
import { ButtonBar, Module } from '../../../layouts';
import { ButtonGroup } from '../../../layouts/button-group';
import { showModal } from '../../../lib/modal/modal-actions';
import currentUserSelector from '../../../lib/selectors/current-user-selector';
import { RootState } from '../../../store';
import { ModalComponentProps } from '../../modal';

const ERROR_MAP = {
  ERR_EMAIL_EXISTS:
    'This email address is already registered to another account',
  ERR_INVALID_EMAIL: 'Email address is not valid',
};

export default function UserDetailsEmail(props: ModalComponentProps) {
  const dispatch = useDispatch();
  const inputEmailRef = useRef(null);
  const email = useSelector((state: RootState) => {
    return state.user.email;
  });
  const [mutateUser] = useMutation(MUTATE_USER, {
    onError: (e) => {
      const { code } = e.graphQLErrors[0].extensions;

      switch (code) {
        case 'BAD_USER_INPUT':
          setErrors(['ERR_INVALID_EMAIL']);
          break;
      }
    },
    onCompleted: () => {
      return dispatch(showModal('VerificationEmailSentModal'));
    },
  });
  const [mutateSendVerificationEmail] = useMutation(
    MUTATE_SEND_VERIFICATION_EMAIL,
    {
      onCompleted: () => {
        return dispatch(showModal('VerificationEmailSentModal'));
      },
    }
  );
  const [errors, setErrors] = useState([]);
  const mappedErrors = errors.map((e) => {
    return ERROR_MAP[e];
  });
  const currentUser = useSelector(currentUserSelector);

  const { loading, data } = useQuery(GET_USER, {
    variables: { id: currentUser.id },
  });

  const onUpdateClicked = () => {
    mutateUser({
      variables: { id: currentUser.id, email: inputEmailRef.current.value },
    });
    dispatch({
      type: 'USER/SET',
      payload: { email: inputEmailRef.current.value },
    });
  };

  return (
    <div>
      {!loading && !data.user.verified && (
        <Module>
          <p>
            Your email address has not been verified. Check the email address is
            correct below and re-send a verification email if necessary. The
            email will be sent to <strong>{email}</strong>, if this is not
            correct, please update below and press the &quot;update email&quot;
            button.
          </p>
          <Button
            icon="fas fa-envelope"
            onClick={() => {
              mutateSendVerificationEmail({
                variables: { id: currentUser.id },
              });
            }}
            type="neutral"
          >
            Re-send verification email
          </Button>
        </Module>
      )}
      {!loading && data.user.verified && (
        <Module>
          <p>Your email address has been successfully verified. Thank you!</p>
        </Module>
      )}
      <Module>
        <UserError errors={mappedErrors} />
        <TextInput
          label="Email address:"
          id="email"
          type="email"
          ref={inputEmailRef}
          autoCapitalize="none"
          defaultValue={email}
        />
      </Module>
      <Module>
        <p>
          This must be a valid email address which can receive emails from The
          Animator. Accounts without verified email addresses cannot post
          animations or comments.
        </p>
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button icon="fas fa-times" onClick={props.hideModal} type="negative">
            Cancel
          </Button>
          <Button
            icon="fas fa-envelope"
            onClick={onUpdateClicked}
            type="positive"
          >
            Update email
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
}
