import React, { ReactNode } from 'react';

export const Statistic = ({
  icon,
  title,
  children,
}: {
  icon: string;
  title: string;
  children: ReactNode;
}) => {
  return (
    <div className="rounded-lg bg-white text-gray-800 bg-opacity-50 text-center py-2 px-5 mr-2 shadow-md">
      <div className="text-2xl text-gray-500">
        <i className={icon} />
      </div>
      <div className="text-xs">{title}</div>
      <span className="text-xl font-bold">{children}</span>
    </div>
  );
};
