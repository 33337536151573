import React from 'react';
import PropTypes from 'prop-types';

export const ProgressBar = ({ progressPercent }) => {
  return (
    <div className="progress-bar">
      <span style={{ width: `${progressPercent}%` }}></span>
    </div>
  );
};

ProgressBar.propTypes = {
  progressPercent: PropTypes.number.isRequired,
};
