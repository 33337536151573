import React, { FC, MouseEvent, TouchEvent, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../../store';
import editorActions from '../editor-actions';
import EditorButton from '../editor-button/editor-button';
import { animationPaletteSelector } from '../selectors/animation-palette-selector';
import { editorSelector } from '../selectors/editor-selector';

type PaletteProps = {
  visible?: boolean;
};

const Palette: FC<PaletteProps> = (props) => {
  const dispatch = useAppDispatch();
  const palette = useSelector(animationPaletteSelector);
  const editor = useSelector(editorSelector);

  const onButtonPressed = useCallback(
    (e: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>) => {
      if (!(e.target instanceof HTMLButtonElement)) return;
      dispatch(
        editorActions.onColourChanged(parseInt(e.target.dataset.index, 10))
      );
      e.preventDefault();
    },
    []
  );

  const onEyeDropperSelected = useCallback(() => {
    dispatch(editorActions.onToolChanged('eyedropper'));
  }, []);

  const onPaletteButtonPressed = useCallback(() => {
    dispatch(editorActions.openPaletteEditorAction());
  }, []);

  const buttons = palette.map((colour, index) => {
    const className =
      editor.currentColourIndex === index ? ' palette-button--selected' : '';
    return (
      <li className="inline-block" key={index}>
        <button
          className={'palette-button' + className}
          style={{ background: colour }}
          data-index={index}
          onClick={onButtonPressed}
          onTouchEnd={onButtonPressed}
        />
      </li>
    );
  });

  const className = `palette ${props.visible ? '' : 'palette--hidden'}`;

  return (
    <div>
      <ol className={className}>
        <li className="inline-block">
          <EditorButton
            onButtonPressed={onPaletteButtonPressed}
            label={<i className="fas fa-palette" />}
          />
          <EditorButton
            onButtonPressed={onEyeDropperSelected}
            label={<i className="fas fa-eye-dropper" />}
            active={editor.currentTool === 'eyedropper'}
          />
        </li>
        {buttons}
      </ol>
    </div>
  );
};

export default Palette;
