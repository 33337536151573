import React, { FC } from 'react';

type PublicGallerySwitchProps = {
  isPublic: boolean;
  onAnimationPublicToggled: (isPublic: boolean) => void;
};

const PublicGallerySwitch: FC<PublicGallerySwitchProps> = (props) => {
  return (
    <div
      className={`public-switch inline-block public-switch--${
        props.isPublic ? 'public' : 'private'
      }`}
      onClick={() => {
        props.onAnimationPublicToggled(!props.isPublic);
      }}
    >
      <span className="public-switch__label--private">Private</span>
      <i className={`fas fa-toggle-${props.isPublic ? 'on' : 'off'}`} />
      <span className="public-switch__label--public">In Gallery</span>
    </div>
  );
};

export default PublicGallerySwitch;
