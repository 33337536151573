import React, { FC, PureComponent, ReactNode } from 'react';
import { Route } from 'react-router-dom';

import PageHeading from '../../components/page-heading';
import ThemedPage from '../../components/themed-page';
import { Main, Module } from '../../layouts';

type StatusProps = {
  code: number;
  children: ReactNode;
};

const Status: FC<StatusProps> = ({ code, children }) => (
  <Route
    render={({ staticContext }) => {
      if (staticContext) staticContext.status = code;
      return children;
    }}
  />
);

class NotFound extends PureComponent {
  render() {
    return (
      <Status code={404}>
        <ThemedPage>
          <Main small>
            <PageHeading>Not found</PageHeading>

            <Module>
              <div className="split-layout">
                <div className="split-layout--small">
                  <img
                    src="/images/villain.jpg"
                    alt=""
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="split-layout--large">
                  <p className="text--large">
                    <strong>404 - Not found</strong>
                  </p>
                  <p>
                    Whoops! This page doesn&apos;t seem to exist. The link you
                    followed might be outdated.
                  </p>
                  <p>Please use the menu at the top to go elsewhere.</p>
                </div>
              </div>
            </Module>
          </Main>
        </ThemedPage>
      </Status>
    );
  }
}

export default NotFound;
