export function backgroundGradient(col1, col2) {
  return {
    background: `linear-gradient(
        238deg,
        rgba(70, 70, 70, 0.09) 0%,
        rgba(70, 70, 70, 0.09) 50%,
        rgba(214, 214, 214, 0.09) 50%,
        rgba(214, 214, 214, 0.09) 100%
      ),
      linear-gradient(
        126deg,
        rgba(223, 223, 223, 0.05) 0%,
        rgba(223, 223, 223, 0.05) 50%,
        rgba(217, 217, 217, 0.05) 50%,
        rgba(217, 217, 217, 0.05) 100%
      ),
      linear-gradient(
        152deg,
        rgba(116, 116, 116, 0.08) 0%,
        rgba(116, 116, 116, 0.08) 50%,
        rgba(248, 248, 248, 0.08) 50%,
        rgba(248, 248, 248, 0.08) 100%
      ),
      linear-gradient(
        225deg,
        rgba(1, 1, 1, 0.07) 0%,
        rgba(1, 1, 1, 0.07) 50%,
        rgba(5, 5, 5, 0.07) 50%,
        rgba(5, 5, 5, 0.07) 100%
      ),
      linear-gradient(
        194deg,
        rgba(14, 14, 14, 0.09) 0%,
        rgba(14, 14, 14, 0.09) 50%,
        rgba(206, 206, 206, 0.09) 50%,
        rgba(206, 206, 206, 0.09) 100%
      ),
      linear-gradient(
        100deg,
        rgba(220, 220, 220, 0.07) 0%,
        rgba(220, 220, 220, 0.07) 50%,
        rgba(65, 65, 65, 0.07) 50%,
        rgba(65, 65, 65, 0.07) 100%
      ),
      linear-gradient(
        190deg,
        rgba(194, 194, 194, 0.03) 0%,
        rgba(194, 194, 194, 0.03) 50%,
        rgba(206, 206, 206, 0.03) 50%,
        rgba(206, 206, 206, 0.03) 100%
      ),
      linear-gradient(
        320deg,
        rgba(10, 10, 10, 0.08) 0%,
        rgba(10, 10, 10, 0.08) 50%,
        rgba(231, 231, 231, 0.08) 50%,
        rgba(231, 231, 231, 0.08) 100%
      ),
      linear-gradient(90deg, ${col1}, ${col2})`,
  };
}
