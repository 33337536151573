import React from 'react';
import { useSelector } from 'react-redux';
import { ApolloConsumer } from '@apollo/client';

import Button from 'components/button';
import DropdownMenu from 'components/dropdown-menu';

import config from '../../../lib/config';
import { showModal } from '../../lib/modal/modal-actions';
import currentUserSelector from '../../lib/selectors/current-user-selector';
import { signOutUser } from '../../lib/user/user-actions';
import { useAppDispatch } from '../../store';
import { UserPill } from '../user-pill/user-pill';

const UserToolbar = () => {
  const currentUser = useSelector(currentUserSelector);
  const dispatch = useAppDispatch();

  if (!currentUser.id) {
    return (
      <Button icon="fas fa-user" to="/sign-in" data-testid="sign-in-button">
        Sign-in
      </Button>
    );
  }
  return (
    <ApolloConsumer>
      {(apolloClient) => {
        return (
          <DropdownMenu
            data-testid="user-menu-button"
            button={{
              icon: currentUser.avatarUrl ? null : 'fas fa-user',
              text: (
                <UserPill user={currentUser} small nonInteractive stillImage />
              ),
              type: 'transparent',
            }}
            items={[
              {
                icon: 'fas fa-user-cog',
                action: () => {
                  return showModal('UserDetailsModal');
                },
                text: 'My account',
              },
              {
                icon: 'fas fa-portrait',
                action: () => {
                  return showModal('ChooseAvatarModal');
                },
                text: 'Profile picture',
              },
              {
                icon: 'fas fa-palette',
                action: () => {
                  return showModal('ThemeModal');
                },
                text: 'Edit my theme',
              },
              {
                icon: 'fas fa-sign-out-alt',
                onClick: async () => {
                  await dispatch(signOutUser());
                  apolloClient.resetStore();
                },
                text: 'Sign out',
                buttonProps: {
                  'data-testid': 'user-sign-out-button',
                },
              },
            ]}
          />
        );
      }}
    </ApolloConsumer>
  );
};

export default UserToolbar;
