import { graphql } from '../generated';

export const MUTATE_COMPETITION_VOTE = graphql(`
  mutation MutateCompetitionVote(
    $threadId: Int
    $animationId: Int
    $action: CompetitionVoteAction
  ) {
    competitionVote(
      threadId: $threadId
      animationId: $animationId
      action: $action
    ) {
      id
      userVotes
      animationVotes {
        animationId
        votes
      }
    }
  }
`);
