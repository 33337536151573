import React, { FC, useEffect, useRef, useState } from 'react';
import interact from 'interactjs';

type SliderControlProps = {
  defaultValue: number;
  min: number;
  max: number;
  decimals: number;
  className?: string;
  onChange: (value: number) => void;
};

const SliderControl: FC<SliderControlProps> = (props) => {
  const [showValue, setShowValue] = useState(null);

  const grabberRef = useRef<HTMLDivElement>(null);

  const onChange = (value: number) => {
    props.onChange(value);
  };

  useEffect(() => {
    interact(grabberRef.current) // target elements with the 'slider' class
      .draggable({
        // make the element fire drag events
        origin: 'self', // (0, 0) will be the element's top-left
        modifiers: [
          interact.modifiers.restrict({
            restriction: 'self', // keep the drag coords within the element
          }),
        ],
      })
      .on('dragstart', () => {
        setShowValue(true);
      })
      .on('dragmove', function (event) {
        // call this listener on every dragmove
        const sliderWidth = interact.getElementRect(
          event.target.parentNode
        ).width;
        const positionPercent = event.pageX / sliderWidth;
        const value = parseFloat(
          (props.min + positionPercent * (props.max - props.min)).toFixed(
            props.decimals
          )
        );

        event.target.style.paddingLeft = positionPercent * 100 + '%';
        event.target.setAttribute('data-value', value);
      })
      .on('dragend', (event) => {
        setShowValue(false);
        onChange(event.target.getAttribute('data-value'));
      });
  }, []);

  useEffect(() => {
    grabberRef.current.style.paddingLeft = `${
      ((props.defaultValue - props.min) / (props.max - props.min)) * 100
    }%`;
  }, []);

  return (
    <div
      className={`slider-control ${props.className ?? ''} ${
        showValue ? 'slider-control--show-value' : ''
      }`}
    >
      <div className="slider-control__rail" ref={grabberRef} />
    </div>
  );
};

export default SliderControl;
