import React from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../store';
import DropdownMenu, {
  DropdownMenuEntry,
} from '../dropdown-menu/dropdown-menu';

import { editorSelector } from './selectors/editor-selector';
import editorActions from './editor-actions';

const EditorViewMenu = () => {
  const dispatch = useAppDispatch();
  const { showOnionSkins, showGrid } = useSelector(editorSelector);

  const menuItems: DropdownMenuEntry[] = [
    {
      icon: 'fas fa-th',
      text: (
        <span>
          Show pixel grid {showGrid ? <i className="fas fa-check"></i> : null}
        </span>
      ),
      onClick: () => dispatch(editorActions.toggleGrid()),
    },
    {
      icon: 'fas fa-ghost',
      text: (
        <span>
          Show onion skins{' '}
          {showOnionSkins ? <i className="fas fa-check"></i> : null}
        </span>
      ),
      onClick: () => dispatch(editorActions.toggleOnionSkins()),
    },
  ];

  return (
    <DropdownMenu
      align="left"
      button={{
        text: 'View',
        type: 'transparent',
      }}
      items={menuItems}
    />
  );
};

export default EditorViewMenu;
