import React, { Fragment, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import animationActions, {
  setFrameDelayAction,
} from '../../../lib/animation/animation-actions';
import { calculateThumbDimensions } from '../../../lib/animation/canvas-utils';
import Delay from '../delay';
import editorActions from '../editor-actions';
import EditorButton from '../editor-button';
import TimePicker from '../time-picker';

import LayerSwitcher from './layer-switcher';
import TimelineFrame from './timeline-frame';
import { timelineSelector } from './timeline-selectors';

const Timeline = () => {
  const [timePickerVisible, setTimePickerVisible] = useState(false);
  const timelineRef = useRef();
  const containerRef = useRef();
  const dispatch = useDispatch();
  const state = useSelector(timelineSelector);

  const onDelayPressed = () => {
    setTimePickerVisible(true);
  };

  const toggleTimePicker = () => {
    setTimePickerVisible(false);
  };

  const onNextFramePressed = () => {
    dispatch(editorActions.nextFrame());
  };

  const onPreviousFramePressed = () => {
    dispatch(editorActions.previousFrame());
  };

  const onDelayChanged = (delayData) => {
    dispatch(
      setFrameDelayAction(
        state.currentFrameIndex,
        parseInt(delayData.delay, 10) || 0,
        delayData.applyAll
      )
    );
    setTimePickerVisible(false);
  };

  const onAddFramePressed = async () => {
    await dispatch(
      animationActions.addFrame(
        state.currentFrameIndex + 1,
        state.sequence[state.currentFrameIndex].delay
      )
    );
    dispatch(editorActions.goToFrame(state.currentFrameIndex + 1));
  };

  const onFrameSelected = useCallback(
    (id) => {
      dispatch(editorActions.goToFrameId(id));
    },
    [editorActions]
  );

  const onDeleteFramePressed = () => {
    dispatch(animationActions.deleteFrame(state.currentFrameIndex));
    if (
      state.currentFrameIndex === state.sequence.length - 1 &&
      state.currentFrameIndex > 0
    ) {
      dispatch(editorActions.goToFrame(state.currentFrameIndex - 1));
    }
  };

  const onCopyFramePressed = () => {
    dispatch(editorActions.toggleFramePicker());
  };

  const onStartPlaybackPressed = () => {
    dispatch(editorActions.startPlayback());
  };

  const onStopPlaybackPressed = () => {
    dispatch(editorActions.stopPlayback());
  };

  // if (state.sequence.length === 0) return null;
  const { currentFrameIndex } = state;
  const sequence = state.sequence.slice(0);

  if (state.framePickerVisible && state.framePickerMode === 'insert') {
    sequence.splice(state.currentFrameIndex + 1, 0, 'placeholder');
  }

  let componentsToRender = [];

  const { width, height } = calculateThumbDimensions(
    state.frameDimensions.width,
    state.frameDimensions.height,
    120,
    90
  );

  for (let index = 0; index < 11; index++) {
    const position = currentFrameIndex - 5 + index;
    const sequenceItem = sequence[position];

    if (
      sequenceItem === 'empty' ||
      position < 0 ||
      position > sequence.length - 1
    ) {
      componentsToRender.push(
        <li
          className="timeline__frame m-2 rounded-md border-2 border-dashed border-gray-300 bg-gray-200"
          key={`empty-${index}`}
          style={{ width, height }}
        />
      );
    } else if (sequenceItem === 'placeholder') {
      componentsToRender.push(
        <li
          className="timeline__frame m-2 rounded-md border-2 border-dashed border-gray-300 bg-gray-200"
          key="insert-placeholder"
          style={{ width, height }}
        >
          <div className="timeline__frame--insert">
            <i className="fas fa-angle-double-down" />
          </div>
        </li>
      );
    } else {
      componentsToRender.push(
        <TimelineFrame
          layers={sequenceItem.layers}
          currentLayer={state.currentLayerIndex}
          index={position + 1}
          key={`${sequenceItem.id}-${index}`}
          id={sequenceItem.id}
          showCopyOverlay={
            (state.framePickerVisible &&
              state.framePickerMode === 'overwrite-all') ||
            (state.framePickerVisible &&
              state.framePickerMode === 'overwrite' &&
              index === 5)
          }
          onFrameSelected={onFrameSelected}
          active={index === 5}
          width={state.frameDimensions.width}
          height={state.frameDimensions.height}
          showCounter={true}
        />
      );
    }
  }

  return (
    <Fragment>
      <TimePicker
        visible={timePickerVisible}
        onClosePressed={toggleTimePicker}
        onChange={onDelayChanged}
        currentValue={state.sequence[state.currentFrameIndex].delay}
      />
      <div
        className="timeline-container flex flex-col items-center"
        ref={containerRef}
      >
        <div className="timeline-toolbar">
          <LayerSwitcher />
          <EditorButton
            onButtonPressed={onDeleteFramePressed}
            transient={true}
            label={<i className="fas fa-trash-alt" />}
            disabled={state.mode !== 'editing'}
          />
          <div className="button-spacer" />
          <EditorButton
            onButtonPressed={onPreviousFramePressed}
            transient={true}
            label={<i className="fas fa-angle-left" />}
            disabled={state.mode !== 'editing'}
          />
          <span className="frame-status-text">
            {state.currentFrameIndex + 1} / {state.sequence.length}
          </span>
          <EditorButton
            onButtonPressed={onNextFramePressed}
            transient={true}
            label={<i className="fas fa-angle-right" />}
            disabled={state.mode !== 'editing'}
          />
          <EditorButton
            onButtonPressed={onAddFramePressed}
            transient={true}
            label={<i className="fas fa-file" />}
            disabled={state.mode !== 'editing'}
          />
          <EditorButton
            onButtonPressed={onCopyFramePressed}
            transient={true}
            label={<i className="fas fa-clone" />}
            disabled={state.mode !== 'editing'}
          />
          <div className="button-spacer" />
          <EditorButton
            onButtonPressed={onStartPlaybackPressed}
            transient={true}
            label={<i className="fas fa-play" />}
            disabled={state.mode !== 'editing'}
          />
          <EditorButton
            onButtonPressed={onStopPlaybackPressed}
            transient={true}
            label={<i className="fas fa-stop" />}
          />
          <Delay
            value={state.sequence[state.currentFrameIndex].delay}
            onChange={onDelayChanged}
            onClick={onDelayPressed}
          />
        </div>

        <ol className={`timeline timeline--${state.mode}`} ref={timelineRef}>
          {componentsToRender}
        </ol>
      </div>
    </Fragment>
  );
};

export default Timeline;
