import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import animationActions from '../../lib/animation/animation-actions';
import { hideModal, showModal } from '../../lib/modal/modal-actions';
import { RootState, useAppDispatch } from '../../store';
import editorActions from '../editor/editor-actions';

import { bootstrap } from './bootstrap';
import { EDITOR_DB_STATE } from './editor-reducer';

export enum BOOTSTRAP_ERRORS {
  NOT_FOUND = 'NOT_FOUND',
  LOAD_FAIL = 'LOAD_FAIL',
}

type BootstrapSuccessCallback = (
  data?: Partial<RootState['animation']>
) => void;

const useEditorBootstrap = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { animationUrl = null } = useParams<{ animationUrl: string }>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<BOOTSTRAP_ERRORS>();

  const onSuccess: BootstrapSuccessCallback = useCallback(
    (data, opts = { fromServer: false }) => {
      if (data) {
        dispatch(animationActions.set(data));
        dispatch(editorActions.resetEditor());
        dispatch(editorActions.resetEditorLayers());
        if (opts.fromServer) {
          dispatch(animationActions.setSaved(true));
        }
        if (data.url && data.url !== animationUrl)
          history.replace(`/editor/${data.url}`);
      }
      setLoading(false);
      dispatch(hideModal());
    },
    []
  );

  const onError = useCallback((error) => {
    if (error.graphQLErrors) {
      if (error.graphQLErrors[0].extensions.code === 'NOT_FOUND') {
        setError(BOOTSTRAP_ERRORS.NOT_FOUND);
        dispatch(hideModal());
      } else {
        dispatch(hideModal());
        history.replace(`/editor/load-error/${animationUrl}`);
      }
    } else {
      dispatch(hideModal());
      history.replace(`/editor/load-error/${animationUrl}`);
    }
    setLoading(false);
  }, []);

  const onIDBDetermined = useCallback((isCompatible) => {
    dispatch(
      editorActions.setDbState(
        isCompatible ? EDITOR_DB_STATE.SUPPORTED : EDITOR_DB_STATE.UNSUPPORTED
      )
    );
  }, []);

  const onLoadProgress = useCallback(({ resolved, total }) => {
    dispatch(
      showModal('LoadingModal', {
        resolved: resolved ?? 0,
        total: total,
      })
    );
  }, []);

  const onUrlChange = useCallback((url) => {
    if (url && url !== animationUrl) history.replace(`/editor/${url}`);
  }, []);

  const onOverwritePrompt = useCallback(() => {
    return new Promise((resolve) => {
      dispatch(
        showModal('ConfirmOverwriteAnimationModal', {
          onClearAndLoadSelected: () => {
            resolve(true);
          },
          onCancelSelected: () => {
            resolve(false);
          },
        })
      );
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    bootstrap({
      onSuccess,
      onError,
      onIDBDetermined,
      onLoadProgress,
      onOverwritePrompt,
      onUrlChange,
      animationUrl,
    });
  }, [animationUrl]);

  return { loading, error };
};

export { useEditorBootstrap };
