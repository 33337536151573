import React from 'react';

import { Animation } from '../../../gql/generated/graphql';
import PaginatedGallery from '../../components/paginated-gallery';

export const ResultsAnimations = ({
  animations = [],
  total,
  onPageChanged,
  page,
}: {
  animations: Array<Animation>;
  total: number;
  onPageChanged: CallableFunction;
  page: number;
}) => {
  if (!animations.length) {
    return (
      <div>
        <h3>Animations</h3>
        <p>No animation results.</p>
      </div>
    );
  }

  return (
    <div>
      <h3>Animations</h3>
      <PaginatedGallery
        page={page}
        totalAnimations={total}
        animations={animations}
        loading={false}
        showPublic={false}
        onPageChanged={onPageChanged}
      />
    </div>
  );
};
