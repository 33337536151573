import { selectToolState } from '../lib/tool-selectors';

import { GenericTool, GenericToolConstructorArgs } from './generic';

const MIN_SIZE = 5;
const NUM_TEXTURES = 20;
let pencilTexture;

export function loadTexture() {
  return new Promise((resolve, reject) => {
    pencilTexture = new Image();
    pencilTexture.onload = resolve;
    pencilTexture.onerror = () => {
      return reject(new Error('Failed to load pencil texture'));
    };
    pencilTexture.src = '/images/pencil-mask.png';
  });
}

export class PencilTool extends GenericTool {
  textureIndex = 0;
  textureCanvas: HTMLCanvasElement;
  cachedColour: string;
  cachedSize: number;
  x: number;
  y: number;
  active = false;
  colour: string;
  size: number;

  constructor(args: GenericToolConstructorArgs) {
    super(args);
    this.textureIndex = 0;
  }

  generateTextures(size: number, colour: string) {
    size = size < MIN_SIZE ? MIN_SIZE : size;

    const textureCanvas = document.createElement('canvas');
    textureCanvas.width = size * NUM_TEXTURES;
    textureCanvas.height = size;
    const ctx = textureCanvas.getContext('2d');

    for (let index = 0; index < NUM_TEXTURES; index++) {
      const x = index * size;

      ctx.globalAlpha = 1;
      ctx.globalCompositeOperation = 'source-over';

      ctx.fillStyle = colour;
      ctx.arc(x + size / 2, size / 2, size / 2, 0, 2 * Math.PI);
      ctx.fill();

      // Draw pencil texture mask
      ctx.globalCompositeOperation = 'destination-out';
      ctx.drawImage(pencilTexture, 0, 0);
    }
    this.textureCanvas = textureCanvas;
  }

  getTextureCoords(size: number, colour: string) {
    if (
      !this.textureCanvas ||
      size !== this.cachedSize ||
      this.cachedColour !== colour
    ) {
      this.generateTextures(size, colour);
      this.cachedSize = size;
      this.cachedColour = colour;
    }
    this.textureIndex++;
    if (this.textureIndex === NUM_TEXTURES) this.textureIndex = 0;
    return this.textureIndex * size;
  }

  static getSizeAdjustedCoords({ coords, size }) {
    let [x, y] = coords;

    x = Math.round(x - size / 2);
    y = Math.round(y - size / 2);

    return [x, y];
  }

  onStart({ coords }) {
    const { size, colour, opacity } = selectToolState();
    const [x, y] = PencilTool.getSizeAdjustedCoords({ coords, size });
    this.x = x;
    this.y = y;
    this.active = true;
    this.colour = colour;
    this.size = size;
    this.opacity = opacity;
    const textureX = this.getTextureCoords(size, colour);

    this.reset();
    this.undoStackPush();

    this.offscreenCtx.drawImage(
      this.textureCanvas,
      textureX,
      0,
      size,
      size,
      x,
      y,
      size,
      size
    );
    this.paintToVisible();
  }

  onMove({ coords }) {
    if (!this.active) return;

    const [x, y] = PencilTool.getSizeAdjustedCoords({
      coords,
      size: this.size,
    });
    if (x === this.x && y === this.y) return;

    // Line drawing algorithm
    let x0 = this.x;
    let y0 = this.y;
    const x1 = x;
    const y1 = y;

    const dx = Math.abs(x1 - x0);
    const dy = Math.abs(y1 - y0);
    const sx = x0 < x1 ? 1 : -1;
    const sy = y0 < y1 ? 1 : -1;
    let err = dx - dy;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const textureX = this.getTextureCoords(this.size, this.colour);

      this.offscreenCtx.drawImage(
        this.textureCanvas,
        textureX,
        0,
        this.size,
        this.size,
        x0,
        y0,
        this.size,
        this.size
      );

      if (x0 == x1 && y0 == y1) break;
      const e2 = 2 * err;
      if (e2 > -dy) {
        err -= dy;
        x0 += sx;
      }
      if (e2 < dx) {
        err += dx;
        y0 += sy;
      }
    }
    this.x = x;
    this.y = y;
    this.paintToVisible();
  }

  onTap({ coords }) {
    this.reset();
    const { size, colour, opacity } = selectToolState();
    this.opacity = opacity;

    const [x, y] = PencilTool.getSizeAdjustedCoords({
      coords,
      size: size,
    });
    const textureX = this.getTextureCoords(size, colour);

    this.offscreenCtx.drawImage(
      this.textureCanvas,
      textureX,
      0,
      size,
      size,
      x,
      y,
      size,
      size
    );

    this.paintToVisible();
    this.onEnd();
  }

  onEnd() {
    this.active = false;
    this.x = null;
    this.y = null;
    this.commitMerged();
  }
}
