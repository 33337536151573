export const setPageDataAction = function (key, value) {
  return {
    type: 'PAGE_DATA/SET',
    payload: {
      key,
      value,
    },
  };
};

export const clearPageDataAction = function () {
  return {
    type: 'PAGE_DATA/CLEAR',
    payload: {},
  };
};
