import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { ButtonBar, Main, Module } from 'layouts';
import { ButtonGroup } from 'layouts/button-group';

import { COMMUNITY_PAGINATED } from '../../../gql/queries/community-paginated';
import { showModal } from '../../components/../lib/modal/modal-actions';
import Button from '../../components/button';
import IfUserIs from '../../components/if-user-is';
import PageHeading from '../../components/page-heading';
import ThemedPage from '../../components/themed-page';
import ThreadTable from '../../components/thread-table';
const THREADS_PER_PAGE = 50;

const CommunityPaginated = () => {
  const { currentPage, type } = useParams();
  const page = parseInt(currentPage, 10);

  const dispatch = useDispatch();
  const { data = { threads: [] } } = useQuery(COMMUNITY_PAGINATED, {
    variables: {
      types:
        type === 'general' ? ['NORMAL', 'COMPETITION'] : ['ANIMATION', 'COMIC'],
      limit: THREADS_PER_PAGE,
      offset: (page - 1) * THREADS_PER_PAGE,
    },
    fetchPolicy: 'cache-and-network',
  });

  const renderPreviousLink = () => {
    const href = `/community/${type}/${page - 1}`;

    const disabled = page === 1;

    return (
      <ButtonGroup>
        <Button icon="fas fa-arrow-circle-left" to={href} disabled={disabled}>
          Newer
        </Button>
      </ButtonGroup>
    );
  };

  const renderNextLink = () => {
    const href = `/community/${type}/${page + 1}`;

    const disabled = data.threads.length < 50;

    return (
      <ButtonGroup align="right">
        <Button icon="fas fa-arrow-circle-right" to={href} disabled={disabled}>
          Older
        </Button>
      </ButtonGroup>
    );
  };

  const getCreateButton = () => {
    if (type !== 'general') return null;
    return (
      <ButtonGroup align="centre">
        <IfUserIs signedIn>
          <Button
            icon="fas fa-comment-dots"
            onClick={() => dispatch(showModal('CreateThreadModal'))}
            type="positive"
          >
            Create a new thread
          </Button>
        </IfUserIs>
      </ButtonGroup>
    );
  };

  const getButtonBar = () => {
    return (
      <ButtonBar>
        {renderPreviousLink()}
        {getCreateButton()}
        {renderNextLink()}
      </ButtonBar>
    );
  };

  const normalisedType = type === 'general' ? 'NORMAL' : 'ANIMATION';
  const subtitle =
    normalisedType === 'NORMAL' ? 'General Discussion' : 'Animation Comments';

  return (
    <ThemedPage>
      <Main>
        <Helmet>
          <title>{`The Animator - Community - ${type} - page ${currentPage}`}</title>
          <meta
            name="description"
            content={`The Animator Community ${type} threads, page ${currentPage}`}
          />
        </Helmet>
        <PageHeading>Community</PageHeading>
        <Module>
          <h3>
            {subtitle} - Page {currentPage}
          </h3>
        </Module>
        {getButtonBar()}
        <Module>
          <ThreadTable threads={data.threads} type={normalisedType} />
        </Module>
        {getButtonBar()}
      </Main>
    </ThemedPage>
  );
};

export default CommunityPaginated;
