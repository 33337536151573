import React, { Fragment } from 'react';
import { Module } from 'layouts';

export const OauthError = () => {
  return (
    <Fragment>
      <Module>
        <p>Sorry, we failed to sign you in.</p>
        <p>
          This might be because your sign in session has expired. You may want
          to try again.
        </p>
      </Module>
    </Fragment>
  );
};
