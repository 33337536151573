import React, { Fragment } from 'react';
// import TabBar from 'components/tab-bar/tab-bar';
import { useDispatch, useSelector } from 'react-redux';

import { TabBar } from '../../../components';
import animationActions from '../../../lib/animation/animation-actions';
import editorActions from '../editor-actions';
import { animationSelector } from '../selectors/animation-selector';
import { editorSelector } from '../selectors/editor-selector';
import { framePickerSelector } from '../selectors/frame-picker-selector';
import TimelineFrame from '../timeline/timeline-frame';

import LayerPicker from './layer-picker';

export const FramePickerInner = () => {
  const dispatch = useDispatch();
  const { width, height } = useSelector(animationSelector);
  const uniqueSequenceItems = useSelector(framePickerSelector);

  const {
    currentLayerIndex,
    framePickerMode,
    currentFrameIndex,
    framePickerLayerMode,
  } = useSelector(editorSelector);

  const onFrameSelected = (sourceFrameIndex) => {
    dispatch(editorActions.undoStackPush());

    if (framePickerMode === 'insert') {
      dispatch(
        animationActions.addFromFrame(
          sourceFrameIndex,
          currentFrameIndex + 1,
          framePickerLayerMode
        )
      );
      dispatch(editorActions.goToFrame(currentFrameIndex + 1));
    } else if (framePickerMode === 'overwrite') {
      dispatch(
        animationActions.replaceFrame(
          sourceFrameIndex,
          currentFrameIndex,
          framePickerLayerMode
        )
      );
    } else if (framePickerMode === 'overwrite-all') {
      dispatch(
        animationActions.replaceAllFrames(
          sourceFrameIndex,
          framePickerLayerMode
        )
      );
    }
  };

  const frames = uniqueSequenceItems.map((sequenceItem) => {
    return (
      <TimelineFrame
        layers={sequenceItem.layers}
        currentLayer={currentLayerIndex}
        index={sequenceItem.index + 1}
        key={`${sequenceItem.id}-${sequenceItem.index}`}
        id={sequenceItem.index}
        onFrameSelected={onFrameSelected}
        width={width}
        height={height}
        showCounter={true}
      />
    );
  });

  return (
    <Fragment>
      <button
        className="framepicker-close"
        onClick={() => dispatch(editorActions.toggleFramePicker())}
      >
        <i className="fas fa-times-circle" />
      </button>
      <TabBar
        selected={framePickerMode}
        name="picker-mode"
        onChange={(mode) => {
          dispatch(editorActions.setFramePickerMode(mode));
        }}
        options={[
          { id: 'insert', value: 'insert', label: 'Copy to new' },
          { id: 'overwrite', value: 'overwrite', label: 'Replace' },
          {
            id: 'overwrite-all',
            value: 'overwrite-all',
            label: 'Replace whole layer',
          },
        ]}
      />

      <LayerPicker />

      <p className="p-4">
        Choose frames to copy (duplicated frames are hidden)
      </p>
      <ol className="framepicker-list overflow-y-scroll">{frames}</ol>
    </Fragment>
  );
};
