import _set from 'lodash.set';

const defaultState = {};

export default function (state = defaultState, action) {
  switch (action.type) {
    case 'PAGE_DATA/SET':
      return _set({ ...state }, action.payload.key, action.payload.value);
    case 'PAGE_DATA/CLEAR':
      return { ...defaultState };
  }
  return state;
}
