import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/button';
import { animationSelector } from '../../components/editor/selectors/animation-selector';
import { ButtonBar, Module } from '../../layouts';
import { ButtonGroup } from '../../layouts/button-group';
import animationActions from '../../lib/animation/animation-actions';
import { ModalComponentProps } from '../modal';

import ModalTitle from './modal-title';

const EditLayersModal = (props: ModalComponentProps) => {
  const dispatch = useDispatch();
  const animationState = useSelector(animationSelector);

  const formattedLayers = animationState.layers
    .map((name, index) => ({
      index,
      name,
    }))
    .reverse();

  return (
    <div>
      <ModalTitle>Edit Layers</ModalTitle>
      <Module>
        {animationState.layers.length < 5 && (
          <Button
            icon="fas fa-plus-circle"
            onClick={() => dispatch(animationActions.addLayer())}
            type="neutral"
          >
            Add layer
          </Button>
        )}
        <ol>
          {formattedLayers.map((layer, index) => {
            return (
              <li key={index} className="p-1">
                <strong>{layer.index + 1}:</strong>{' '}
                <input
                  className="text-sm rounded-md w-40 shadow-inner p-1 border border-gray-400 mr-2"
                  type="text"
                  maxLength={10}
                  value={layer.name}
                  onChange={(e) => {
                    dispatch(
                      animationActions.renameLayer({
                        index: layer.index,
                        name: e.target.value,
                      })
                    );
                  }}
                />
                {layer.index > 0 && (
                  <Button
                    icon="fas fa-trash-alt"
                    onClick={() =>
                      dispatch(
                        animationActions.deleteLayer({ index: layer.index })
                      )
                    }
                    type="negative"
                  >
                    Delete
                  </Button>
                )}
              </li>
            );
          })}
        </ol>
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button icon="fas fa-check" onClick={props.hideModal} type="positive">
            Done
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
};

export default EditLayersModal;
