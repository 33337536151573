import React from 'react';
import { useDispatch } from 'react-redux';

import Button from '../../components/button';
import { ButtonBar, Module } from '../../layouts';
import { ButtonGroup } from '../../layouts/button-group';
import { showModal } from '../../lib/modal/modal-actions';
import { ModalComponentProps } from '../modal';

import ModalTitle from './modal-title';

export default function UserUnverified(props: ModalComponentProps) {
  const dispatch = useDispatch();

  return (
    <div className="text-center">
      <ModalTitle>Email Not Verified</ModalTitle>
      <Module>
        <p>
          To prevent abuse of this website, you must verify your email address
          before posting or creating animations in the public gallery. Please
          check your emails and click the link.
        </p>
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-user-cog"
            onClick={() => {
              dispatch(showModal('UserDetailsModal'));
            }}
            type="neutral"
            rightAlign
          >
            Check &amp; update email
          </Button>
          <Button
            icon="fas fa-check-circle"
            onClick={props.hideModal}
            type="positive"
            rightAlign
          >
            Ok
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
}
