import React, { FC, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';

import {
  Animation,
  CompetitionVoteAction,
  Thread,
} from '../../../gql/generated/graphql';
import { MUTATE_COMPETITION_VOTE } from '../../../gql/queries/mutate-competition-vote';
import randomSort from '../../../lib/random-sort';
import { useModal } from '../../modal/use-modal';

import ThreadAnimationEntity from './thread-animation-entity';

type ThreadCompetitionEntriesProps = {
  thread: Thread;
};

const ThreadCompetitionEntries: FC<ThreadCompetitionEntriesProps> = ({
  thread,
}) => {
  const [entries, setEntries] = useState<Animation[]>([]);
  const { showModal } = useModal();
  const [mutateCompetitionVote] = useMutation(MUTATE_COMPETITION_VOTE, {
    onError: (e) => {
      const { code } = e.graphQLErrors[0].extensions;
      switch (code) {
        case 'USER_UNVERIFIED':
          showModal('UserUnverifiedModal');
          break;
      }
    },
  });
  const { userVotes } = thread.voteSummary;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    let interval;

    interval = setInterval(() => {
      const hasTimeLeft = !dayjs().isAfter(dayjs(thread.endDate));
      if (hasTimeLeft !== isOpen) {
        setIsOpen(hasTimeLeft);
      }
    }, 1000);

    return function cleanup() {
      clearInterval(interval);
      interval = null;
    };
  }, [isOpen]);

  const onEntryVoted = (animationId) => {
    mutateCompetitionVote({
      variables: {
        animationId,
        threadId: thread.id,
        action: userVotes.includes(animationId)
          ? CompetitionVoteAction.Remove
          : CompetitionVoteAction.Add,
      },
    });
  };

  useEffect(() => {
    const entries: Animation[] = [];
    const foundAnimationIds = {};

    thread.posts.forEach((post) => {
      post.blocks.forEach((block) => {
        block.entities.forEach((entity) => {
          if (
            entity.__typename === 'Animation' &&
            !foundAnimationIds[entity.url] &&
            entity.user.id === post.userId
          ) {
            entries.push({ ...entity });
            foundAnimationIds[entity.url] = true;
          }
        });
      });
    });

    setEntries(randomSort(entries));
  }, [thread.posts.length]);

  if (entries.length === 0) {
    return null;
  }

  return (
    <div className="thread-competition-entries bg-gray-200 rounded-lg text-center p-2 shadow-md">
      <h3>All entries (randomly ordered)</h3>
      <div className="flex flex-wrap justify-around items-center">
        {entries.map((entry) => {
          const voteSummaryEntry = thread.voteSummary.animationVotes.find(
            (voteEntry) => voteEntry.animationId === entry.id
          );

          const votes = voteSummaryEntry ? voteSummaryEntry.votes : 0;

          return (
            <ThreadAnimationEntity
              key={entry.url}
              animation={entry}
              voteButton={isOpen}
              onVotePressed={onEntryVoted}
              voted={userVotes.includes(entry.id)}
              votes={votes}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ThreadCompetitionEntries;
