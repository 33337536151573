import { graphql } from '../generated';

export const MUTATE_ANIMATION = graphql(`
  mutation MutateAnimation(
    $id: Int
    $title: String
    $sequence: String
    $public: Boolean
    $palette: [String]
    $blacklisted: Boolean
    $width: Int
    $height: Int
    $layers: [String]
    $version: Int
    $schemaVersion: Int
    $requestEncode: Boolean
    $folderId: String
    $pixelMode: PixelMode
    $type: AnimationType
  ) {
    animation(
      id: $id
      title: $title
      sequence: $sequence
      public: $public
      palette: $palette
      blacklisted: $blacklisted
      width: $width
      height: $height
      layers: $layers
      version: $version
      schemaVersion: $schemaVersion
      requestEncode: $requestEncode
      folderId: $folderId
      pixelMode: $pixelMode
      type: $type
    ) {
      id
      title
      sequence
      public
      palette
      blacklisted
      url
      frames
      layers
      version
      schemaVersion
      folderId
      pixelMode
      type
    }
  }
`);
