import React, { MouseEvent, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import Button from '../../components/button';
import { TextInput } from '../../components/forms';
import PageHeading from '../../components/page-heading';
import ThemedPage from '../../components/themed-page';
import UserError from '../../components/user-error';
import { ButtonBar, Main, Module } from '../../layouts';
import { ButtonGroup } from '../../layouts/button-group';
import {
  clearErrorsAction,
  setErrorAction,
} from '../../lib/error/error-actions';
import { errorSelector } from '../../lib/error/error-selector';
import { useAppDispatch } from '../../store';

import { ERROR_MESSAGES } from './constants';
import registerActions from './register-actions';

const RegistrationForm = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const errorState = useSelector(errorSelector);

  const inputUsernameRef = useRef<HTMLInputElement>();
  const inputPasswordRef = useRef<HTMLInputElement>();
  const inputConfirmPasswordRef = useRef<HTMLInputElement>();
  const inputEmailRef = useRef<HTMLInputElement>();

  const onRegisterClicked = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(clearErrorsAction());

    if (
      inputPasswordRef.current.value !== inputConfirmPasswordRef.current.value
    ) {
      return dispatch(
        setErrorAction('REGISTRATION_FAILED', ['ERR_PASSWORDS_MATCH'])
      );
    }

    dispatch(
      registerActions.register(
        inputUsernameRef.current.value,
        inputPasswordRef.current.value,
        inputEmailRef.current.value,
        history
      )
    );
  };

  useEffect(() => {
    inputUsernameRef.current.focus();
    return function cleanup() {
      dispatch(clearErrorsAction());
    };
  }, []);

  const errors =
    errorState.REGISTRATION_FAILED?.map((e) => {
      return ERROR_MESSAGES[e];
    }) ?? [];

  return (
    <ThemedPage>
      <Main small>
        <Helmet>
          <title>The Animator - Register a new account</title>
          <meta
            name="description"
            content="Create an account to save your animations"
          />
        </Helmet>
        <PageHeading>Register a new account</PageHeading>
        <Module>
          <p>
            Please enter a few details so that you can retrieve and edit your
            animations in the future. If you already have an account, please{' '}
            <Link to="/sign-in">sign-in</Link>.
          </p>
        </Module>
        <Module>
          <p>Your email address is only used for password reset emails.</p>
        </Module>
        <UserError errors={errors} />
        <form>
          <Module>
            <TextInput
              id="username"
              type="text"
              maxLength="30"
              ref={inputUsernameRef}
              autoCapitalize="none"
              label="User name: (No spaces, alphanumeric, less than 30 chars)"
            />
            <TextInput
              id="email"
              type="email"
              ref={inputEmailRef}
              autoCapitalize="none"
              label="Email address:"
            />
            <p>
              This must be a valid email address which can receive emails from
              The Animator. Accounts without verified email addresses cannot
              post animations or comments.
            </p>
            <TextInput
              id="password"
              type="password"
              ref={inputPasswordRef}
              label="Password: (At least 4 characters)"
            />
            <TextInput
              id="password-confirm"
              type="password"
              ref={inputConfirmPasswordRef}
              label="Re-enter password:"
            />
          </Module>

          <ButtonBar>
            <ButtonGroup align="right">
              <Button
                icon="fas fa-user-circle"
                onClick={onRegisterClicked}
                rightAlign
                data-testid="register-button"
              >
                Create a new account
              </Button>
            </ButtonGroup>
          </ButtonBar>
        </form>
      </Main>
    </ThemedPage>
  );
};

export default RegistrationForm;
