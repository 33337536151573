import { InteractiveCanvasPane } from './interactive-canvas-pane';

let canvasPaneInstance = null;

export const getCanvasPane = (): InteractiveCanvasPane => {
  if (!canvasPaneInstance) {
    canvasPaneInstance = new InteractiveCanvasPane();
  }

  return canvasPaneInstance;
};
