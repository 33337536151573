import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../components/button';
import { animationSelector } from '../../../components/editor/selectors/animation-selector';
import { editorSelector } from '../../../components/editor/selectors/editor-selector';
import { TextInput } from '../../../components/forms';
import UserError from '../../../components/user-error';
import { ButtonBar, Module } from '../../../layouts/';
import { ButtonGroup } from '../../../layouts/button-group';
import { anibotFader } from '../../../lib/anibot/anibot-actions';
import { ModalComponentProps } from '../../modal';
import ModalTitle from '../modal-title';

export default function AnibotFadeModal(props: ModalComponentProps) {
  const dispatch = useDispatch();
  const [insertMode, setInsertMode] = useState('insert');
  const [errors, setErrors] = useState([]);
  const toPercentRef = useRef<HTMLInputElement>();
  const fromPercentRef = useRef<HTMLInputElement>();
  const numFramesRef = useRef<HTMLInputElement>();
  const animation = useSelector(animationSelector);
  const editor = useSelector(editorSelector);

  const onInsertModeChanged = (e) => {
    setInsertMode(e.currentTarget.value);
  };

  const onSubmit = () => {
    const fromPercent = parseInt(fromPercentRef.current.value, 10);
    const toPercent = parseInt(toPercentRef.current.value, 10);
    const frameCount = parseInt(numFramesRef.current.value, 10);
    const errorsToShow = [];

    // Validation
    if (
      insertMode === 'replace' &&
      frameCount + editor.currentFrameIndex > animation.sequence.length
    ) {
      errorsToShow.push(
        `Animation is not long enough to fade ${frameCount} frames.`
      );
    }

    if (isNaN(fromPercent) || isNaN(toPercent) || isNaN(frameCount)) {
      errorsToShow.push(`Enter valid numbers.`);
    }

    if (errorsToShow.length) {
      return setErrors(errorsToShow);
    } else {
      setErrors([]);
    }

    dispatch(
      anibotFader({
        from: fromPercent,
        to: toPercent,
        frameCount,
        insertMode,
      })
    );
    props.hideModal();
  };

  return (
    <div className="text-center">
      <ModalTitle>
        <i className="fas fa-robot" /> Fade Layer
      </ModalTitle>
      <Module>
        <p>Fade the current layer automatically.</p>
      </Module>
      <Module>
        <input
          type="radio"
          name="insertMode"
          value="insert"
          id="move-insert"
          checked={insertMode === 'insert'}
          onChange={onInsertModeChanged}
        />{' '}
        <label htmlFor="move-insert">Insert new frames</label>{' '}
        <input
          type="radio"
          name="insertMode"
          value="replace"
          id="move-replace"
          checked={insertMode === 'replace'}
          onChange={onInsertModeChanged}
        />{' '}
        <label htmlFor="move-replace">Use existing frames</label>
      </Module>
      <Module>
        <p>Choose the start and end opacity:</p>
        <TextInput
          id="opacity_from"
          size="5"
          inline
          label="start %"
          defaultValue="100"
          type="number"
          className="w-20"
          ref={fromPercentRef}
          max="100"
          min="0"
        />
        <TextInput
          id="opacity_to"
          size="5"
          inline
          label="end %"
          defaultValue="0"
          type="number"
          className="w-20"
          ref={toPercentRef}
          max="100"
          min="0"
        />
        <TextInput
          id="frameCount"
          size="5"
          inline
          label="num frames"
          defaultValue="2"
          className="w-20"
          type="number"
          max="100"
          min="2"
          ref={numFramesRef}
        />
        <UserError errors={errors} />
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-times-circle"
            onClick={props.hideModal}
            type="negative"
            rightAlign
          >
            Cancel
          </Button>
          <Button
            icon="fas fa-robot"
            onClick={onSubmit}
            type="positive"
            rightAlign
          >
            Run Tweenbot
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
}
