import React, { FC, MouseEvent, useState } from 'react';

import Button from '../../../components/button';

import TimePickerPreview from './preview';

type TimePickerProps = {
  onChange: ({ applyAll, delay }: { applyAll: boolean; delay: number }) => void;
  currentValue: number;
  onClosePressed: (e: MouseEvent) => void;
  visible: boolean;
};

const TimePicker: FC<TimePickerProps> = (props) => {
  const [applyAll, setApplyAll] = useState(false);

  const onDelayClicked = (e) => {
    props.onChange({
      applyAll: applyAll,
      delay: e.currentTarget.dataset.delay,
    });

    setApplyAll(false);
  };

  const applyAllClicked = () => {
    setApplyAll(!applyAll);
  };

  const getApplyAllButton = () => {
    const buttonType = applyAll ? 'positive' : 'inactive';

    const icon = applyAll ? 'fas fa-check' : 'fas fa-times';
    return (
      <Button icon={icon} type={buttonType} onClick={applyAllClicked}>
        Apply to all frames
      </Button>
    );
  };

  const visibilityClass = props.visible ? ' timepicker-container--visible' : '';

  if (!props.visible) return null;

  return (
    <div className={`timepicker-container${visibilityClass}`}>
      <button className="timepicker-close" onClick={props.onClosePressed}>
        <i className="fas fa-times-circle" />
      </button>
      <p>Frame speed</p>
      <div>{getApplyAllButton()}</div>
      <ol className="flex mt-4 space-x-2 leading-4">
        <TimePickerPreview
          delay={25}
          onClick={onDelayClicked}
          currentValue={props.currentValue}
        />
        <TimePickerPreview
          delay={50}
          onClick={onDelayClicked}
          currentValue={props.currentValue}
        />
        <TimePickerPreview
          delay={100}
          onClick={onDelayClicked}
          currentValue={props.currentValue}
        />
        <TimePickerPreview
          delay={200}
          onClick={onDelayClicked}
          currentValue={props.currentValue}
        />
        <TimePickerPreview
          delay={300}
          onClick={onDelayClicked}
          currentValue={props.currentValue}
        />
        <TimePickerPreview
          delay={400}
          onClick={onDelayClicked}
          currentValue={props.currentValue}
        />
        <TimePickerPreview
          delay={500}
          onClick={onDelayClicked}
          currentValue={props.currentValue}
        />
      </ol>
    </div>
  );
};

export default TimePicker;
