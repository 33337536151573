import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

import config from '../../../lib/config';
import Editor from '../../components/editor/editor';
import { Header } from '../../components/header/header';
import { hideModal } from '../../lib/modal/modal-actions';
import { clearPageDataAction } from '../../lib/page-data/page-data-actions';
import Modal from '../../modal/modal';
import { useAppDispatch } from '../../store';
import AnimationPage from '../animation-page';
import ChangePasswordForm from '../change-password-form';
import ComicPage from '../comic-page';
import CommunityPage from '../community-page';
import CommunityPaginated from '../community-paginated';
import EditorLoadFailurePage from '../editor-load-failure';
import FollowingPage from '../following-page';
import GalleryPage from '../gallery-page';
import Home from '../home';
import MyStudio from '../my-studio';
import NotFound from '../not-found';
import NotificationsPage from '../notifications-page';
import { OauthSignInPage } from '../oauth-sign-in/oauth-sign-in';
import PasswordResetSent from '../password-reset-sent';
import PrivacyPage from '../privacy';
import RegistrationForm from '../registration-form';
import ReportAProblemPage from '../report-a-problem';
import RequestPasswordResetEmailForm from '../request-password-reset-email-form';
import SchoolsPage from '../schools';
import SearchPage from '../search-page';
import SignInForm from '../sign-in-form';
import Studio from '../studio';
import TermsPage from '../terms';
import ThreadPage from '../thread-page';
import VerifiedPage from '../verified-page';

import { RootErrorBoundary } from './root-error-boundary';
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few secs',
    m: 'a min',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mo',
    MM: '%dmo',
    y: '1yr',
    yy: '%dyr',
  },
});

const Root = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(clearPageDataAction());
    dispatch(hideModal());
    if (config.features.analytics) {
      window.ga('set', 'page', location.pathname + location.search);
      window.ga('send', 'pageview');
    }

    window.scrollTo(0, 0);
  }, [location]);
  return (
    <RootErrorBoundary>
      <Helmet>
        <title>The Animator</title>
      </Helmet>
      <Header />
      <Switch>
        <Route path="/editor" exact component={Editor} />
        <Route path="/editor/:animationUrl" exact component={Editor} />
        <Route
          path="/editor/load-error/:animationUrl"
          exact
          component={EditorLoadFailurePage}
        />
        <Route path="/gallery" exact component={GalleryPage} />
        <Route exact path="/gallery/following/" component={FollowingPage} />
        <Route
          path="/gallery/following/:currentPage"
          component={FollowingPage}
        />
        <Route path="/gallery/:currentPage" component={GalleryPage} />
        <Route path="/animation/:animationUrl" component={AnimationPage} />
        <Route path="/comic/:comicUrl" component={ComicPage} />
        <Route path="/register" component={RegistrationForm} />
        <Route path="/sign-in" component={SignInForm} />
        <Route path="/oauth-sign-in" component={OauthSignInPage} />
        <Route path="/verified" component={VerifiedPage} />
        <Route path="/my-studio/:currentPage?" component={MyStudio} />
        <Route path="/studio/:username/:currentPage?" component={Studio} />
        <Route path="/community" exact component={CommunityPage} />
        <Route
          path="/community/:type/:currentPage?"
          exact
          component={CommunityPaginated}
        />
        <Route
          path="/community/general/thread/:threadId"
          exact
          component={ThreadPage}
        />
        <Route
          path="/password-reset"
          component={RequestPasswordResetEmailForm}
        />
        <Route path="/password-reset-sent" component={PasswordResetSent} />
        <Route path="/reset/:resetId" component={ChangePasswordForm} />
        <Route path="/notifications" component={NotificationsPage} />
        <Route path="/terms" component={TermsPage} />
        <Route path="/privacy" component={PrivacyPage} />
        <Route path="/schools" component={SchoolsPage} />
        <Route path="/report-a-problem" component={ReportAProblemPage} />
        <Route path="/search" component={SearchPage} />
        <Route path="/" exact component={Home} />
        <Route component={NotFound} />
      </Switch>
      <Modal />
    </RootErrorBoundary>
  );
};

export default Root;
