import React from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';

import { MUTATE_USER } from '../../../gql/queries/mutate-user';
import Button from '../../components/button';
import { ButtonBar, Module } from '../../layouts';
import { ButtonGroup } from '../../layouts/button-group';
import currentUserSelector from '../../lib/selectors/current-user-selector';
import { setUserAvatarAction } from '../../lib/user/user-actions';
import { useAppDispatch } from '../../store';
import { useModal } from '../use-modal';

import ModalTitle from './modal-title';

export default function ConfirmSetAvatarModal({
  animationUrl,
}: {
  animationUrl: string;
}) {
  const { hideModal } = useModal();
  const currentUser = useSelector(currentUserSelector);
  const [mutateUser] = useMutation(MUTATE_USER);
  const dispatch = useAppDispatch();

  const onSetClicked = async () => {
    await mutateUser({
      variables: { id: currentUser.id, avatarUrl: animationUrl },
    });
    dispatch(setUserAvatarAction(animationUrl));
    hideModal();
  };

  const onCancelClicked = () => {
    hideModal();
  };

  return (
    <div>
      <ModalTitle>Set Profile Picture</ModalTitle>
      <Module>
        <p>Do you want to set this as your profile picture?</p>
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button icon="fas fa-portrait" onClick={onSetClicked} type="positive">
            Set profile picture
          </Button>
          <Button
            icon="fas fa-times-circle"
            onClick={onCancelClicked}
            type="negative"
          >
            Not now
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
}
