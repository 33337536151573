import React, { MouseEvent, useState } from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { useSelector } from 'react-redux';

import Button from '../../components/button';
import { animationSelector } from '../../components/editor/selectors/animation-selector';
import { ButtonBar, Module } from '../../layouts';
import { ButtonGroup } from '../../layouts/button-group';
import animationActions from '../../lib/animation/animation-actions';
import { useAppDispatch } from '../../store';
import { ModalComponentProps } from '../modal';

import ModalTitle from './modal-title';

const PaletteModal = (props: ModalComponentProps) => {
  const [currentColourIndex, setCurrentColourIndex] = useState(0);
  const dispatch = useAppDispatch();
  const { palette } = useSelector(animationSelector);

  const onSwatchPressed = (e: MouseEvent<HTMLButtonElement>) => {
    const newIndex = parseInt(e.currentTarget.dataset.colourIndex, 10);
    setCurrentColourIndex(newIndex);
  };

  const onColorChange = (newHex: string) => {
    dispatch(animationActions.setColourAction(currentColourIndex, newHex));
  };

  const renderSwatches = () => {
    return palette.map((colour, index) => (
      <li key={index}>
        <button
          onClick={onSwatchPressed}
          data-colour-index={index}
          className={`palette-modal__swatch ${
            currentColourIndex === index
              ? 'palette-modal__swatch--selected'
              : ''
          }`}
          style={{ backgroundColor: colour }}
        />
      </li>
    ));
  };

  return (
    <div>
      <ModalTitle>Edit Palette</ModalTitle>
      <Module>
        <ol className="palette-modal__swatches">{renderSwatches()}</ol>
        <HexColorPicker
          color={palette[currentColourIndex]}
          onChange={onColorChange}
        />
        <div className="palette-modal__hex-input-container">
          Hex code:{' '}
          <HexColorInput
            color={palette[currentColourIndex]}
            onChange={onColorChange}
            className="palette-modal__hex-input rounded-md shadow-inner p-1 border border-gray-300"
          />
        </div>
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-check-circle"
            onClick={props.hideModal}
            type="positive"
            rightAlign
          >
            Done
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
};

export default PaletteModal;
