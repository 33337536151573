import React from 'react';
import PropTypes from 'prop-types';

const PageHeading = ({ children, ...props }) => {
  return (
    <h2
      className="mb-0 px-2 sm:px-4 py-2 sm:rounded-t-md bg-green-50 shadow-md break-words"
      {...props}
    >
      {children}
    </h2>
  );
};

PageHeading.propTypes = {
  children: PropTypes.node.isRequired,
  wide: PropTypes.bool,
  light: PropTypes.bool,
};

export default PageHeading;
