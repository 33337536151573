import editorActions from 'components/editor/editor-actions';

import { dataUrlToImg, moveWrapped } from '../../lib/animation/canvas-utils';
import animationActions from '../animation/animation-actions';

export const anibotMover = ({ x, y, frameCount, insertMode, wrap }) => {
  return async (dispatch, getState) => {
    const { animation, editor } = getState();
    const { sequence } = animation;
    const { currentFrameIndex, currentLayerIndex } = editor;
    const initialFrame = sequence[currentFrameIndex];

    const xStep = x / frameCount;
    const yStep = y / frameCount;

    dispatch(editorActions.undoStackPush());

    const destinationCanvas = document.createElement('canvas');
    destinationCanvas.width = animation.width;
    destinationCanvas.height = animation.height;
    const destinationCtx = destinationCanvas.getContext('2d');

    const sourceCanvas = document.createElement('canvas');
    sourceCanvas.width = animation.width;
    sourceCanvas.height = animation.height;
    const sourceCtx = sourceCanvas.getContext('2d');

    for (let index = 0; index < frameCount; index++) {
      const frameIndex = index + currentFrameIndex;
      destinationCtx.clearRect(
        0,
        0,
        destinationCanvas.width,
        destinationCanvas.height
      );
      sourceCtx.clearRect(0, 0, sourceCanvas.width, sourceCanvas.height);

      let referenceImage;

      if (insertMode === 'insert') {
        dispatch(animationActions.addFrame(frameIndex + 1, initialFrame.delay));
        referenceImage = await dataUrlToImg(
          sequence[currentFrameIndex].layers[currentLayerIndex].image.getData()
        );
        sourceCtx.drawImage(referenceImage, 0, 0);

        if (wrap) {
          moveWrapped({
            sourceCtx,
            destinationCtx,
            moveX: (xStep * (index + 1)) % sourceCtx.canvas.width,
            moveY: (yStep * (index + 1)) % sourceCtx.canvas.height,
          });
        } else {
          destinationCtx.drawImage(
            referenceImage,
            xStep * (index + 1),
            yStep * (index + 1)
          );
        }

        dispatch(
          animationActions.updateFrame(frameIndex + 1, destinationCanvas)
        );
      } else {
        referenceImage = await dataUrlToImg(
          sequence[frameIndex].layers[currentLayerIndex].image.getData()
        );
        sourceCtx.drawImage(referenceImage, 0, 0);
        if (wrap) {
          moveWrapped({
            sourceCtx,
            destinationCtx,
            moveX: (xStep * index) % sourceCtx.canvas.width,
            moveY: (yStep * index) % sourceCtx.canvas.height,
          });
        } else {
          destinationCtx.drawImage(
            referenceImage,
            xStep * index,
            yStep * index
          );
        }
        dispatch(animationActions.updateFrame(frameIndex, destinationCanvas));
      }
    }
  };
};

export const anibotFader = ({ from, to, frameCount, insertMode }) => {
  return async (dispatch, getState) => {
    const { animation, editor } = getState();
    const { sequence } = animation;
    const { currentFrameIndex, currentLayerIndex } = editor;
    const initialFrame = sequence[currentFrameIndex];

    const fadeStep = (to - from) / frameCount;

    dispatch(editorActions.undoStackPush());

    const canvas = document.createElement('canvas');
    canvas.width = animation.width;
    canvas.height = animation.height;
    const ctx = canvas.getContext('2d');

    for (let index = 0; index < frameCount; index++) {
      const frameIndex = index + currentFrameIndex;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      let referenceImage;

      if (insertMode === 'insert') {
        dispatch(animationActions.addFrame(frameIndex + 1, initialFrame.delay));
        referenceImage = await dataUrlToImg(
          sequence[currentFrameIndex].layers[currentLayerIndex].image.getData()
        );

        ctx.globalAlpha = (from + fadeStep * (index + 1)) / 100;
        ctx.drawImage(referenceImage, 0, 0);

        dispatch(animationActions.updateFrame(frameIndex + 1, canvas));
      } else {
        referenceImage = await dataUrlToImg(
          sequence[frameIndex].layers[currentLayerIndex].image.getData()
        );

        ctx.globalAlpha = (from + fadeStep * index) / 100;
        ctx.drawImage(referenceImage, 0, 0);
        dispatch(animationActions.updateFrame(frameIndex, canvas));
      }
    }
  };
};
