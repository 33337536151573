import { graphql } from '../generated';

export const ANIMATION_PAGE = graphql(`
  query GetAnimation($slug: String) {
    animation(slug: $slug) {
      id
      url
      title
      version
      userId
      dateCreated
      dateUpdated
      sequence
      views
      blacklisted
      public
      width
      height
      schemaVersion
      encoded
      frames
      pixelMode
      user {
        ...CoreUserFields
      }
      reactions {
        id
        love
        laugh
        dizzy
        stars
      }
      reactionsBreakdown {
        id
        type
        user {
          id
          name
        }
      }
      olderAnimations {
        id
        title
        userId
        version
        url
        pixelMode
        type
        reactions {
          love
          laugh
          dizzy
          stars
        }
        commentCount
        encoded
        schemaVersion
      }
      thread {
        id
        userId
        title
        locked
        pinned
        type
        posts {
          ...PostsFields
        }
      }
    }
  }
`);
