import React, { useState } from 'react';
import { Module } from 'layouts';

import TabBar from 'components/tab-bar/tab-bar';

import ManageFoldersCreate from './manage-folders/create-folder';
import ManageFoldersDelete from './manage-folders/delete-folder';
import ModalTitle from './modal-title';

const ManageFoldersModal = () => {
  const [activeTab, setActiveTab] = useState('create');

  return (
    <div>
      <ModalTitle>Manage Folders</ModalTitle>
      <Module>
        <TabBar
          selected={activeTab}
          name="folderOptions"
          onChange={(mode) => {
            setActiveTab(mode);
          }}
          options={[
            { id: 'create', value: 'create', label: 'Create folder' },
            { id: 'delete', value: 'delete', label: 'Delete folder' },
          ]}
        />
      </Module>
      {activeTab === 'create' && <ManageFoldersCreate />}
      {activeTab === 'delete' && <ManageFoldersDelete />}
    </div>
  );
};

export default ManageFoldersModal;
