import { v1 as uuidv1 } from 'uuid';

import editorActions from 'components/editor/editor-actions';
import {
  EDITOR_DB_SAVE_STATE,
  EDITOR_DB_STATE,
} from 'components/editor/editor-reducer';

import { getBlankImage } from '../../components/editor/canvas/lib/blank-image';
import { FrameImage } from '../../components/editor/canvas/lib/frame-image';
import { loadAll, reset, sync } from '../../lib/animation/animation-db';

const loadFromStorage = function () {
  return async () => {
    const animationData = await loadAll();

    if (animationData) {
      return animationData;
    } else {
      return false;
    }
  };
};

const addFrame = function (index, delay) {
  return (dispatch) => {
    dispatch({
      type: 'ANIMATION/ADD_FRAME',
      payload: {
        index,
        delay,
      },
    });
  };
};

const deleteFrame = function (index) {
  return (dispatch, getState) => {
    const { sequence } = getState().animation;

    if (sequence.length === 1) {
      dispatch(addFrame(1, sequence[0].delay));
    }

    dispatch({
      type: 'ANIMATION/DELETE_FRAME',
      payload: {
        index,
      },
    });
  };
};

const updateFrame = function (index, canvas, id) {
  return (dispatch, getState) => {
    const imageToStore = new FrameImage({ from: canvas, id });

    const { currentLayerIndex } = getState().editor;

    dispatch({
      type: 'ANIMATION/UPDATE_FRAME',
      payload: {
        index,
        image: imageToStore,
        layer: currentLayerIndex,
      },
    });
  };
};

const updateCurrentFrame = (canvas, id) => {
  return (dispatch, getState) => {
    const { currentFrameIndex } = getState().editor;
    return dispatch(updateFrame(currentFrameIndex, canvas, id));
  };
};

export const setFrameDelayAction = function (id, delay, applyAll) {
  return (dispatch) => {
    dispatch({
      type: 'ANIMATION/SET_FRAME_DELAY',
      payload: {
        index: id,
        delay,
        applyAll,
      },
    });
  };
};

const addFromFrame = function (sourceIndex, destinationIndex, layersToCopy) {
  return (dispatch, getState) => {
    const frame = getState().animation.sequence[sourceIndex];
    const { animation } = getState();

    const layers = layersToCopy.map((shouldCopy, index) => {
      if (shouldCopy) {
        return {
          id: uuidv1(),
          image: frame.layers[index].image,
        };
      } else {
        return {
          id: uuidv1(),
          image: getBlankImage({
            width: animation.width,
            height: animation.height,
          }),
        };
      }
    });

    dispatch({
      type: 'ANIMATION/ADD_FROM_FRAME',
      payload: {
        sourceIndex,
        destinationIndex,
        layers,
      },
    });
  };
};

const replaceFrame = function (sourceIndex, destinationIndex, layersToCopy) {
  return (dispatch, getState) => {
    const sourceFrame = getState().animation.sequence[sourceIndex];
    const destinationFrame = getState().animation.sequence[destinationIndex];

    const layers = layersToCopy.map((shouldCopy, index) => {
      if (shouldCopy) {
        return {
          id: uuidv1(),
          image: sourceFrame.layers[index].image,
        };
      } else {
        return {
          id: uuidv1(),
          image: destinationFrame.layers[index].image,
        };
      }
    });

    dispatch({
      type: 'ANIMATION/REPLACE_FRAME',
      payload: {
        sourceIndex,
        destinationIndex,
        layers,
      },
    });
  };
};

const replaceAllFrames = function (sourceIndex, layersToCopy) {
  return (dispatch, getState) => {
    const allFrames = getState().animation.sequence;

    allFrames.forEach((frame, index) => {
      dispatch(replaceFrame(sourceIndex, index, layersToCopy));
    });
  };
};

const set = function (animationProps) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'ANIMATION/SET',
      payload: { ...animationProps },
    });
    const { images } = getState();
    await sync(animationProps, images);
  };
};

const setSaved = function (isSaved) {
  return (dispatch) => {
    dispatch({
      type: 'ANIMATION/SET_SAVED',
      payload: isSaved,
    });
  };
};

const setId = function (id) {
  return (dispatch) => {
    dispatch({
      type: 'ANIMATION/SET_ID',
      payload: id,
    });
  };
};

const saveLocally = function () {
  return async (dispatch, getState) => {
    const supportedState = getState().editor.databaseState;
    const { animation } = getState();
    if (supportedState !== EDITOR_DB_STATE.SUPPORTED) {
      console.log('unsupported db');
      return;
    }
    dispatch(editorActions.setDbSaveState(EDITOR_DB_SAVE_STATE.PENDING));

    await sync(animation);

    dispatch(editorActions.setDbSaveState(EDITOR_DB_SAVE_STATE.SAVED));
  };
};

const createNew = function (options) {
  return async (dispatch) => {
    await reset();
    dispatch({
      type: 'ANIMATION/NEW',
      payload: { ...options },
    });
    dispatch(addFrame(0, 100));
  };
};

const setUrl = function (newUrl) {
  return {
    type: 'ANIMATION/SET_URL',
    payload: { url: newUrl },
  };
};

const setAnimationTitle = function (newTitle) {
  return (dispatch) => {
    dispatch({
      type: 'ANIMATION/SET_TITLE',
      payload: { title: newTitle },
    });
  };
};

const setAnimationPublic = function (isPublic) {
  return (dispatch) => {
    dispatch({
      type: 'ANIMATION/SET_PUBLIC',
      payload: { public: isPublic },
    });
  };
};

const setPixelMode = function (pixelMode) {
  return (dispatch) => {
    dispatch({
      type: 'ANIMATION/SET_PIXEL_MODE',
      payload: { pixelMode },
    });
  };
};

const setAnimationFolder = function (folderId) {
  return (dispatch) => {
    dispatch({
      type: 'ANIMATION/SET_FOLDER',
      payload: { folderId },
    });
  };
};

const setColourAction = function (index, colour) {
  return (dispatch) => {
    dispatch({
      type: 'ANIMATION/SET_COLOUR',
      payload: { index, colour },
    });
  };
};

const renameLayer = ({ index, name }) => {
  return {
    type: 'ANIMATION/LAYER_RENAME',
    payload: {
      index,
      name,
    },
  };
};

const addLayer = () => {
  return (dispatch, getState) => {
    dispatch({
      type: 'ANIMATION/LAYER_ADD',
    });
    dispatch(
      editorActions.setLayerVisibility({
        layerIndex: getState().animation.layers.length - 1,
        visible: true,
      })
    );
    dispatch(
      editorActions.setFramePickerLayerVisibility({
        layerIndex: getState().animation.layers.length - 1,
        visible: false,
      })
    );
  };
};

const deleteLayer = ({ index }) => {
  return (dispatch, getState) => {
    const { sequence } = getState().animation;
    const { currentLayerIndex } = getState().editor;
    const deletedLayerImages = {};

    sequence.forEach((frame) => {
      deletedLayerImages[frame.layers[index].imageId] = true;
    });

    if (currentLayerIndex >= index) {
      dispatch(editorActions.previousLayer());
    }

    dispatch({
      type: 'ANIMATION/LAYER_DELETE',
      payload: {
        index,
      },
    });

    dispatch(editorActions.resetEditorLayers());
  };
};

export default {
  addFrame,
  updateFrame,
  updateCurrentFrame,
  addFromFrame,
  deleteFrame,
  createNew,
  set,
  setUrl,
  setAnimationTitle,
  setAnimationPublic,
  setPixelMode,
  setColourAction,
  setSaved,
  saveLocally,
  loadFromStorage,
  setId,
  replaceFrame,
  replaceAllFrames,
  renameLayer,
  addLayer,
  deleteLayer,
  setAnimationFolder,
};
