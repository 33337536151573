import React, { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import { Animation } from '../../../gql/generated/graphql';
import { GET_COMIC } from '../../../gql/queries/get-comic';
import { MUTATE_ANIMATION } from '../../../gql/queries/mutate-animation';
import { MUTATE_COMIC } from '../../../gql/queries/mutations/mutate-comic';
import {
  Button,
  DropdownMenu,
  IfUserIs,
  PublicGallerySwitch,
  Reactions,
  ReactionSelector,
  ThemedHeading,
  ThemedPage,
  Thread,
  UserPill,
} from '../../components';
import ReactionsShower from '../../components/reactions/reactions-shower';
import { Main, Module } from '../../layouts';
import { useModal } from '../../modal/use-modal';
import { ReactionsBreakdown } from '../../pages/animation-page/reactions-breakdown';

import ComicFrame from './comic-frame';
import ComicPageLoading from './loading';

const colsClassMap = {
  1: 'md:grid-cols-1',
  2: 'md:grid-cols-2',
  3: 'md:grid-cols-3',
  4: 'md:grid-cols-4',
};

const ComicPage = () => {
  const { comicUrl } = useParams();
  const { data, loading } = useQuery(GET_COMIC, {
    variables: { slug: comicUrl },
  });

  const { showModal } = useModal();
  const [mutateComic] = useMutation(MUTATE_COMIC);
  const [mutateAnimation] = useMutation(MUTATE_ANIMATION);
  const [editMode, setEditMode] = useState(false);

  if (loading) return <ComicPageLoading></ComicPageLoading>;

  const onAddAnimationPressed = () => {
    showModal('LoadAnimationModal', {
      onSelected: (animation: Animation) => {
        mutateComic({
          variables: {
            comicId: data.comic.id,
            addAnimation: animation.id,
          },
        });
      },
    });
  };

  const onSetColumnsPressed = (numCols) => {
    mutateComic({
      variables: {
        comicId: data.comic.id,
        columns: numCols,
      },
    });
  };

  const onAnimationPublicToggled = () => {
    mutateAnimation({
      variables: {
        id: data.comic.parentAnimation.id,
        public: !data.comic.parentAnimation.public,
      },
    });
  };

  const onDeleteComicPressed = () => {
    showModal('ConfirmAnimationDeleteModal', {
      id: data.comic.parentAnimation.id,
    });
  };

  return (
    <ThemedPage theme={data.comic.user.theme}>
      <Main theme={data.comic.user.theme}>
        <Helmet>
          <title>The Animator - Comic</title>
        </Helmet>
        <ThemedHeading theme={data.comic.user.theme}>
          {data.comic.parentAnimation.title} by{' '}
          <UserPill user={data.comic.user} />
        </ThemedHeading>
        <IfUserIs username={data.comic.user.id}>
          {!editMode && (
            <Module>
              <Button
                type="positive"
                onClick={() => {
                  setEditMode(true);
                }}
              >
                <i className="fas fa-edit"></i> Edit Comic
              </Button>
            </Module>
          )}
          {editMode && (
            <Module>
              <Button
                type="positive"
                onClick={() => {
                  setEditMode(false);
                }}
              >
                <i className="fas fa-edit"></i> Finish Editing
              </Button>
              <PublicGallerySwitch
                onAnimationPublicToggled={onAnimationPublicToggled}
                isPublic={data.comic.parentAnimation.public}
              />
              <DropdownMenu
                button={{ text: 'Comic layout' }}
                items={[
                  {
                    text: (
                      <span>
                        1 col{' '}
                        {data.comic.columns === 1 && (
                          <i className="fas fa-check"></i>
                        )}
                      </span>
                    ),
                    onClick: () => onSetColumnsPressed(1),
                  },
                  {
                    text: (
                      <span>
                        2 cols{' '}
                        {data.comic.columns === 2 && (
                          <i className="fas fa-check"></i>
                        )}
                      </span>
                    ),
                    onClick: () => onSetColumnsPressed(2),
                  },
                  {
                    text: (
                      <span>
                        3 cols{' '}
                        {data.comic.columns === 3 && (
                          <i className="fas fa-check"></i>
                        )}
                      </span>
                    ),
                    onClick: () => onSetColumnsPressed(3),
                  },
                  {
                    text: (
                      <span>
                        4 cols{' '}
                        {data.comic.columns === 4 && (
                          <i className="fas fa-check"></i>
                        )}
                      </span>
                    ),
                    onClick: () => onSetColumnsPressed(4),
                  },
                ]}
              />{' '}
              <Button type="negative" onClick={onDeleteComicPressed}>
                <i className="fas fa-trash-alt"></i> Delete Comic
              </Button>
            </Module>
          )}
        </IfUserIs>
        <Module>
          <div
            className={`w-full grid gap-2 items-center grid-cols-1 ${
              colsClassMap[data.comic.columns]
            }`}
          >
            {data.comic.frames.map((frame) => {
              return (
                <ComicFrame
                  comic={data.comic}
                  key={frame.id}
                  frame={frame}
                  numFrames={data.comic.frames.length}
                  editMode={editMode}
                />
              );
            })}
            {editMode && (
              <IfUserIs username={data.comic.user.id}>
                <div className="bg-blue-50 border-2 border-gray-400 border-dotted text-center w-full h-full flex items-center justify-center p-5">
                  <Button type="positive" onClick={onAddAnimationPressed}>
                    <i className="fas fa-plus-circle"></i> Add animation
                  </Button>
                </div>
              </IfUserIs>
            )}
          </div>
        </Module>
        {!editMode && (
          <Fragment>
            <IfUserIs not username={data.comic.user.id}>
              <Module>
                <ReactionSelector
                  animationId={data.comic.parentAnimation.id}
                  reactionsBreakdown={
                    data.comic.parentAnimation.reactionsBreakdown
                  }
                />
              </Module>
            </IfUserIs>
            <Module>
              <div className="animation-page__reactions">
                <Reactions
                  reactions={data.comic.parentAnimation.reactions}
                  type="large"
                />
              </div>
            </Module>
            <Module>
              <ReactionsBreakdown
                reactions={data.comic.parentAnimation.reactionsBreakdown}
              />
            </Module>
            <Thread
              thread={data.comic.parentAnimation.thread}
              type="COMIC"
              animation={data.comic.parentAnimation}
            />
          </Fragment>
        )}
      </Main>
      <ReactionsShower
        reactions={data.comic.parentAnimation.reactionsBreakdown}
      />
    </ThemedPage>
  );
};

export default ComicPage;
