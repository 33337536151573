import React, { FC, ReactNode } from 'react';

import PageHeading from '../page-heading';

const DEFAULT_BG1 = 'rgb(10, 147, 39)';

type ThemedHeadingProps = {
  theme: Record<string, string>;
  children: ReactNode;
};

const ThemedHeading: FC<ThemedHeadingProps> = ({ theme = {}, children }) => {
  return (
    <PageHeading
      style={{
        backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.5), rgba(0, 0, 0, 0.2)), linear-gradient(${
          theme.bg1 || DEFAULT_BG1
        }, ${theme.bg1 || DEFAULT_BG1})`,
        color: 'white',
      }}
    >
      {children}
    </PageHeading>
  );
};

export default ThemedHeading;
