import { graphql } from '../generated';

export const CHECK_LINKED_ACCOUNTS = graphql(`
  query CheckLinkedAccounts {
    oauthLinkedAccounts {
      id
      name
      theme {
        post
        username
      }
    }
  }
`);
