import React, { FC } from 'react';

import { AnimationFrame } from '../../../lib/animation/animation-default-state';

import OnionSkin from './onion-skin';

type FrameLayersProps = {
  frame: AnimationFrame;
  width: number;
  height: number;
  layerIndexes: number[];
};

export const FrameLayers: FC<FrameLayersProps> = ({
  frame,
  width,
  height,
  layerIndexes,
}) => {
  return (
    <>
      {layerIndexes.map((layer: number) => (
        <OnionSkin
          width={width}
          height={height}
          key={`layer-${layer}`}
          className="layer"
          dataUrl={frame.layers[layer].image.getData()}
        />
      ))}
    </>
  );
};
