import React from 'react';

import Button from '../../components/button';
import { ButtonBar, Module } from '../../layouts';
import { ButtonGroup } from '../../layouts/button-group';
import { ModalComponentProps } from '../modal';

import ModalTitle from './modal-title';

type DefaultErrorModalProps = ModalComponentProps & {
  title: string;
  message: string;
};

export default function DefaultErrorModal(props: DefaultErrorModalProps) {
  return (
    <div className="text-center">
      <ModalTitle>{props.title}</ModalTitle>
      <Module>
        <p>{props.message}</p>
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-check-circle"
            onClick={props.hideModal}
            type="positive"
            rightAlign
          >
            Ok
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
}
