import { ApolloQueryResult } from '@apollo/client';
import { v1 as uuidv1 } from 'uuid';

import apolloClient from '../../../apollo-client';
import { Animation } from '../../../gql/generated/graphql';
import { ANIMATION } from '../../../gql/queries/animation';
import { asyncRetry } from '../../../lib/async-retry';
import { addBreadcrumb, captureError } from '../../../lib/error-logger';
import { loadAll, testCompatibility } from '../../lib/animation/animation-db';
import { AnimationFrame } from '../../lib/animation/animation-default-state';
import { RootState, store } from '../../store';

import { getBlankImage } from './canvas/lib/blank-image';
import { loadTexture } from './canvas/tools/pencil';
import { loadServerImages } from './load-server-images';

let localStorageHasBeenChecked = false;

const logBreadcrumb = (message: string) => {
  addBreadcrumb({
    category: 'editor-bootstrap',
    message,
  });
};

export const bootstrap = async ({
  onSuccess,
  onError,
  onOverwritePrompt,
  onIDBDetermined,
  onLoadProgress,
  onUrlChange,
  animationUrl,
}) => {
  const { animation } = store.getState();
  let localStorage: Partial<RootState['animation']> = null;
  logBreadcrumb(`Bootstrap started. AnimationUrl: ${animationUrl}`);

  if (
    (animation.url === animationUrl && localStorageHasBeenChecked) ||
    (!animationUrl && animation.url)
  ) {
    logBreadcrumb('Bootstrap skipped - animation already in store');
    onUrlChange(animation.url);
    return onSuccess();
  }

  const idbSupported = await testCompatibility();
  onIDBDetermined(idbSupported);

  await asyncRetry(loadTexture, 3); // Load pencil texture;

  // Load from local IDB
  if (idbSupported) {
    logBreadcrumb('Load from IDB');
    localStorageHasBeenChecked = true;

    try {
      localStorage = await loadAll();
    } catch (error) {
      captureError(error);
      return onError(error);
    }

    if (localStorage) {
      // We loaded something from local storage
      if (localStorage.url && !animationUrl) {
        logBreadcrumb('Resolved from local DB');
        return onSuccess(localStorage);
      }
      if (!localStorage.saved && localStorage.url !== animationUrl) {
        const shouldOverwrite = await onOverwritePrompt(); // true = overwrite from server, false == keep local
        if (shouldOverwrite === false) {
          logBreadcrumb('User chose to keep local version');
          return onSuccess(localStorage);
        }
      } else if (localStorage.url === animationUrl) {
        logBreadcrumb('Resolving to local DB - animation URL matches');
        return onSuccess(localStorage);
      }
    }
  } else {
    localStorageHasBeenChecked = true;
  }

  if (!localStorage && !animationUrl) {
    // Empty local DB and no data to load, can use default store state
    logBreadcrumb(
      'No local data & no URL to load. Creating a new local animation'
    );

    const defaultSequence: AnimationFrame[] = [
      {
        id: uuidv1(),
        delay: 200,
        layers: [
          { id: uuidv1(), image: getBlankImage({ width: 400, height: 300 }) },
          { id: uuidv1(), image: getBlankImage({ width: 400, height: 300 }) },
        ],
      },
    ];
    return onSuccess({ ...animation, sequence: defaultSequence });
  }

  // Either no local data, or we want to overwrite it
  // start loading from the server
  logBreadcrumb('Load from GQL');
  try {
    const { data }: ApolloQueryResult<{ animation: Animation }> =
      await apolloClient.query({
        query: ANIMATION,
        variables: {
          slug: animationUrl,
        },
      });
    logBreadcrumb(`Loaded anim ${data.animation.url} from GQL successfully`);
    logBreadcrumb(`Loading images`);
    const loadedAnimation = await loadServerImages(
      data.animation,
      onLoadProgress
    );
    logBreadcrumb(`Image load succeeded`);
    return onSuccess(loadedAnimation, { fromServer: true });
  } catch (error) {
    captureError(error);
    return onError(error);
  }
};
