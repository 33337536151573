import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import sha1 from 'crypto-js/sha1';

import { User } from '../../../gql/generated/graphql';
import { textColorForBackground } from '../../../lib/text-color-for-background';
import Avatar from '../avatar/avatar';

type UserPillProps = {
  user: User;
  prefix?: string;
  suffix?: string;
  onClick?: () => void;
  small?: boolean;
  nonInteractive?: boolean;
  stillImage?: boolean;
};

export const UserPill: FC<UserPillProps> = ({
  user,
  prefix = '',
  suffix = '',
  onClick,
  small = false,
  nonInteractive = false,
  stillImage = false,
}) => {
  if (!user) return null;
  let themeColour = user.theme?.username;
  const classes =
    'font-bold shadow-md inline-block pr-1 text-center whitespace-nowrap relative rounded-md overflow-hidden inline-flex items-center align-text-bottom mr-1';

  if (!themeColour) {
    const hash = sha1(user.name).toString();
    themeColour = `#${hash.slice(0, 2)}${hash.slice(2, 4)}${hash.slice(4, 6)}`;
  }

  if (onClick) {
    return (
      <button
        onClick={onClick}
        className={classes}
        style={{
          background: themeColour,
          color: textColorForBackground(themeColour),
          ...(small ? { maxWidth: '10rem' } : {}),
        }}
      >
        {prefix}
        {user.name}
        {suffix}
      </button>
    );
  }

  if (nonInteractive) {
    return (
      <span
        className={classes}
        style={{
          background: themeColour,
          color: textColorForBackground(themeColour),
          height: '1.25em',
          ...(small ? { maxWidth: '10rem' } : {}),
        }}
      >
        <Avatar
          user={user}
          className="pr-1 rounded-l-md"
          stillImage={stillImage}
        />
        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {prefix}
          {user.name}
          {suffix}
        </span>
      </span>
    );
  }

  return (
    <Link
      to={`/studio/${user.name}`}
      className={classes}
      style={{
        background: themeColour,
        color: textColorForBackground(themeColour),
        height: '1.25em',
        ...(small ? { maxWidth: '10rem' } : {}),
      }}
    >
      <Avatar
        user={user}
        className="pr-1 rounded-l-md"
        stillImage={stillImage}
      />
      <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {prefix}
        {user.name}
        {suffix}
      </span>
    </Link>
  );
};
