import produce from 'immer';

import { AnimationFrame } from '../../lib/animation/animation-default-state';

const MAX_UNDO_STACK_ITEMS = 50;

export enum EDITOR_DB_STATE {
  UNKNOWN = 'UNKNOWN',
  SUPPORTED = 'SUPPORTED',
  UNSUPPORTED = 'UNSUPPORTED',
}

export enum EDITOR_DB_SAVE_STATE {
  QUEUED = 'queued',
  PENDING = 'pending',
  SAVED = 'saved',
}

export type EditorMode = 'editing' | 'playback';
export type EditorTool =
  | 'pencil'
  | 'fill'
  | 'erase'
  | 'paint'
  | 'eyedropper'
  | 'moveLayer'
  | 'line'
  | 'circle'
  | 'rectangle';
export type EditorFramePickerMode = 'insert' | 'ovewrwrite' | 'overwrite-all';

export type EditorState = {
  currentTool: EditorTool;
  opacity: number;
  currentBrushSize: number;
  currentColourIndex: number;
  currentFrameIndex: number;
  currentLayerIndex: number;
  framePickerVisible: boolean;
  framePickerMode: EditorFramePickerMode;
  framePickerLayerMode: boolean[];
  mode: EditorMode;
  undoStack: AnimationFrame[];
  redoStack: AnimationFrame[];
  layerVisibility: boolean[];
  databaseState: EDITOR_DB_STATE;
  databaseSaveState: EDITOR_DB_SAVE_STATE;
  showOnionSkins: boolean;
  showGrid: boolean;
};

const defaultState: EditorState = {
  currentTool: 'pencil',
  opacity: 1,
  currentBrushSize: 3,
  currentColourIndex: 0,
  currentFrameIndex: 0,
  currentLayerIndex: 1,
  framePickerVisible: false,
  framePickerMode: 'insert',
  framePickerLayerMode: [],
  mode: 'editing',
  undoStack: [],
  redoStack: [],
  layerVisibility: [],
  databaseState: EDITOR_DB_STATE.UNKNOWN,
  databaseSaveState: EDITOR_DB_SAVE_STATE.SAVED,
  showOnionSkins: true,
  showGrid: false,
};

export default produce((state, action) => {
  switch (action.type) {
    case 'EDITOR/TOOL_CHANGED':
      state.currentTool = action.payload.type;
      break;
    case 'EDITOR/GO_TO_FRAME':
      state.currentFrameIndex = action.payload.index;
      break;
    case 'EDITOR/TOGGLE_FRAME_PICKER':
      state.framePickerVisible = !state.framePickerVisible;
      break;
    case 'EDITOR/BRUSH_SIZE_CHANGED':
      state.currentBrushSize = action.payload.size;
      break;
    case 'EDITOR/OPACITY_CHANGED':
      state.opacity = action.payload.opacity;
      break;
    case 'EDITOR/COLOUR_CHANGED':
      state.currentColourIndex = action.payload.index;
      break;
    case 'EDITOR/PLAYBACK_START':
      state.mode = 'playback';
      break;
    case 'EDITOR/PLAYBACK_STOP':
      state.mode = 'editing';
      break;
    case 'EDITOR/RESET':
      Object.assign(state, defaultState, {
        databaseState: `${state.databaseState}`,
      });
      break;
    case 'EDITOR/NEXT_LAYER':
      state.currentLayerIndex++;
      break;
    case 'EDITOR/PREVIOUS_LAYER':
      state.currentLayerIndex--;
      break;
    case 'EDITOR/GO_TO_LAYER':
      state.currentLayerIndex = action.payload;
      break;
    case 'EDITOR/SET_FRAME_PICKER_MODE':
      state.framePickerMode = action.payload;
      break;
    case 'EDITOR/SET_LAYER_VISIBILITY': {
      state.layerVisibility[action.payload.layerIndex] = action.payload.visible;
      break;
    }
    case 'EDITOR/SET_FRAME_PICKER_LAYER_VISIBILITY': {
      state.framePickerLayerMode[action.payload.layerIndex] =
        action.payload.visible;
      break;
    }
    case 'EDITOR/SET_LAYERS': {
      state.layerVisibility = action.payload;
      break;
    }
    case 'EDITOR/SET_FRAME_PICKER_LAYERS': {
      state.framePickerLayerMode = action.payload;
      break;
    }
    case 'EDITOR/UNDO_STACK_PUSH': {
      state.undoStack.push(action.payload);
      if (state.undoStack.length > MAX_UNDO_STACK_ITEMS) {
        state.undoStack.shift();
      }
      break;
    }
    case 'EDITOR/UNDO_STACK_POP': {
      state.undoStack.pop();
      break;
    }
    case 'EDITOR/REDO_STACK_PUSH': {
      state.redoStack.push(action.payload);
      break;
    }
    case 'EDITOR/REDO_STACK_POP': {
      state.redoStack.pop();
      break;
    }
    case 'EDITOR/REDO_STACK_RESET': {
      state.redoStack = [];
      break;
    }
    case 'EDITOR/SET_DB_STATE': {
      state.databaseState = action.payload;
      break;
    }
    case 'EDITOR/SET_DB_SAVE_STATE': {
      state.databaseSaveState = action.payload;
      break;
    }
    case 'EDITOR/TOGGLE_ONION_SKINS': {
      state.showOnionSkins = !state.showOnionSkins;
      break;
    }
    case 'EDITOR/TOGGLE_GRID': {
      state.showGrid = !state.showGrid;
      break;
    }
  }
  return state;
}, defaultState);
